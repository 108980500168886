/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import { labelLang } from '../Lang/lang';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEmployees, insertEmployees, updateEmployees, deleteEmployees,
} from '../reducers/employees';
import {
  getRequest, postRequest, putRequest, deleteRequest, getLangUser
} from '../Cache/cacheStorage';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function RoleAccess() {
  const apiUrl = 'roleAccess';
  const storedData = 'roleAccess';
  const [data, setdata] = useState([]);
  const [item, setItem] = useState({
    id: '', name: '', active: false,
  });
  const [modalItem, setmodalItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const [menuPosition, setMenuPosition] = useState('');
  const [menu, setMenu] = useState([]);
  const [subMenu, setsubMenu] = useState([]);
  const [menuAccess, setMenuAccess] = useState([]);

  const getEmployee = async () => {
    const requestEmployee = await getRequest('employees', {}, 'employees', false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };

  useEffect(() => {
    if (data.length === 0) {
      getEmployee();
    }
  }, []);

  const getRoleAccess = async () => {
    const requestRoleAccess = await getRequest(apiUrl, {}, storedData, false);
    setdata(requestRoleAccess.data.dataResponse);
  };

  useEffect(() => {
    getRoleAccess();
  }, []);

  const handlerOpenModalItem = (id) => {
    if (id !== '') {
      const obj = data.filter((x) => x.id === id);
      setItem(obj[0]);
    }

    setmodalItem(true);
  };

  const handlerCloseModalItem = () => {
    setItem({
      id: '', name: '', active: false,
    });

    setmodalItem(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeStock = (e) => {
    const { name, checked } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handlerItem = async (dataItem, method) => {
    if (method === 'POST') {
      const createData = await postRequest(apiUrl, dataItem, storedData, false);

      if (createData.status === 200) {
        setItem({
          id: '', name: '', active: false,
        });
        setmodalItem(false);
        setmsmAlert('El Rol se ha creado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }

    if (method === 'PUT') {
      const updateData = await putRequest(apiUrl, dataItem, storedData, false);

      if (updateData.status === 200) {
        setItem({
          id: '', name: '', active: false,
        });
        setmodalItem(false);
        setmsmAlert('El Rol se ha actualizado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }
  };

  const handlerProcess = () => {
    const dataItem = item;
    let method = '';
    if (dataItem.id === '') {
      method = 'POST';
      dataItem.id = uuidv4();
    } else {
      method = 'PUT';
    }

    handlerItem(dataItem, method); 
  };

  const handlerOpenModalDelete = (id) => {
    setdeleteItem(id);
    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handlerDelete = async () => {
    const deleteData = await deleteRequest(apiUrl, { id: deleteItem }, storedData, false);
    if (deleteData.status === 200) {
      if (deleteData.data.status === false) {
        setdeleteItem('');
        handlerCloseModalDelete(false);
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }

      if (deleteData.data.status === true) {
        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
        setdeleteItem('');
        setmodalDelete(false);
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const handlerSetEmployeeUpdate = (e) => {
    console.log(e.target.value);
  };

  const [userToShow, setUserToShow] = useState([]);

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <h3>
          Rol de acceso
        </h3>
      </div>
      {userToShow.length === 0 && (
        <div className="box-content d-flex flex-column justify-content-center align-items-center">
          <span className="text-muted mb-2">
            Seleccione un usuario
          </span>
          <FormControl variant="standard" className="w-25 float-end">
            <InputLabel id="label-select-employee">Usuario</InputLabel>
            <Select
              labelId="select-update-category"
              id="select-update-category"
              defaultValue=""
              name="employee"
              onChange={handlerSetEmployeeUpdate}
              MenuProps={MenuProps}
            >
              {
                employees.map((employeeItem) => (
                  <MenuItem key={employeeItem.id} value={employeeItem.id}>
                    {`${employeeItem.name} ${employeeItem.lastName}`}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      )}

      {userToShow.length !== 0  && (
        <div className="row w-100 g-0">
          
        </div>
      )}



      {/*
      <MaterialTable
        title={<h3>Rol de Acceso</h3>}
        columns={[
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Sucursal',
            field: 'branchOfficeName',
          },
          {
            title: 'Último inicio de sesión',
            field: 'lastLogin',
          },
        ]}
        data={data}
        options={{
          maxColumnSort: 'all_columns',
          paging: data.length > 100,
          pageSize: 100,
          pageSizeOptions: [100, 150, 200, 300],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
            searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
          },
          header: {
            actions: labelLang(getLangUser(), 'table', 'actions'),
          },
          pagination: {
            labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
            labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
            labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
            firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
            firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
            previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
            previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
            nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
            nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
            lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
            lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
          },
        }}
      />
      */}

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalItem(); } }}
      >
        <DialogTitle>
          {item.id ? (
            <span>Editar</span>
          ) : (
            <span>Crear</span>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-1">
              {menu.map((itemPosition) => (menuPosition !== itemPosition.id && (
                <div className="row" key={itemPosition.name}>
                  <div className="col-12">
                    <h3>{itemPosition.name}</h3>
                  </div>
                  {subMenu.filter((x) => x.menu === itemPosition.id).map((submenuItem) => (
                    <div className="col-4" key={submenuItem.name}>
                      {submenuItem.name}
                    </div>
                  ))}
                </div>
              )))}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalItem}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerProcess}
                disabled={item.name === ''}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar el Role Access?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RoleAccess;

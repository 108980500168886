/* eslint-disable */
import React from 'react';
import {
  Switch, Route, Link, useRouteMatch, useHistory, Redirect,
} from 'react-router-dom';
import Tutorial from './tutorial';
import WhatsNews from './whatsNew';
import tutorialConfiguration from './tutorialConfiguration';
import tutorialTable from './tutorialTable';
import tutorialTransaction from './tutorialTransaction';
import tutorialReport from './tutorialReport';
import tutorialClockIn from './tutorialClockIn';

function Configuracion() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const ValidationPath = history.location.pathname;

  const handlerActive = (event) => {
    const navItem = document.getElementsByClassName('nav-link');
    for (let index = 0; index < navItem.length; index++) {
      const obj = document.getElementById(navItem[index].id);
      if (navItem[index].id === event.target.id) {
        obj.classList.add('btn-filter-product-active');
      } else {
        obj.classList.remove('btn-filter-product-active');
      }
    }
  };

  return (
    <div className="h-80 w-100 mt-4 pt-3 px-3 pe-4 btn-rounded main-container overflow-auto">
      <div className="row h-100">
        <div className="col-2">
          <h3 className="text-white">Ayuda</h3>
          <div className="w-100">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Link
                id="tutorial-configuration"
                onClick={handlerActive}
                className={`nav-link text-white ${ValidationPath.includes('tutorial-configuration') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/tutorial-configuration`}
              >
                Configuración de Nuestro Sitio
              </Link>
              <Link
                id="tutorial-table"
                onClick={handlerActive}
                className={`nav-link text-white ${ValidationPath.includes('tutorial-table') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/tutorial-table`}
              >
                Mesas
              </Link>
              <Link
                id="tutorial-transaction"
                onClick={handlerActive}
                className={`nav-link text-white ${ValidationPath.includes('tutorial-transaction') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/tutorial-transaction`}
              >
                Pedidos
              </Link>
              <Link
                id="turorial-report"
                onClick={handlerActive}
                className={`nav-link text-white ${ValidationPath.includes('turorial-report') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/turorial-report`}
              >
                Reportes
              </Link>
              <Link
                id="turorial-clock-in"
                onClick={handlerActive}
                className={`nav-link text-white ${ValidationPath.includes('turorial-clock-in') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/turorial-clock-in`}
              >
                Registro de Asistencia
              </Link>
            </div>
          </div>
        </div>
        <div className="col-10 bg-white btn-rounded mb-3 detail-content">
          <Switch>
            <Redirect exact from={`${path}/`} to={`${path}/tutorial-configuration`} />
            <Route path={`${path}/tutorial-configuration`} component={tutorialConfiguration} />
            <Route path={`${path}/tutorial-table`} component={tutorialTable} />
            <Route path={`${path}/tutorial-transaction`} component={tutorialTransaction} />
            <Route path={`${path}/turorial-report`} component={tutorialReport} />
            <Route path={`${path}/turorial-clock-in`} component={tutorialClockIn} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Configuracion;

/* eslint-disable */
import React from 'react';
import {
  Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller
} from 'react-scroll';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { urlImages } from '../service';

function tutorialReport() {
  return (
    <div className="row mt-2">
      <h3>
        Reportes
        <h5
          className="float-end"
          data-bs-toggle="collapse"
          href="#collapseIndice"
          role="button"
          aria-expanded="false"
          aria-controls="collapseTransactions"
        >
          Indice&nbsp;
          <ArrowDropDownIcon />
        </h5>
      </h3>
      <div className="collapse px-5" id="collapseIndice">
        <div className="container card card-body">
          <ul className="nav navbar-nav">
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-one"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo ver mis Ventas Diarias?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-two"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo ver mis gráficos de Ventas?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-three"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo ver mis gráficos de Ventas por Empleados?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-four"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo ver mi gráfico de Gastos?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-five"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo registrar mis Gastos?
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Element className="element overflow-scroll" id="containerElement">
        <Element name="element-one">
          <h5 className="mt-1">
            ¿Cómo ver mis Ventas Diarias?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a&nbsp;
              <mark>
                Reportes
              </mark>
              &nbsp;en el menú superior.
            </li>
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el Menú&nbsp;
              <mark>
                Ventas
              </mark>
              &nbsp;en la parte Izquierda de la Visual para
              desplegar los submenús y allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <mark>
                <i>
                  Ventas de Día
                </i>
              </mark>
              .
            </li>
            <img
              src={urlImages+'tutorial-report-01.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 01"
            />
            <li>
              Allí visualizaremos una tabla donde se harán visibles
              las ventas que se han hecho durante el día.
            </li>
            <img
              src={urlImages+'tutorial-report-03.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 03"
            />
            <li>
              Aquí podremos visualizar que en la parte superior izquierda de la tabla
              habrá un botón en el cual nos mostrará la fecha actual y la cual podremos cambiar
              para&nbsp;
              <mark>
                filtrar por fecha
              </mark>
              &nbsp;las ventas que ha habido durante el día seleccionado.
            </li>
            <img
              src={urlImages+'tutorial-report-08.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 08"
            />
          </ol>
        </Element>
        <Element name="element-two">
          <h5 className="mt-1">
            ¿Cómo ver mis gráficos de Ventas?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a&nbsp;
              <mark>
                Reportes
              </mark>
              &nbsp;en el menú superior.
            </li>
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el Menú&nbsp;
              <mark>
                Ventas
              </mark>
              &nbsp;en la parte Izquierda de la Visual para
              desplegar los submenús y allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <mark>
                <i>
                  Ventas
                </i>
              </mark>
              .
            </li>
            <img
              src={urlImages+'tutorial-report-09.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 09"
            />
            <li>
              Allí podremos visualizar tres&nbsp;
              <b>
                (
              </b>
              3
              <b>
                )
              </b>
              &nbsp;gráficos diferentes donde podremos ver las ventas que se han realizado:
            </li>
            <ul>
              <li>
                Diario
              </li>
              <li>
                Mensual
              </li>
              <li>
                Anual
              </li>
            </ul>
            <img
              src={urlImages+'tutorial-report-10.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 10"
            />
            <li>
              y con tres&nbsp;
              <b>
                (
              </b>
              3
              <b>
                )
              </b>
              &nbsp;datos por gráfica:
            </li>
            <ul>
              <li>
                Número de Ventas&nbsp;
                <b>
                  (
                </b>
                #
                <b>
                  )
                </b>
              </li>
              <li>
                Promedio de Ventas&nbsp;
                <b>
                  (
                </b>
                $
                <b>
                  )
                </b>
              </li>
              <li>
                Total de Ventas&nbsp;
                <b>
                  (
                </b>
                $
                <b>
                  )
                </b>
              </li>
              <img
                src={urlImages+'tutorial-report-10.PNG'}
                className="my-2 w-75 btn-rounded"
                alt="pos system - tutorial report 10"
              />
            </ul>
            <li>
              En la parte superior de cada gráfica veremos un botón en el
              cual nos mostrará la fecha actual y la cual podremos cambiar
              para&nbsp;
              <mark>
                filtrar por fecha
              </mark>
              &nbsp;y las gráficas se actualizarán mostrando las Ventas que se han generado
              a diario, mensual y anual a partir de la fecha seleccionada.
            </li>
          </ol>
        </Element>
        <Element name="element-three">
          <h5 className="mt-1">
            ¿Cómo ver mis gráficos de Ventas por Empleados?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a&nbsp;
              <mark>
                Reportes
              </mark>
              &nbsp;en el menú superior.
            </li>
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el Menú&nbsp;
              <mark>
                Ventas
              </mark>
              &nbsp;en la parte Izquierda de la Visual para
              desplegar los submenús y allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <mark>
                <i>
                  Ventas de Empleados
                </i>
              </mark>
              .
            </li>
            <img
              src={urlImages+'tutorial-report-12.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 12"
            />
            <li>
              Allí podremos visualizar dos&nbsp;
              <b>
                (
              </b>
              2
              <b>
                )
              </b>
              &nbsp;gráficos diferentes donde podremos ver las ventas que se han realizado:
            </li>
            <ul>
              <li>
                Diario
              </li>
              <li>
                Mensual
              </li>
            </ul>
            <img
              src={urlImages+'tutorial-report-13.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 13"
            />
            <li>
              En estas gráficas podremos ver la cantidad de Ventas por Empleado.
            </li>
            <img
              src={urlImages+'tutorial-report-13.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 13"
            />
            <li>
              En la parte superior de cada gráfica veremos un botón en el
              cual nos mostrará la fecha actual y la cual podremos cambiar
              para&nbsp;
              <mark>
                filtrar por fecha
              </mark>
              &nbsp;y las gráficas se actualizarán mostrando las Ventas que se han generado
              a diario y mensual a partir de la fecha seleccionada.
            </li>
          </ol>
        </Element>
        <Element name="element-four">
          <h5 className="mt-1">
            ¿Cómo ver mi gráfico de Gastos?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a&nbsp;
              <mark>
                Reportes
              </mark>
              &nbsp;en el menú superior.
            </li>
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el Menú&nbsp;
              <mark>
                Gastos
              </mark>
              &nbsp;en la parte Izquierda de la Visual para
              desplegar los submenús y allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <mark>
                <i>
                  Reporte de Gastos
                </i>
              </mark>
              .
            </li>
            <img
              src={urlImages+'tutorial-report-11.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 11"
            />
            <li>
              Allí visualizaremos un gráfico donde se verá reflejado los gastos registrados
              y una tabla donde veremos en registros, los gastos que hemos registrado.
            </li>
            <img
              src={urlImages+'tutorial-report-17.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 17"
            />
            <li>
              En la parte superior de cada gráfica veremos un botón en el
              cual nos mostrará la fecha actual y la cual podremos cambiar
              para&nbsp;
              <mark>
                filtrar por fecha
              </mark>
              &nbsp;y las gráficas se actualizarán mostrando las Ventas que se han generado
              a diario y mensual a partir de la fecha seleccionada.
            </li>
            <img
              src={urlImages+'tutorial-report-18.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 18"
            />
          </ol>
        </Element>
        <Element name="element-five">
          <h5 className="mt-1">
            ¿Cómo registrar mis Gastos?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a&nbsp;
              <mark>
                Reportes
              </mark>
              &nbsp;en el menú superior.
            </li>
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el Menú&nbsp;
              <mark>
                Gastos
              </mark>
              &nbsp;en la parte Izquierda de la Visual para
              desplegar los submenús y allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <mark>
                <i>
                  Registrar Gasto
                </i>
              </mark>
              .
            </li>
            <img
              src={urlImages+'tutorial-report-14.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 14"
            />
            <li>
              Aquí completamos los campos requeridos para registrar nuestro gasto
            </li>
            <img
              src={urlImages+'tutorial-report-15.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 15"
            />
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-report-16.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial report 16"
            />
          </ol>
        </Element>
      </Element>
    </div>
  );
}

export default tutorialReport;

/* eslint-disable */
import React from 'react';
import {
  Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller
} from 'react-scroll';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { urlImages } from '../service';

function tutorialTransaction() {
  return (
    <div className="row mt-2">
      <h3>
        Pedidos
        <h5
          className="float-end"
          data-bs-toggle="collapse"
          href="#collapseIndice"
          role="button"
          aria-expanded="false"
          aria-controls="collapseTransactions"
        >
          Indice&nbsp;
          <ArrowDropDownIcon />
        </h5>
      </h3>
      <div className="collapse px-5" id="collapseIndice">
        <div className="container card card-body">
          <ul className="nav navbar-nav">
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-one"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Crear un Pedido a la Mesa?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-two"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Asociar un Pedido a una Mesa?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-three"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Asociar un Pedido a una Mesa con Pago Incluido?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-four"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Calcular el Cambio (Vueltas) del pedido?
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Element className="element overflow-scroll" id="containerElement">
        <Element name="element-one">
          <h5 className="mt-1">
            ¿Cómo Crear un Pedido a la Mesa?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Mesas’
               en el menú superior.
            </li>
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp; en cualquiera de nuestras Mesas guardadas.
            </li>
            <img
              src={urlImages+'tutorial-transaction-01.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 01"
            />
            <li>
              Allí nos dirigirá a una Nueva pantalla para tomar el Pedido.
            </li>
            <img
              src={urlImages+'tutorial-transaction-02.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 02"
            />
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en cualquiera de Nuestros Productos,
              Nos mostrará un Popup con el nombre del Producto elegido,
              Su valor y la cantidad de 1 como cantidad predeterminada.&nbsp;
            </li>
            <img
              src={urlImages+'tutorial-transaction-03.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 03"
            />
            <li>
              Si queremos aumentar la cantidad solicitada del producto, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;En la Lista de Cantidad, Se desplegará un listado de
              1 a 10 y elegiremos la cantidad que necesitemos.
            </li>
            <img
              src={urlImages+'tutorial-transaction-04.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 04"
            />
            <li>
              Una vez ajustado la cantidad que necesitamos para dicho producto,
              Veremos que el valor del producto en el Popup se actualizará
              mostrando el valor del producto por la cantidad elegida.
            </li>
            <img
              src={urlImages+'tutorial-transaction-05.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 05"
            />
            <li>
              Para incluir el Producto en la lista de Productos para el pedido,
              hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Agregar
              </button>
              &nbsp;Nuestro Popup se ocultará y el Producto aparecerá en la parte derecha,
              que mostrará el listado de Productos agregados al Pedido.
            </li>
            <img
              src={urlImages+'tutorial-transaction-06.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 06"
            />
          </ol>
        </Element>
        <Element name="element-two">
          <h5 className="mt-4">
            ¿Cómo Asociar un Pedido a una Mesa?
          </h5>
          <ol>
            <li>
              Cuando ya tengamos el listado de productos para nuestro Cliente,
              en la parte derecha inferior, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Procesar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-transaction-06.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 06"
            />
            <li>
              Luego nos aparecerá un Popup donde nos mostrará el Resumen del
              Pedido y para&nbsp;
              <mark>
                <i>
                  Asignar el Pedido a una Mesa
                </i>
              </mark>
              &nbsp;hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al botón&nbsp;
              <button
                className="btn btn-sm btn-warning text-white"
                type="button"
              >
                Asignar a la Mesa
              </button>
            </li>
            <img
              src={urlImages+'tutorial-transaction-07.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 07"
            />
            <li>
              Luego de hacer&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-warning text-white" type="button">
                Asignar a la Mesa
              </button>
              , nos mostrará la visual de Mesas
              y veremos que nuestra mesa, tendrá un&nbsp;
              <i>
                Cambio de Color
              </i>
              &nbsp;el cual refleja un cambio de Estado para la Mesa
              a la cual asociamos el pedido.
            </li>
            <img
              src={urlImages+'tutorial-transaction-16.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 16"
            />
          </ol>
        </Element>
        <Element name="element-three">
          <h5 className="mt-4">
            ¿Cómo Asociar un Pedido a una Mesa con Pago Incluido?
          </h5>
          <ol>
            <li>
              Cuando ya tengamos el listado de productos para nuestro Cliente,
              en la parte derecha inferior, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Procesar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-transaction-06.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 06"
            />
            <li>
              Luego nos aparecerá un Popup donde nos mostrará el Resumen del
              Pedido y para&nbsp;
              <mark>
                <i>
                  Asignar el Pedido a una Mesa con pago incluido
                </i>
              </mark>
              &nbsp;hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al botón&nbsp;
              <button
                className="btn btn-sm btn-primary text-white"
                type="button"
              >
                Pagar y Asignar a la mesa
              </button>
            </li>
            <img
              src={urlImages+'tutorial-transaction-07.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 07"
            />
            <li>
              Luego de hacer&nbsp;
              <mark>
                Clic
              </mark>
              , nos mostrará un recuadro para Elegir el Método de Pago.
            </li>
            <img
              src={urlImages+'tutorial-transaction-08.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 08"
            />
            <li>
              Luego de Elegir el Método de Pago, Elegiremos quien Registra el pago.
            </li>
            <img
              src={urlImages+'tutorial-transaction-20.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 20"
            />
            <li>
              Para Finalizar hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Confirmar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-transaction-21.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 21"
            />
            <li>
              Y nos mostrará la visual de Mesas donde veremos que el
              pedido se ha asociado a nuestra mesa con pago incluido.
            </li>
            <img
              src={urlImages+'tutorial-transaction-13.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 13"
            />
          </ol>
        </Element>
        <Element name="element-four">
          <h5 className="mt-4">
            ¿Cómo Calcular el Cambio (Vueltas) del pedido?
          </h5>
          <ol>
            <li>
              En la visual de Método de Pago, veremos dos opciones&nbsp;
              <mark>
                Tarjeta de Crédito
              </mark>
              &nbsp;y&nbsp;
              <mark>
                Efectivo
              </mark>
              &nbsp;y para habilitar la opción de calcular el cambio para
              el cliente debemos hacer&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <mark>
                Efectivo
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-transaction-08.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 08"
            />
            <li>
              Una vez elegido&nbsp;
              <mark>
                Efectivo
              </mark>
              &nbsp; nos mostrará una caja de texto donde tendremos que
              digitar el&nbsp;
              <mark>
                valor del dinero recibido
              </mark>
              .
            </li>
            <img
              src={urlImages+'tutorial-transaction-09.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 09"
            />
            <li>
              Luego hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Calcular Cambio
              </button>
              &nbsp;y allí se calculará el valor a devolver.
            </li>
            <img
              src={urlImages+'tutorial-transaction-10.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 10"
            />
          </ol>
        </Element>
      </Element>
    </div>
  );
}

export default tutorialTransaction;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import StarIcon from '@mui/icons-material/Star';
import {
  getRequest, getLangUser, numberToMoney,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

function TopSeller() {
  const currentDateForInputMonth = moment().format('YYYY-MM');
  const [dataMonth, setdataMonth] = useState([]);

  const getTransactionsByMonth = async (i, e) => {
    const response = await getRequest('report-top-seller', { initialdate: i, enddate: e }, 'report-top-seller', false);
    if (response.status === 200) {
      setdataMonth(response.data.dataResponse);
    }
  };

  const getSalesMonthly = async (eventInit, eventEnd, op) => {
    if (op === 0) {
      getTransactionsByMonth(eventInit, eventEnd);
    }

    if (op === 1) {
      getTransactionsByMonth(moment(eventInit).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        moment(eventInit).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'));
    }
  };

  useEffect(() => {
    getSalesMonthly(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'), 0);
  }, []);

  const getClassPosition = (position) => {
    let classPosition = 'text-dark';

    if (position === 1) {
      classPosition = 'top-1';
    } else if (position === 2) {
      classPosition = 'top-2';
    } else if (position === 3) {
      classPosition = 'top-3';
    }
    
    return classPosition;
  };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <div className="col-12 mt-2">
          <h3>
            Los más vendidos (Mes)&nbsp;
            {numberToMoney(dataMonth.reduce((a, v) => a + v.totalAmount, 0))}
          </h3>
        </div>
        <div className="col-12 mb-2">
          <TextField
                variant="standard"
            id="date-month"
            type="month"
            className="float-end"
            defaultValue={currentDateForInputMonth}
            onChange={(e) => getSalesMonthly(e.target.value, 0, 1)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-12 mb-2">
          <MaterialTable
            title=""
            columns={[
              {
                title: 'Posición',
                field: 'position',
                width: '8%',
                render: (dataRow) => (
                  <span className={getClassPosition(dataRow.position)}>
                    <span className="text-dark me-1">{dataRow.position}</span>
                    {dataRow.position < 4 && (
                      <StarIcon />
                    )}
                  </span>
                ),
              },
              {
                title: 'Nombre',
                field: 'name',
                width: '35%',
              },
              {
                title: 'Cantidad total',
                field: 'totalCant',
                width: '15%',
              },
              {
                title: 'Cant. calculada',
                field: 'cant',
                width: '15%',
              },
              {
                title: 'Cant. no calculada',
                field: 'cantNoCount',
                width: '15%',
              },
              {
                title: 'Precio',
                field: 'price',
                width: '15%',
                render: (dataRow) => (numberToMoney(dataRow.price)),
              },
              {
                title: 'T. de Venta',
                field: 'totalAmount',
                width: '20%',
                render: (dataRow) => (numberToMoney(dataRow.totalAmount)),
              },
            ]}
            data={dataMonth}
            options={{
              paging: dataMonth.length > 100,
              pageSize: 100,
              pageSizeOptions: [100, 150, 200, 300],
              toolbar: false,
              maxColumnSort: 'all_columns',
              rowStyle: (rowData) => {
                if (rowData.position < 4) {
                  return { fontSize: '24px' };
                }
              },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
                searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
              },
              header: {
                actions: labelLang(getLangUser(), 'table', 'actions'),
              },
              pagination: {
                labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
                labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
                labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
                firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
                firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
                previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
                previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
                nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
                nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
                lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
                lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TopSeller;

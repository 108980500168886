import React from 'react';
import {
  Switch, Route, Link, useRouteMatch, useHistory, Redirect,
} from 'react-router-dom';
import Company from './company';
import BranchOffice from './branchOffice';
import Users from './users';

function Configuracion() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const ValidationPath = history.location.pathname;

  const handlerActive = (event) => {
    const navItem = document.getElementsByClassName('nav-link');
    for (let index = 0; index < navItem.length; index += 1) {
      const obj = document.getElementById(navItem[index].id);
      if (navItem[index].id === event.target.id) {
        obj.classList.add('btn-filter-product-active');
      } else {
        obj.classList.remove('btn-filter-product-active');
      }
    }
  };

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <div className="d-flex w-100 h-100">
        <div className="options pe-2 h-100">
          <h3 className="text-white">Configuración</h3>
          <div className="w-100">
            <div className="col-sm-12 col-md-12 col-lg-12 menu-content">
              <Link
                key="users"
                id="users"
                onClick={handlerActive}
                className={`nav-link p-2 text-white ${ValidationPath.includes('users') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/users`}
              >
                Usuarios
              </Link>
              <Link
                key="company"
                id="company"
                onClick={handlerActive}
                className={`nav-link p-2 text-white ${ValidationPath.includes('company') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/company`}
              >
                Compañia
              </Link>
              <Link
                key="branch-office"
                id="branch-office"
                onClick={handlerActive}
                className={`nav-link p-2 text-white ${ValidationPath.includes('branch-office') === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                to={`${url}/branch-office`}
              >
                Sucursal
              </Link>
            </div>
          </div>
        </div>
        <div className="option-content-view ps-2 h-100 detail-content">
          <Switch>
            <Redirect exact from={`${path}`} to={`${path}admin-view/users`} />
            <Route path={`${path}/users`} component={Users} />
            <Route path={`${path}/company`} component={Company} />
            <Route path={`${path}/branch-office`} component={BranchOffice} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Configuracion;

import React, { useState } from 'react';
import {
  Switch, Route, Link, useRouteMatch, Redirect, useHistory,
} from 'react-router-dom';
import Product from './product';
import Category from './category';
import Materials from './materials';
import Employee from './employee';
import Rol from './role';
import paymentMethod from './paymentMethod';
import Stock from './stock';
import RoleAccess from './roleAccess';
import Promotions from './promotions';
import SpecialProducts from './specialProducts';
import app404 from '../Security/app404';

import {
  getListSubMenu, getTypeUserData, getAdmWebSite,
} from '../Cache/cacheStorage';

function Configuracion() {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const submenuList = getListSubMenu('settings');
  const userData = getTypeUserData();
  const [currentView, setCurrentView] = useState(history.location.pathname.substring(1).split('/')[1] === undefined ? 'stock' : history.location.pathname.substring(1).split('/')[1]);
  const admWebSite = getAdmWebSite();

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <div className="d-flex w-100 h-100">
        <div className="options pe-2 h-100">
          <h3 className="text-white">Configuración</h3>
          <div className="w-100">
            <div className="w-100 menu-content">
              {submenuList.submenu.map((item) => (
                <Link
                  key={item.name}
                  id={item.name}
                  onClick={() => setCurrentView(item.name)}
                  className={`nav-link p-2 text-white ${currentView === item.name ? ' btn-filter-product-active btn-rounded' : ''}`}
                  to={`${url}/${item.name}`}
                >
                  {item.tagName}
                </Link>
              ))}
              {admWebSite.admWebSite === true && (
                <Link
                  key="promotions"
                  id="promotions"
                  onClick={() => setCurrentView('promotions')}
                  className={`nav-link p-2 text-white ${currentView === 'promotions' ? ' btn-filter-product-active btn-rounded' : ''}`}
                  to={`${url}/promotions`}
                >
                  Promociones
                </Link>
              )}
              {admWebSite.admWebSite === false && (
                <Link
                  key="special-products"
                  id="special-products"
                  onClick={() => setCurrentView('special-products')}
                  className={`nav-link p-2 text-white ${currentView === 'special-products' ? ' btn-filter-product-active btn-rounded' : ''}`}
                  to={`${url}/special-products`}
                >
                  Productos especiales
                </Link>
              )}
              {/*
              {userData.typeUser === 'pppp-xqqx-xbbb-x2x1' && (
                  <Link
                    key="role-access"
                    id="role-access"
                    onClick={() => setCurrentView('role-access')}
                    className={`nav-link p-2 text-white ${currentView === 'role-access' ? ' btn-filter-product-active btn-rounded' : ''}`}
                    to={`${url}/role-access`}
                  >
                    Rol de Acceso
                  </Link>
              )}
              */}
            </div>
          </div>
        </div>
        <div className="option-content-view ps-2 h-100 detail-content">
          <Switch>
            <Redirect exact from={`${path}/`} to={`${path}/stock`} />
            <Route path={`${path}/stock`} component={Stock} />
            <Route path={`${path}/product`} component={Product} />
            <Route path={`${path}/category`} component={Category} />
            <Route path={`${path}/materials`} component={Materials} />
            <Route path={`${path}/employee`} component={Employee} />
            <Route path={`${path}/role`} component={Rol} />
            <Route path={`${path}/payment-method`} component={paymentMethod} />
            {admWebSite.admWebSite === true && (
              <Route path={`${path}/promotions`} component={Promotions} />
            )}
            {admWebSite.admWebSite === false && (
              <Route path={`${path}/special-products`} component={SpecialProducts} />
            )}
            {userData.typeUser === 'pppp-xqqx-xbbb-x2x1' && (
              <Route path={`${path}/role-access`} component={RoleAccess} />
            )}
            <Route path="*" component={app404} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Configuracion;

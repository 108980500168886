import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import userPool from './userPool';

const CssTextField = styled(TextField)({
  root: {
    '& label': {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'gray',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
  },
});

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();

    userPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        console.error(err);
      }
      console.log(data);
    });
  };

  return (
    <div className="w-100 h-100-i d-flex justify-content-center bg-security">
      <div className="d-flex justify-content-center text-white m-auto p-4 btn-rounded ">
        <div className="my-auto px-4 text-white" style={{ width: '30vw' }}>
          <h2 className="mb-5">Registro</h2>
          <form onSubmit={onSubmit}>
            <CssTextField
              label="Correo"
              className="w-100 mb-2"
              InputProps={{
                style: { color: 'white' },
              }}
              onChange={(event) => setEmail(event.target.value)}
            />
            <CssTextField
              label="Contraseña"
              className="w-100 mb-2"
              type="password"
              InputProps={{
                style: { color: 'white' },
              }}
              onChange={(event) => setPassword(event.target.value)}
            />
            <br />
            <Button
              variant="contained"
              className="mb-4 w-100"
              type="submit"
            >
              Registrarme
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import PropTypes from 'prop-types';
import {
  getRequest, getLangUser,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

const EmployeeDetail = ({ name, clockIn, clockOut }) => {
  return <div className="col-4">
    <div className="card card-body px-3 py-3 my-2 shadow-sm border-0">
      <div className="row">
        <div className="col-12 mb-2">
          <span className="h4 text-dark">{name}</span>
        </div>
        <div className="col-12 mb-2">
          <span className="text-dark">
            H. Ingreso:&nbsp;
            <span className="text-muted">
              {clockIn}
            </span>
          </span>
        </div>
        <div className="col-12 mb-2">
          <span className="text-dark">
            H. Salida:&nbsp;
            <span className="text-muted">
              {clockOut}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>;
};

function ClockIn() {
  const [dataTable, setdatatable] = useState([]);

  const getClockIn = async () => {
    const responseClockIn = await getRequest('clock-in', {}, 'clock-in', false);
    if (responseClockIn.status === 200) {
      setdatatable(responseClockIn.data.dataResponse);
    }
  };

  useEffect(() => {
    getClockIn();
  }, []);

  const detailPanel = (data) => (
    <div className="row px-4 py-3">
      {data.employees.map((employee) => (
        <React.Fragment key={employee.id}>
          <EmployeeDetail {...employee} />
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <MaterialTable
          title={<h3>Asistencia de empleados</h3>}
          columns={[
            {
              title: 'Fecha de creación del registro de asistencia',
              field: 'createdate',
              render: (dataRow) => moment(dataRow.createdate).format('DD/MM/YYYY'),
            },
          ]}
          detailPanel={(rowData) => (detailPanel(rowData.rowData))}
          data={dataTable}
          options={{
            paging: dataTable.length > 100,
            pageSize: 100,
            pageSizeOptions: [100, 150, 200, 300],
            maxColumnSort: 'all_columns',
          }}
          localization={{
            toolbar: {
              searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
              searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
            },
            header: {
              actions: labelLang(getLangUser(), 'table', 'actions'),
            },
            pagination: {
              labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
              labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
              labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
              firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
              firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
              previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
              previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
              nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
              nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
              lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
              lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
            },
          }}
        />
      </div>
    </div>
  );
}

EmployeeDetail.propTypes = {
  name: PropTypes.element.isRequired,
  clockIn: PropTypes.element.isRequired,
  clockOut: PropTypes.element.isRequired
};

export default ClockIn;

/* eslint-disable */
import axios from 'axios';
import {
  decode as base64_decode,
  encode as base64_encode
} from 'base-64';
import { urlAws } from '../service';

export const encodeData = (data) => {
  return base64_encode(JSON.stringify(data));
};

export const decodeData = (data) => {
  return base64_decode(data);
};

export const setCacheData = (storeData, data) => {
  sessionStorage.setItem(storeData, encodeData(data));
};

export const getCacheData = (storeData) => {
  let data = '';

  if (sessionStorage.getItem(storeData) !== null) {
    data = JSON.parse(decodeData(sessionStorage.getItem(storeData)));
  }

  return data;
};

export const getTypeUserData = () => {
  const data = {
    typeUser: '',
    typeUserName: '',
  };

  if (sessionStorage.getItem('cogappuser') !== null) {
    const user = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));
    data.typeUser = user.typeUser;
    data.typeUserName = user.typeUserName;
  }

  return data;
};

export const getEmailUser = () => {
  const data = {
    email: '',
    branchOffice: '',
    company: '',
  };

  if (sessionStorage.getItem('cogappuser') !== null) {
    const user = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));

    data.email = user.email;
    data.branchOffice = user.branchOfficeName;
    data.company = user.companyName;
  }

  return data;
};

export const getCompany = () => {
  const data = {
    company: '',
    companyName: '',
    branchOffice: '',
    branchOfficeName: '',
    address: '',
    phoneNumber: '',
    nit: '',
  };

  if (sessionStorage.getItem('cogappuser') !== null) {
    const companyData = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));

    data.company = companyData.company;
    data.companyName = companyData.companyName;
    data.branchOffice = companyData.branchOffice;
    data.branchOfficeName = companyData.branchOfficeName;
    data.nit = companyData.companyNIT;
    data.phoneNumber = companyData.companyPhoneNumber;
    data.address = companyData.companyAddress;
  }

  return data;
};

export const getMenu = () => {
  let data = {};

  if (sessionStorage.getItem('cogappuser') !== null) {
    const menudata = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));
    data = menudata.roleAccess.menu;
  }

  return data;
};

export const getListSubMenu = (menu) => {
  let data = {};

  if (sessionStorage.getItem('cogappuser') !== null) {
    const menudata = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));
    data = menudata.roleAccess.menu.find((x) => x.menuUrl === menu);
  }

  return data;
};

export const getSubMenu = (menu, submenu) => {
  let data = {};

  if (sessionStorage.getItem('cogappuser') !== null) {
    const menudata = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));
    const submenudata = menudata.roleAccess.menu.find((x) => x.menuUrl === menu);
    data = submenudata.submenu.find((x) => x.name === submenu);
  }

  return data;
};

export const getRoleAccess = () => {
  let data = {};

  if (sessionStorage.getItem('cogappuser') !== null) {
    const roleAccessData = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));
    data = roleAccessData.roleAccess;
  }

  return data;
};

export const getAdmWebSite = () => {
  const data = {
    admWebSite: false,
  };

  if (sessionStorage.getItem('cogappuser') !== null) {
    const user = JSON.parse(decodeData(sessionStorage.getItem('cogappuser')));
    data.admWebSite = user.admWebSite;
  }

  return data;
};

export const getHostedSite = () => {
  const data = {
    hostedSite: false,
  };

  if (sessionStorage.getItem('cogappuser') !== null) {
    const user = JSON.parse(decodeData(sessionStorage.getItem('cogappuser'), 'base64'));
    data.hostedSite = user.hostedSite;
  }

  return data;
};

export const getAuthUser = () => {
  let data = '';
  if (sessionStorage.getItem('cogauthuser') !== null) {
    data = sessionStorage.getItem('cogauthuser');
  } 
  return data;
};

export const createPhoto = async (url, imgBlob) => {
  let data = '';

  let formData = new FormData();
  formData.append('image', imgBlob);

  data = await axios.post(`${urlAws + url}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export const createPDF = async (url, pdfBlob) => {
  let data = '';

  let formData = new FormData();
  formData.append('pdf', pdfBlob);

  data = await axios.post(`${urlAws + url}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export const getRequest = async (url, params, storeData, storeCache) => {
  let data = [];

  const dataUser = getCacheData('cogdatauser');
  const dataParams = {
    email: dataUser.email,
    pass: dataUser.pass,
    token: dataUser.token,
    params: params,
  };

  data = await axios.post(`${urlAws}get-${url}`, dataParams);

  if (data.data.noStatus === 2) {
    sessionStorage.setItem('cogauthuser', 'false');
    window.location.reload();
  }

  return data;
};

export const postRequest = async (url, params, storeData, storeCache) => {
  let data = [];

  const dataUser = getCacheData('cogdatauser');
  const dataParams = {
    email: dataUser.email,
    pass: dataUser.pass,
    token: dataUser.token,
    params: params,
  };

  data = await axios.post(`${urlAws + url}`, dataParams);

  if (data.data.noStatus === 2) {
    sessionStorage.setItem('cogauthuser', 'false');
    window.location.reload();
  }

  return data;
};

export const putRequest = async (url, params, storeData, storeCache) => {
  let data = [];

  const dataUser = getCacheData('cogdatauser');
  const dataParams = {
    email: dataUser.email,
    pass: dataUser.pass,
    token: dataUser.token,
    params: params,
  };

  data = await axios.put(`${urlAws + url}`, dataParams);

  if (data.data.noStatus === 2) {
    sessionStorage.setItem('cogauthuser', 'false');
    window.location.reload();
  }

  return data;
};

export const deleteRequest = async (url, params, storeData, storeCache) => {
  let data = [];

  const dataUser = getCacheData('cogdatauser');
  const dataParams = {
    email: dataUser.email,
    pass: dataUser.pass,
    token: dataUser.token,
    params: params,
  };

  data = await axios.delete(`${urlAws + url}`, { data: dataParams });

  if (data.data.noStatus === 2) {
    sessionStorage.setItem('cogauthuser', 'false');
    window.location.reload();
  }

  return data;
};

export const handlerSendEmail = async (date) => {
  const deleteData = await getRequest('email-resume-sales', { date: date }, 'email-resume-sales', false);
  if (deleteData.status === 200) {
    console.log('Se ha enviado.');
  } else {
    console.log('error');
  }
};

export const getLangUser = () => {
  let langUser = 'en';
  const langST = sessionStorage.getItem('lang');

  if (langST !== '' || langST !== undefined || langST !== null) {
    langUser = langST;
  }

  return langUser;
};

export const numberToMoney = (price) => {
  let money = 0;

  if (price !== "" && price !== null && price !== undefined) {
    const { currency, langCode } = getCacheData('cogappuser');
  
    const transformMoney = Intl.NumberFormat(langCode, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);

    money = transformMoney;
  }

  return money;
};

/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const newProductSlice = createSlice({
  name: 'product',
  initialState: {
    value: {
      id: '',
      name: '',
      category: '',
      categoryName: '',
      materials: [],
      photo: '',
      price: 0,
    },
  },
  reducers: {
    setProduct: (state, action) => {
      state.value.id = action.payload.id
      state.value.name = action.payload.name
      state.value.category = action.payload.category
      state.value.categoryName = action.payload.categoryName
      state.value.materials = action.payload.materials
      state.value.photo = action.payload.photo
      state.value.price = action.payload.price
    },
    setId: (state, action) => {
      state.value.id = action.payload
    },
    setName: (state, action) => {
      state.value.name = action.payload
    },
    setCategoryProduct: (state, action) => {
      state.value.category = action.payload
    },
    setCategoryName: (state, action) => {
      state.value.categoryName = action.payload
    },
    setMaterialsList: (state, action) => {
      state.value.materials.push(action.payload)
    },
    setRemoveMaterialsList: (state, action) => {
      state.value.materials = state.value.materials.filter(x => x.id !== action.payload)
    },
    setPhoto: (state, action) => {
      state.value.photo = action.payload
    },
    setPrice: (state, action) => {
      state.value.price = action.payload
    },
  }
});

export const {
  setProduct, setId, setName, setCategoryProduct, setCategoryName, setMaterialsList, setRemoveMaterialsList, setPhoto, setPrice 
} = newProductSlice.actions;

export default newProductSlice.reducer;

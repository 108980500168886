import React, { useState, useEffect } from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReactApexChart from 'react-apexcharts';
import { getRequest, numberToMoney } from '../Cache/cacheStorage';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Sales() {
  const currentDateForInput = moment().format('YYYY-MM-DD');
  const currentDateForInputMonth = moment().format('YYYY-MM');

  const [salesReport, setSalesReport] = useState([]);

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const [dataSalesDaily, setDataSalesDaily] = useState(0);
  const [dataTotalDaily, setdataTotalDaily] = useState(0);

  const [dataSalesMonth, setdataSalesMonth] = useState(0);
  const [dataTotalMonth, setdataTotalMonth] = useState(0);

  const [selectYear, setSelectYear] = useState('');
  const [dataSalesYear, setdataSalesYear] = useState(0);
  const [dataTotalYear, setdataTotalYear] = useState(0);

  const [seriesDaily, SetSeriesDaily] = useState({
    series: [],
    options: {
      chart: {
        id: 'daily-apexchart',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const [seriesMonthly, SetSeriesMonthly] = useState({
    series: [],
    options: {
      chart: {
        id: 'month-apexchart',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const [seriesYear, SetSeriesYear] = useState({
    series: [],
    options: {
      chart: {
        id: 'anual-apexchart',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const alertDontFoundSales = (graphics) => {
    if (graphics === 'day') {
      setDataSalesDaily(0);
      setdataTotalDaily(0);
      const updateDailyChart = {
        series: [],
        options: {
          chart: {
            id: 'daily-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
        },
      };
      SetSeriesDaily(updateDailyChart);

      setmsmAlert('No se encontraron ventas para el día.');
      setalertError(true);
    }

    if (graphics === 'month') {
      setdataSalesMonth(0);
      setdataTotalMonth(0);
      const updateMonthChart = {
        series: [],
        options: {
          chart: {
            id: 'month-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
        },
      };
      SetSeriesMonthly(updateMonthChart);

      setmsmAlert('No se encontraron registros para el mes.');
      setalertError(true);
    }

    if (graphics === 'year') {
      setdataSalesYear(0);
      setdataTotalYear(0);
      const updateYearChart = {
        series: [],
        options: {
          chart: {
            id: 'anual-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
        },
      };
      SetSeriesYear(updateYearChart);

      setmsmAlert('No se encontraron registros para el año.');
      setalertError(true);
    }
  };

  const getTransactionsByDay = (initialDate) => {
    const dataReport = salesReport;
    const yearReport = dataReport.find((x) => x.createdate === moment(initialDate).startOf('year').format('YYYY-MM-DD'));

    if (yearReport !== undefined) {
      const monthReportDay = yearReport.months.find((x) => x.createdate === moment(initialDate).startOf('month').format('YYYY-MM-DD'));
      if (monthReportDay !== undefined) {
        const dayReport = monthReportDay.days.filter((x) => x.createdate === moment(initialDate).startOf('day').format('YYYY-MM-DD'));
        if (dayReport.length !== 0) {
          setDataSalesDaily(dayReport[0].totalTransaction);
          setdataTotalDaily(dayReport[0].totalSales);

          const updateDailyChart = {
            series: [
              { name: '# Ventas', data: dayReport.map((x) => x.totalTransaction) },
              { name: 'Total de Ventas', data: dayReport.map((x) => x.totalSales) },
            ],
            options: {
              chart: {
                id: 'daily-apexchart',
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'smooth',
              },
              xaxis: {
                categories: dayReport.map((x) => x.createdate),
                labels: {
                  formatter(value) {
                    return moment(value).format('MMM DD');
                  },
                },
              },
            },
          };
          SetSeriesDaily(updateDailyChart);
        } else {
          alertDontFoundSales('day');
        }
      } else {
        alertDontFoundSales('day');
      }
    } else {
      alertDontFoundSales('day');
    }
  };

  const getTransactionsByMonth = (initialDate) => {
    const dataReport = salesReport;
    const yearReport = dataReport.find((x) => x.createdate === moment(initialDate).startOf('year').format('YYYY-MM-DD'));
    if (yearReport !== undefined) {
      const monthReport = yearReport.months.find((x) => x.createdate === moment(initialDate).startOf('month').format('YYYY-MM-DD'));
      if (monthReport !== undefined) {
        setdataSalesMonth(monthReport.totalTransaction);
        setdataTotalMonth(monthReport.totalSales);

        const updateMonthChart = {
          series: [
            { name: '# Ventas', data: monthReport.days.map((x) => x.totalTransaction) },
            { name: 'Total de Ventas', data: monthReport.days.map((x) => x.totalSales) },
          ],
          options: {
            chart: {
              id: 'month-apexchart',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              categories: monthReport.days.map((x) => x.createdate),
              labels: {
                formatter(value) {
                  return moment(value).format('MMM DD');
                },
              },
            },
          },
        };
        SetSeriesMonthly(updateMonthChart);
      } else {
        alertDontFoundSales('month');
      }
    } else {
      alertDontFoundSales('month');
    }
  };

  const getTransactionsByYear = (initialDate) => {
    const dataReport = salesReport;
    const yearReport = dataReport.find((x) => x.createdate === moment(initialDate).startOf('year').format('YYYY-MM-DD'));
    if (yearReport !== undefined) {
      setSelectYear(moment(yearReport.createdate).format('YYYY'));
      setdataSalesYear(yearReport.totalTransaction);
      setdataTotalYear(yearReport.totalSales);

      const updateYearChart = {
        series: [
          { name: '# Ventas', data: yearReport.months.map((x) => x.totalTransaction) },
          { name: 'Total de Ventas', data: yearReport.months.map((x) => x.totalSales) },
        ],
        options: {
          chart: {
            id: 'anual-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: yearReport.months.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM');
              },
            },
          },
        },
      };
      SetSeriesYear(updateYearChart);
    } else {
      alertDontFoundSales('year');
    }
  };

  const initFilters = (dataReport) => {
    const yearReport = dataReport.find((x) => x.createdate === moment().startOf('year').format('YYYY-MM-DD'));

    if (yearReport !== undefined) {
      setSelectYear(moment(yearReport.createdate).format('YYYY'));
      setdataSalesYear(yearReport.totalTransaction);
      setdataTotalYear(yearReport.totalSales);

      const updateYearChart = {
        series: [
          { name: '# Ventas', data: yearReport.months.map((x) => x.totalTransaction) },
          { name: 'Total de Ventas', data: yearReport.months.map((x) => x.totalSales) },
        ],
        options: {
          chart: {
            id: 'anual-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: yearReport.months.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM');
              },
            },
          },
        },
      };
      SetSeriesYear(updateYearChart);

      const monthReport = yearReport.months.find((x) => x.createdate === moment().startOf('month').format('YYYY-MM-DD'));
      if (monthReport !== undefined) {
        setdataSalesMonth(monthReport.totalTransaction);
        setdataTotalMonth(monthReport.totalSales);

        const updateMonthChart = {
          series: [
            { name: '# Ventas', data: monthReport.days.map((x) => x.totalTransaction) },
            { name: 'Total de Ventas', data: monthReport.days.map((x) => x.totalSales) },
          ],
          options: {
            chart: {
              id: 'month-apexchart',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              categories: monthReport.days.map((x) => x.createdate),
              labels: {
                formatter(value) {
                  return moment(value).format('MMM DD');
                },
              },
            },
          },
        };
        SetSeriesMonthly(updateMonthChart);

        const dayReport = monthReport.days.filter((x) => x.createdate === moment().startOf('day').format('YYYY-MM-DD'));
        if (dayReport.length !== 0) {
          setDataSalesDaily(dayReport[0].totalTransaction);
          setdataTotalDaily(dayReport[0].totalSales);

          const updateDailyChart = {
            series: [
              { name: '# Ventas', data: dayReport.map((x) => x.totalTransaction) },
              { name: 'Total de Ventas', data: dayReport.map((x) => x.totalSales) },
            ],
            options: {
              chart: {
                id: 'daily-apexchart',
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'smooth',
              },
              xaxis: {
                categories: dayReport.map((x) => x.createdate),
                labels: {
                  formatter(value) {
                    return moment(value).format('MMM DD');
                  },
                },
              },
            },
          };
          SetSeriesDaily(updateDailyChart);
        }
      }
    }
  };

  const getReport = async (i, e) => {
    const response2 = await getRequest('report-sales', { initialdate: i, enddate: e }, 'report-sales', false);
    if (response2.status === 200) {
      if (response2.data.dataResponse.length !== 0) {
        setSalesReport(response2.data.dataResponse);
        initFilters(response2.data.dataResponse);
      }
    }
  };

  useEffect(() => {
    getReport(moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD'), 0);
  }, []);

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <h3>Reporte de ventas</h3>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h6>Diario</h6>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-end">
              <TextField
                variant="standard"
                id="date-daily"
                type="date"
                defaultValue={currentDateForInput}
                onChange={(e) => getTransactionsByDay(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <ReactApexChart
                options={seriesDaily.options}
                series={seriesDaily.series}
                type="bar"
                height={250}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card card-body border-0 shadow-sm">
            <div className="row text-center">
              <h4 className="text-primary">Resumen - Gráfica Diaria</h4>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Ventas registradas:
                  <br />
                  <span className="h5 text-muted">
                    {dataSalesDaily}
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Prom. de ventas
                  <br />
                  <span className="h5 text-muted">
                    {
                      numberToMoney(
                        (dataTotalDaily !== 0 && dataSalesDaily !== 0)
                          ? dataTotalDaily / dataSalesDaily : 0,
                      )
                    }
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Total de ventas
                  <br />
                  <span className="h5 text-primary">
                    {
                      numberToMoney(dataTotalDaily)
                    }
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h6>Mensual</h6>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-end">
              <TextField
                variant="standard"
                id="date-month"
                type="month"
                defaultValue={currentDateForInputMonth}
                onChange={(e) => getTransactionsByMonth(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <ReactApexChart
                options={seriesMonthly.options}
                series={seriesMonthly.series}
                type="bar"
                height={250}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card card-body border-0 shadow-sm">
            <div className="row text-center">
              <h4 className="text-primary">Resumen - Gráfica Mensual</h4>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Ventas registradas
                  <br />
                  <span className="h5 text-muted">
                    {dataSalesMonth}
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Prom. de ventas
                  <br />
                  <span className="h5 text-muted">
                    {
                      numberToMoney(
                        (dataTotalMonth !== 0 && dataSalesMonth !== 0)
                          ? dataTotalMonth / dataSalesMonth : 0,
                      )
                    }
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Total de ventas
                  <br />
                  <span className="h5 text-primary">
                    {
                      numberToMoney(dataTotalMonth)
                    }
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h6>Anual</h6>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-end">
              <FormControl variant="standard" className="float-end filter-origin">
                <InputLabel id="label-select-year">Año</InputLabel>
                <Select
                  id="select-year"
                  labelId="select-year"
                  name="select-year"
                  value={selectYear}
                  onChange={(e) => getTransactionsByYear(e.target.value)}
                >
                  {salesReport.map((item) => (
                    <MenuItem
                      key={item.createdate}
                      value={moment(item.createdate).format('YYYY')}
                    >
                      {moment(item.createdate).format('YYYY')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <ReactApexChart
                options={seriesYear.options}
                series={seriesYear.series}
                type="bar"
                height={250}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card card-body border-0 shadow-sm">
            <div className="row text-center">
              <h4 className="text-primary">Resumen - Gráfica Anual</h4>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Ventas registradas
                  <br />
                  <span className="h5 text-muted">
                    &nbsp;
                    {dataSalesYear}
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Prom. de ventas
                  <br />
                  <span className="h5 text-muted">
                    {
                      numberToMoney(
                        (dataTotalYear !== 0 && dataSalesYear !== 0)
                          ? dataTotalYear / dataSalesYear : 0,
                      )
                    }
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Total de ventas
                  <br />
                  <span className="h5 text-primary">
                    {
                      numberToMoney(dataTotalYear)
                    }
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Sales;
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    value: [],
  },
  reducers: {
    setCategory: (state, action) => {
      state.value = action.payload
    },
    insertCategory: (state, action) => {
      state.value.push(action.payload)
    },
    updateCategory: (state, action) => {
			state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index] = action.payload
				}
			})
    },
    deleteCategory: (state, action) => {
      state.value = state.value.filter(item => item.id !== action.payload)
    },
  }
});

export const { setCategory, insertCategory, updateCategory, deleteCategory } = categorySlice.actions;

export default categorySlice.reducer;

// EC2 URL
// export const urlAws = 'https://localhost:4000/';
export const urlAws = 'https://d3090mxcwnzsww.cloudfront.net/';

// pdf
// export const urlpdf = 'http://192.168.20.22:3000/#/';
export const urlpdf = 'https://tf-payments.io/#/';

// URL TUTORIAL IMAGES
export const urlImages = 'https://pos-system-tf-payments.s3.amazonaws.com/assets/img/Tutorials/';

// URL UP KEY
export const upKey = 'UvMWsWQP71UP-gtam3cO1vDscvPQdXgjmxl5KaQoEiw';

// upCognito
export const upCognito = 'us-east-1_AZGQj1xpA';

import React from 'react';

function WhatsNews() {
  return (
    <div>
      <div className="row mt-2">
        <div className="col-12">
          <h3>¿Qué hay de nuevo?</h3>
        </div>
        <div className="col-12">
          V 1.0.0
        </div>
        <div className="col-12">
          <h5>WorkFlows</h5>
        </div>
      </div>
    </div>
  );
}

export default WhatsNews;

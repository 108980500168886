/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const stockSlice = createSlice({
  name: 'stock',
  initialState: {
    value: [],
  },
  reducers: {
    setStock: (state, action) => {
      state.value = action.payload
    },
    insertProductStock: (state, action) => {
      state.value.push(action.payload)
    },
    updateProductStock: (state, action) => {
			state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index] = action.payload
				}
			})
    },
    updateAmountProduct: (state, action) => {
      state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index].amountStock += action.payload.addAmount;
					state.value[index].amountRemaining += action.payload.addAmount;
				}
			})
    },
    updateStockProduct: (state, action) => {
      state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index].name = action.payload.name;
					state.value[index].price = action.payload.price;
				}
			})
    },
    deleteProductStock: (state, action) => {
      state.value = state.value.filter(item => item.id !== action.payload)
    },
    updateProductForAddToOrder: (state, action) => {
      state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index].amountRemaining -= action.payload.amount;
					state.value[index].amountSell += action.payload.amount;
				}
			})
    },
    updateProductReturnAmountToOrder: (state, action) => {
      state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index].amountRemaining = (action.payload.prevAmount + state.value[index].amountRemaining) - action.payload.currentAmount;
					state.value[index].amountSell = (action.payload.prevAmount - state.value[index].amountSell) + action.payload.currentAmount;
				}
			})
    },
    resetStock: (state, action) => {
      state.value = [];
    }
  }
});

export const { setStock, insertProductStock, deleteProductStock,
  updateProductStock, updateAmountProduct, updateStockProduct,
  updateProductForAddToOrder, updateProductReturnAmountToOrder,
  resetStock
} = stockSlice.actions;

export default stockSlice.reducer;

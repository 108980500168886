import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
  getRequest, numberToMoney,
} from '../Cache/cacheStorage';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ReportStock() {
  moment.defineLocale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
  });

  const [data, setData] = useState([]);
  const [productToShow, setProductToShow] = useState([]);

  const getReportStock =  async () => {
    const resReporteStock = await getRequest('report-stock', {}, 'report-stock', false);
    if (resReporteStock.status === 200) {
      setData(resReporteStock.data.dataResponse);
    }
  };

  useEffect(() => {
    getReportStock();
  }, []);

  const [dayReportSelected, setDayReportSelected] = useState('');
  const [monthReportSelected, setMonthReportSelected] = useState('');
  const [yearReportSelected, setYearReportSelected] = useState('');

  const [dateDayReport, setDateDayReport] = useState([]);
  const [dateMonthReport, setDateMonthReport] = useState([]);
  const [dateYearReport, setDateYearReport] = useState([]);

  const [seriesDaily, SetSeriesDaily] = useState({
    series: [],
    options: {
      chart: {
        id: 'daily-apexchart',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const [seriesMonthly, SetSeriesMonthly] = useState({
    series: [],
    options: {
      chart: {
        id: 'month-apexchart',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const [seriesYear, SetSeriesYear] = useState({
    series: [],
    options: {
      chart: {
        id: 'anual-apexchart',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const getFormatYear = (date) => {
    return moment(date).format('YYYY');
  };
  
  const getFormatMonth = (date) => {
    return moment(date).format('YYYY-MM');
  };
  
  const getFormatDay = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const handlerGetDataDay = (date) => {
    if (moment(date).isValid()) {
      const reportY = productToShow[0].record.filter((dy) => dy.createdate === getFormatYear(date))[0];
      const reportM = reportY?.months.filter((dm) => dm.createdate === getFormatMonth(date))[0];

      return reportM?.days.filter((dd) => dd.createdate === getFormatDay(date))[0];
    }
  };

  const handlerGetDataMonth = (date) => {
    if (moment(date).isValid()) {
      const reportY = productToShow[0].record.filter((dy) => dy.createdate === getFormatYear(date))[0];

      return reportY?.months.filter((dm) => dm.createdate === getFormatMonth(date))[0];
    }
  };

  const handlerGetDataYear = (date) => {
    if (moment(date).isValid()) {
      return productToShow[0].record.filter((dy) => dy.createdate === getFormatYear(date))[0];
    }
  };

  const alertDontFoundSales = (graphics) => {
    if (graphics === 'day') {
      const updateDailyChart = {
        series: [],
        options: {
          chart: {
            id: 'daily-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
        },
      };
      SetSeriesDaily(updateDailyChart);
    }

    if (graphics === 'month') {
      const updateMonthChart = {
        series: [],
        options: {
          chart: {
            id: 'month-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
        },
      };
      SetSeriesMonthly(updateMonthChart);
    }

    if (graphics === 'year') {
      const updateYearChart = {
        series: [],
        options: {
          chart: {
            id: 'anual-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
        },
      };
      SetSeriesYear(updateYearChart);
    }
  };

  const handlerChangeDay = (date) => {
    setDayReportSelected(date);
    
    const reportY = productToShow[0].record.filter((dy) => dy.createdate === getFormatYear(date));
    const reportM = reportY?.months.filter((dm) => dm.createdate === getFormatMonth(date));
    const dataDayReport = reportM?.days.filter((dd) => dd.createdate === getFormatDay(date));

    if (dataDayReport !== undefined) {
      const updateDailyChart = {
        series: [
          { name: 'Cant. registradas', data: dataDayReport.map((x) => x.amountStock) },
          { name: 'Cant. vendidas', data: dataDayReport.map((x) => x.amountSell) },
          { name: 'Cant. restantes', data: dataDayReport.map((x) => x.amountRemaining) },
        ],
        options: {
          chart: {
            id: 'daily-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: dataDayReport.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM DD');
              },
            },
          },
        },
      };
      SetSeriesDaily(updateDailyChart);

    } else {
      alertDontFoundSales('day');
    }
  };

  const handlerChangeMonth = (date) => {
    setMonthReportSelected(date);

    const dataYearReport = productToShow[0].record.filter((dy) => dy.createdate === getFormatYear(date));
    const dataMonthReport = dataYearReport?.months.filter((dm) => dm.createdate === getFormatMonth(date));

    if (dataMonthReport !== undefined) {
      const updateMonthChart = {
        series: [
          { name: 'Cant. registradas', data: dataMonthReport.map((x) => x.amountStock) },
          { name: 'Cant. vendidas', data: dataMonthReport.map((x) => x.amountSell) },
          { name: 'Cant. restantes', data: dataMonthReport.map((x) => x.amountRemaining) },
        ],
        options: {
          chart: {
            id: 'month-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: dataMonthReport.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM');
              },
            },
          },
        },
      };
      SetSeriesMonthly(updateMonthChart);

    } else {
      alertDontFoundSales('month');
    }
  };

  const handlerChangeYear = (date) => {
    setYearReportSelected(date);

    const dataYearReport = productToShow[0].record.filter((dy) => dy.createdate === getFormatYear(date));

    if (dataYearReport !== undefined) {
      const updateYearChart = {
        series: [
          { name: 'Cant. registradas', data: dataYearReport[0].months.map((x) => x.amountStock) },
          { name: 'Cant. vendidas', data: dataYearReport[0].months.map((x) => x.amountSell) },
        ],
        options: {
          chart: {
            id: 'anual-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: dataYearReport[0].months.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM');
              },
            },
          },
        },
      };
      SetSeriesYear(updateYearChart);
    } else {
      alertDontFoundSales('year');
    }
  };

  const handlerSetInitdata = (dataProduct, arrayYear, arrayMonths, arrayDays) => {
    const lastDateDay = arrayDays.reverse()[0];
    setDayReportSelected(lastDateDay);
    const lastDateMonth = arrayMonths.reverse()[0];
    setMonthReportSelected(lastDateMonth);
    const lastDateYear = arrayYear.reverse()[0];
    setYearReportSelected(lastDateYear);

    const dataDayYearReport = dataProduct[0].record.filter((dy) => dy.createdate === getFormatYear(lastDateDay))[0];
    const dataDayMonthReport = dataDayYearReport?.months.filter((dm) => dm.createdate === getFormatMonth(lastDateDay))[0];
    const dataDayReport = dataDayMonthReport?.days.filter((dd) => dd.createdate === getFormatDay(lastDateDay));

    if (dataDayReport !== undefined) {
      const updateDailyChart = {
        series: [
          { name: 'Cant. registradas', data: dataDayReport.map((x) => x.amountStock) },
          { name: 'Cant. vendidas', data: dataDayReport.map((x) => x.amountSell) },
          { name: 'Cant. restantes', data: dataDayReport.map((x) => x.amountRemaining) },
        ],
        options: {
          chart: {
            id: 'daily-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: dataDayReport.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM DD');
              },
            },
          },
        },
      };
      SetSeriesDaily(updateDailyChart);

    } else {
      alertDontFoundSales('day');
    }

    const dataMonthYearReport = dataProduct[0].record.filter((dy) => dy.createdate === getFormatYear(lastDateDay))[0];
    const dataMonthReport = dataMonthYearReport?.months.filter((dm) => dm.createdate === getFormatMonth(lastDateDay));

    if (dataMonthReport !== undefined) {
      const updateMonthChart = {
        series: [
          { name: 'Cant. registradas', data: dataMonthReport.map((x) => x.amountStock) },
          { name: 'Cant. vendidas', data: dataMonthReport.map((x) => x.amountSell) },
          { name: 'Cant. restantes', data: dataMonthReport.map((x) => x.amountRemaining) },
        ],
        options: {
          chart: {
            id: 'month-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: dataMonthReport.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM');
              },
            },
          },
        },
      };
      SetSeriesMonthly(updateMonthChart);

    } else {
      alertDontFoundSales('month');
    }

    const dataYearReport = dataProduct[0].record.filter((dy) => dy.createdate === getFormatYear(lastDateDay));

    if (dataYearReport !== undefined) {
      const updateYearChart = {
        series: [
          { name: 'Cant. registradas', data: dataYearReport[0]?.months.map((x) => x.amountStock) },
          { name: 'Cant. vendidas', data: dataYearReport[0]?.months.map((x) => x.amountSell) },
        ],
        options: {
          chart: {
            id: 'anual-apexchart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: dataYearReport[0]?.months.map((x) => x.createdate),
            labels: {
              formatter(value) {
                return moment(value).format('MMM');
              },
            },
          },
        },
      };
      SetSeriesYear(updateYearChart);
    } else {
      alertDontFoundSales('year');
    }
  };

  const resetReports = () => {
    setDateDayReport([...[]]);
    setDateMonthReport([...[]]);
    setDateYearReport([...[]]);
  };

  const handlerChangeProductToShow = (e) => {
    resetReports();

    const productStock = data.filter((p) => p.id === e.target.value);

    const arrayYear = [];
    const arrayMonths = [];
    const arrayDays = [];

    if (productStock[0].record.length !== 0) {
      for (let record of productStock[0].record) {
        const element = record;

        if (element.months.length !== 0) {
          for (let itemMonth of element.months) {
            const elementMonths = itemMonth;

            if (elementMonths.days.length !== 0) {
              for (let itemDays of elementMonths.days) {
                const elementDays = itemDays;
                arrayDays.push(elementDays.createdate);
                setDateDayReport(dateDayReport => [...dateDayReport, elementDays.createdate]);
              }
            }
            arrayMonths.push(elementMonths.createdate);
            setDateMonthReport(dateMonthReport => [...dateMonthReport, elementMonths.createdate]);
          }
        }
        arrayYear.push(element.createdate);
        setDateYearReport(dateYearReport => [...dateYearReport, element.createdate]);
      }
    }

    setProductToShow(productStock);
    handlerSetInitdata(productStock, arrayYear, arrayMonths, arrayDays);
  };

  return (
    <div className="h-100 px-3 pt-2 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <div className="col-12 mb-2">
          <h3
            className={`text-test-title`}
          >
            Reporte de Stock

            {productToShow.length !== 0 && (
              <FormControl variant="standard" className="float-end select-product-Stock">
                <InputLabel id="label-select-product-Stock">
                  Lista de Productos
                </InputLabel>
                <Select
                  labelId="label-select-product-Stock"
                  id="select-product-Stock"
                  value={productToShow.length === 0 ? '0' : productToShow[0].id}
                  onChange={(e) => handlerChangeProductToShow(e)}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="0"
                  >
                    Seleccionar
                  </MenuItem>
                  {
                    data.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            )}
          </h3>
        </div>
      </div>
      {productToShow.length === 0 && (
        <div className="box-content d-flex flex-column justify-content-center align-items-center">
          <span className="text-muted mb-2">
            Seleccione un producto
          </span>
          <FormControl variant="standard" className="select-product-Stock">
            <Select
              labelId="label-select-product-Stock"
              id="select-product-Stock"
              value={productToShow.length === 0 ? '0' : productToShow[0].id}
              onChange={(e) => handlerChangeProductToShow(e)}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="0"
              >
                Seleccionar
              </MenuItem>
              {
                data.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      )}
      {productToShow.length !== 0 && (
        <div className="row w-100 g-0">
          <div className="col-4 mb-2">
            <span className="fs-5 text-primary">
              {productToShow[0].name}
            </span>
            <div className="h-100 d-flex flex-column mt-2">
              <span className="fs-6 text-muted">
                Categoría
                <br />
                <span className="fs-4 text-dark">
                  {productToShow[0].categoryName}
                </span>
              </span>

              <span className="fs-6 text-muted">
                Precio
                <br />
                <span className="fs-4 text-dark">
                  {numberToMoney(productToShow[0].price)}
                </span>
              </span>
            </div>
          </div>
          <div className="col-4 mb-2">
            <span
              className="fs-5 text-primary"
            >
              Gráfica diaria
            </span>
            <FormControl variant="standard" className="select-record-day-Stock float-end">
              <Select
                labelId="label-select-record-day-Stock"
                id="select-record-day-Stock"
                value={dayReportSelected === '' ? 0 : dayReportSelected}
                onChange={(e) => handlerChangeDay(e.target.value)}
                MenuProps={MenuProps}
              >
                {dateDayReport.length === 0 && (
                  <MenuItem
                    value="0"
                  >
                    Seleccionar
                  </MenuItem>
                )}
                {
                  dateDayReport.reverse().map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                    >
                      {moment(item).format('D MMMM YYYY')}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <ReactApexChart
              options={seriesDaily.options}
              series={seriesDaily.series}
              type="bar"
              height={250}
            />
          </div>
          <div className="col-4 mb-2">
            <div className="card card-body border-0 h-100">
              <div className="h-100 d-flex flex-column text-center justify-content-evenly">

                <span className="fs-6 text-muted">
                  Cantidades registradas en el día
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataDay(dayReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataDay(dayReportSelected).amountStock
                    )}
                  </span>
                </span>

                <span className="fs-6 text-muted">
                  Cantidades vendidas en el día
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataDay(dayReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataDay(dayReportSelected).amountSell
                    )}
                  </span>
                </span>

                <span className="fs-6 text-muted">
                  Cantidades restantes
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataDay(dayReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataDay(dayReportSelected).amountRemaining
                    )}
                  </span>
                </span>

              </div>
            </div>
          </div>

          <div className="col-12 mb-2">
            <div className="row g-0">
              <div className="col-12 text-end">
                <p className="text-primary mb-0">
                  Actividad del día {moment(dayReportSelected).format('D MMMM YYYY')}
                </p>
              </div>
              <div className="col-6 pe-1 mb-2">
                <span>
                  Cantidades añadidas
                </span>
                <div className="virtualized-window mt-1">
                  {(handlerGetDataDay(dayReportSelected) !== undefined && handlerGetDataDay(dayReportSelected).edit.length !== 0) ? (
                    handlerGetDataDay(dayReportSelected).edit.map((item) => (
                      <ListItem button key={item.id}> 
                        <ListItemText
                          primary={
                            <span
                              className="text-dark"
                            >
                              {item.amountAdded !== undefined && item.amountAdded}
                              &nbsp;-&nbsp;
                              <span
                                className="text-muted"
                              >
                              {item.name !== undefined && item.name}
                              </span>
                            </span>
                            }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <div className="h-100 d-flex flex-column text-center justify-content-center align-items-center">
                      <span className="text-muted">
                        No hay registros
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6 ps-1 mb-2">
                <span>
                  Cantidades vendidas
                </span>
                <div className="virtualized-window mt-1">
                  {(handlerGetDataDay(dayReportSelected) !== undefined && handlerGetDataDay(dayReportSelected).transactions.length !== 0) ? (
                    handlerGetDataDay(dayReportSelected).transactions.map((item) => (
                      <ListItem button key={item.id}>
                        <ListItemText
                          primary={
                          <span
                            className="text-dark"
                          >
                            {item.amount !== undefined && item.amount}
                            &nbsp;a&nbsp;
                            <span
                              className="text-muted"
                            >
                            {item.price !== undefined && (
                              numberToMoney(item.price)
                            )}
                            </span>
                          </span>
                          }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <div className="h-100 d-flex flex-column text-center justify-content-center align-items-center">
                      <span className="text-muted">
                        No hay registros
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>  
          </div>

          <div className="col-6 mb-2">
            <span
              className="fs-5 text-primary"
            >
              Gráfica mensual
            </span>
            <FormControl variant="standard" className="select-record-year-month float-end">
              <Select
                labelId="label-select-record-month-Stock"
                id="select-record-month-Stock"
                value={monthReportSelected === '' ? 0 : monthReportSelected}
                onChange={(e) => handlerChangeMonth(e.target.value)}
                MenuProps={MenuProps}
              >
                {dateMonthReport.length === 0 && (
                  <MenuItem
                    value="0"
                  >
                    Seleccionar
                  </MenuItem>
                )}
                {
                  dateMonthReport.reverse().map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                    >
                      {moment(item).format('MMMM YYYY')}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <ReactApexChart
              options={seriesMonthly.options}
              series={seriesMonthly.series}
              type="bar"
              height={250}
            />
          </div>
          <div className="col-6 mb-2">
            <div className="card card-body border-0 h-100">
              <div className="h-100 d-flex flex-column text-center justify-content-evenly">

                <span className="fs-6 text-muted">
                  Cantidades registradas en el mes
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataMonth(monthReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataMonth(monthReportSelected).amountStock
                    )}
                  </span>
                </span>

                <span className="fs-6 text-muted">
                  Cantidades vendidas en el mes
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataMonth(monthReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataMonth(monthReportSelected).amountSell
                    )}
                  </span>
                </span>

                <span className="fs-6 text-muted">
                  Cantidades restantes
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataMonth(monthReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataMonth(monthReportSelected).amountRemaining
                    )}
                  </span>
                </span>

              </div>
            </div>
          </div>

          <div className="col-6 mt-2 mb-2">
            <span
              className="fs-5 text-primary"
            >
              Gráfica anual
            </span>
            <FormControl variant="standard" className="select-record-year-Stock float-end">
              <Select
                labelId="label-select-record-year-Stock"
                id="select-record-year-Stock"
                value={yearReportSelected === '' ? 0 : yearReportSelected}
                onChange={(e) => handlerChangeYear(e.target.value)}
                MenuProps={MenuProps}
              >
                {dateYearReport.length === 0 && (
                  <MenuItem
                    value="0"
                  >
                    Seleccionar
                  </MenuItem>
                )}
                {
                  dateYearReport.reverse().map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <ReactApexChart
              options={seriesYear.options}
              series={seriesYear.series}
              type="bar"
              height={250}
            />
          </div>
          <div className="col-6 mb-2">
            <div className="card card-body border-0 h-100">
              <div className="h-100 d-flex flex-column text-center justify-content-evenly">

                <span className="fs-6 text-muted">
                  Cantidades registradas en el año
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataYear(dayReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataYear(dayReportSelected).amountStock
                    )}
                  </span>
                </span>

                <span className="fs-6 text-muted">
                  Cantidades vendidas en el año
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataYear(dayReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataYear(dayReportSelected).amountSell
                    )}
                  </span>
                </span>

                <span className="fs-6 text-muted">
                  Cantidades restantes
                  <br />
                  <span className="fs-4 text-dark">
                    {handlerGetDataYear(dayReportSelected) === undefined ? (
                      0
                    ) : (
                      handlerGetDataYear(dayReportSelected).amountRemaining
                    )}
                  </span>
                </span>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReportStock;
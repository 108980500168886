/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const rolSlice = createSlice({
  name: 'rol',
  initialState: {
    value: [],
  },
  reducers: {
    setRol: (state, action) => {
      state.value = action.payload
    },
    insertRol: (state, action) => {
      state.value.push(action.payload)
    },
    updateRol: (state, action) => {
			state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index] = action.payload
				}
			})
    },
    deleteRol: (state, action) => {
      state.value = state.value.filter(item => item.id !== action.payload)
    },
  }
});

export const { setRol, insertRol, updateRol, deleteRol } = rolSlice.actions;

export default rolSlice.reducer;

/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'load',
  initialState: {
    value: {
      loading: false,
    },
  },
  reducers: {
    startLoading: (state, action) => {
      state.value.loading = true
    },
    stopLoading: (state, action) => {
      state.value.loading = false
    },
  }
});

export const { startLoading, stopLoading } = loadingSlice.actions;

export default loadingSlice.reducer;

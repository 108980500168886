import React, { useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  getRequest, postRequest, putRequest, deleteRequest, createPhoto, getLangUser,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Promotions() {
  const apiUrl = 'promotions';
  const storedData = 'promotions';

  const [data, setData] = useState([]);

  const [modalItem, setmodalItem] = useState(false);
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [promotion, setPromotion] = useState({
    id: '',
    createdate: '',
    active: false,
    description: '',
    endDate: moment().add(1, 'd').format('YYYY-MM-DD'),
    image: '',
    initDate: moment().format('YYYY-MM-DD'),
    products: [],
    title: '',
  });
  
  const [imgCompressed, setimgCompressed] = useState();
  const [imgCompressedblob, setimgCompressedblob] = useState();
  const [newImg, setNewImg] = useState(false);
  const [imgLoad, setImgLoad] = useState(false);

  const getPromotions = async () => {
    const requestStock = await getRequest(apiUrl, { 
      date: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS')
    }, storedData, false);
    if (requestStock.status === 200) {
      if (requestStock.data.dataResponse.length !== 0) {
        setData(requestStock.data.dataResponse);
      }
    }
  };

  useEffect(() => {
    getPromotions();
  }, []);

  const handlerClose = () => {
    setPromotion({
      id: '',
      createdate: '',
      active: false,
      description: '',
      endDate: moment().add(1, 'd').format('YYYY-MM-DD'),
      image: '',
      initDate: moment().format('YYYY-MM-DD'),
      products: [],
      title: '',
    });
    setimgCompressed(null);
    setimgCompressedblob(null);
    setNewImg(false);
    setImgLoad(false);
    setmodalItem(false);
  };

  const handleChange = (event) => {
    setPromotion({
      ...promotion,
      [event.target.name]: event.target.value,
    });
  };

  const handleApiResponse = (response, successMessage, errorMessage) => {
    if (response.status === 200) {
      setmsmAlert(successMessage);
      setalertSuccess(true);
    } else {
      setmsmAlert(errorMessage);
      setalertError(true);
    }
  };

  const resetRequest = () => {
    setPromotion({
      createdate: '',
      active: false,
      description: '',
      endDate: moment().add(1, 'd').format('YYYY-MM-DD'),
      image: '',
      initDate: moment().format('YYYY-MM-DD'),
      products: [],
      title: '',
    });
  };

  const handlerCreatePromo = async () => {
    const promo = promotion;

    if (promo.id === '') {
      promo.createdate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
      promo.id = uuidv4();
      promo.active = true;

      const responseCreatePromo = await postRequest(apiUrl, promo, storedData, false);
      if (responseCreatePromo.status === 200) {
        getPromotions();
      }
      handleApiResponse(responseCreatePromo, 'Promoción creada correctamente.', 'Error en la creación.');
    }

    if (promo.id !== '') {
      const responsePutPromo = await putRequest(apiUrl, promo, storedData, false);
      if (responsePutPromo.status === 200) {
        getPromotions();
      }
      handleApiResponse(responsePutPromo, 'Actualizado.', 'Error en la actualización.');
    }
    
    resetRequest();
    setmodalItem(false);
  };

  const handlerCreate = async () => {
    if (newImg === true) {
      const responsePhoto = await createPhoto('upload-image', imgCompressedblob);
      if (responsePhoto.status === 200) {
        const itemData = promotion;
        itemData.image = responsePhoto.data.imageUrl
        setPromotion(itemData);
  
        handlerCreatePromo();
      }
    } else {
      handlerCreatePromo();
    }
  };

  const handlerViewPromo = (id) => {
    if (id !== '') {
      const promo = data.filter((x) => x.id === id);
      setPromotion(promo[0]);
    }

    setmodalItem(true);
  };

  const compressImage = (event) => {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1080,
      useWebWorker: true,
    };

    imageCompression(imageFile, options)
      .then((compressedFile) => {
        const urlImg = URL.createObjectURL(compressedFile);
        setimgCompressed(urlImg);
        setimgCompressedblob(compressedFile);
        setNewImg(true);
        setImgLoad(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlerOpenModalDelete = (id) => {
    setdeleteItem(id);
    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handleApiResponseDelete = (response, successMessage, errorMessage) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setmsmAlert(successMessage);
        setalertSuccess(true);
      } else {
        setmsmAlert(errorMessage);
        setalertError(true);
      }
    } else {
      setmsmAlert(errorMessage);
      setalertError(true);
    }
  };

  const handlerDelete = async () => {
    const deleteData = await deleteRequest(apiUrl, { createdate: deleteItem }, storedData, false);
    if (deleteData.data.status === true) {
      getPromotions();
    }
    handleApiResponseDelete(deleteData, 'Se ha eliminado con exito.', 'Error en el registro, por favor intente de nuevo.');
    setdeleteItem('');
    handlerCloseModalDelete();
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const imgComponent = imgCompressed ? (
    <img
      width="588"
      height="416"
      className="m-auto img-promotion"
      src={imgCompressed}
      alt={promotion.title}
    />
  ) : (
    <Skeleton
      variant="rect"
      width={588}
      height={416}
    />
  );

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <MaterialTable
        title={<h3>Promociones</h3>}
        columns={[{
            title: 'Fin de la Promo.',
            field: 'endDate',
            width: '10%',
          },
          {
            title: 'Titulo',
            field: 'title',
            width: '25%',
          },
          {
            title: 'Descripción',
            field: 'description',
            width: '65%',
          }
        ]}
        data={data}
        actions={
          [
            {
              icon: 'edit',
              tooltip: 'Actualizar',
              onClick: (event, rowData) => handlerViewPromo(rowData.id),
            },
            {
              icon: 'delete',
              tooltip: 'Eliminar',
              onClick: (event, rowData) => handlerOpenModalDelete(rowData.createdate),
            },
            {
              icon: 'add',
              tooltip: 'Crear',
              isFreeAction: true,
              onClick: () => handlerViewPromo(''),
            },
          ]
        }
        options={{
          maxColumnSort: 'all_columns',
          paging: data.length > 100,
          pageSize: 100,
          pageSizeOptions: [100, 150, 200, 300],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
            searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
          },
          header: {
            actions: labelLang(getLangUser(), 'table', 'actions'),
          },
          pagination: {
            labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
            labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
            labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
            firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
            firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
            previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
            previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
            nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
            nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
            lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
            lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
          },
        }}
      />

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerClose(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                {promotion.id === '' ? 'Crear promoción' : 'Detalle'}
              </h4>
            </div>
            <div className="col-12 mt-1 mb-1">
              <small>
                <InputLabel>
                  Imagen
                </InputLabel>
              </small>
            </div>
            <div className="col-12 mt-1 mb-3 text-center d-flex flex-column align-items-center">
              {promotion.image === undefined || promotion.image === '' ? (
                <>
                  {imgComponent}
                </>
              ) : (
                <img
                  width="588"
                  height="416"
                  className="m-auto img-promotion"
                  src={imgCompressed || promotion.image}
                  alt={promotion.title}
                />
              )}
              <input
                id="icon-button-file-create"
                accept="image/*"
                className="d-none"
                type="file"
                onChange={(e) => compressImage(e)}
              />
              <label htmlFor="icon-button-file-create" className="mt-2">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
            </div>
            <div className="w-100">
              <ul className="text-muted">
                <li>
                  <small>
                    La imagen no debe pesar mas de 1MB
                  </small>
                </li>
                <li>
                  <small>
                    Se requiere imagen para registrar el producto en stock
                  </small>
                </li>
              </ul>
            </div>
            <div className="col-4 mb-2">
              <TextField
                variant="standard"
                error={promotion.title === ''}
                label="Titulo *"
                className="w-100"
                name="title"
                value={promotion.title === '' ? '' : promotion.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-4 mb-2">
              <TextField
                variant="standard"
                error={promotion.initDate === '' || promotion.initDate > promotion.endDate}
                label="Fecha inicio *"
                className="w-100"
                name="initDate"
                defaultValue={promotion.initDate}
                onChange={(e) => handleChange(e)}
                type="date"
              />
              {promotion.initDate > promotion.endDate && (
                <small className="text-danger">
                  La fecha de inicio debe ser menor a la fecha de fin.
                </small>
              )}
              {promotion.endDate === promotion.initDate && (
                <small className="text-danger">
                  La fecha de inicio y fin no pueden ser iguales.
                </small>
              )}
            </div>
            <div className="col-4 mb-2">
              <TextField
                variant="standard"
                error={promotion.endDate === '' || promotion.endDate < promotion.initDate}
                label="Fecha fin *"
                name="endDate"
                className="w-100"
                onChange={(e) => handleChange(e)}
                defaultValue={promotion.endDate}
                type="date"
              />
              {promotion.endDate < promotion.initDate && (
                <small className="text-danger">
                  La fecha de fin debe ser mayor a la fecha de inicio.
                </small>
              )}
              {promotion.endDate === promotion.initDate && (
                <small className="text-danger">
                  La fecha de inicio y fin no pueden ser iguales.
                </small>
              )}
            </div>
            <div className="col-12 mb-4">
              <TextField
                variant="standard"
                label="Descripción *"
                name="description"
                className="w-100"
                onChange={(e) => handleChange(e)}
                type="text"
                multiline
                minRows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                value={promotion.description === '' ? '' : promotion.description}
              />
            </div>
            <div className="col-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerClose}>Cancelar</button>
            </div>
            {promotion.id !== '' ? (
              <div className="col-6">
                <button
                  className="btn btn-primary w-100"
                  type="button"
                  onClick={handlerCreate}
                  disabled={
                    !(promotion.title !== '' && promotion.description !== ''
                    && promotion.initDate <= promotion.endDate
                    && promotion.endDate >= promotion.initDate
                    && promotion.initDate !== promotion.endDate)
                  }
                >
                  Actualizar
                </button>
              </div>
            ) : (
              <div className="col-6">
                <button
                  className="btn btn-primary w-100"
                  type="button"
                  onClick={handlerCreate}
                  disabled={
                    !(promotion.title !== '' && promotion.description !== ''
                    && promotion.initDate <= promotion.endDate
                    && promotion.endDate >= promotion.initDate
                    && promotion.initDate !== promotion.endDate
                    && imgLoad === true)
                  }
                >
                  Crear
                </button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar la promoción?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Promotions;

/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const specialProductsSlice = createSlice({
  name: 'specialProducts',
  initialState: {
    value: [],
  },
  reducers: {
    setProduct: (state, action) => {
      state.value = action.payload
    },
    insertProduct: (state, action) => {
      state.value.push(action.payload)
    },
    updateProduct: (state, action) => {
			state.value.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value[index] = action.payload
				}
			})
    },
    deleteProduct: (state, action) => {
      state.value = state.value.filter(item => item.id !== action.payload)
    },
  }
});

export const { setProduct, insertProduct, updateProduct, deleteProduct } = specialProductsSlice.actions;

export default specialProductsSlice.reducer;

import React from 'react';
import { Link } from 'react-router-dom';

function page404() {
  return (
    <div className="w-100 h-100-i pt-5 d-flex justify-content-center bg-security">
      <div className="container mt-4 text-center" style={{ height: '95%' }}>
        <h1 className="text-white mb-4 mt-2">Oops!</h1>
        <p className="text-white mb-5">No encontramos la página que buscas, puedes intentar volver a la página principal.</p>
        <span className="button-404 mt-2">404</span>
        <br />
        <br />
        <br />
        <br />
        <Link
          className="button-back-404 text-decoration-none mt-4 text-white"
          to="/login"
        >
          Volver al LogIn
        </Link>
        <div className="objects">
          <img src="../assets/img/404/rocket.svg" className="object_rocket" alt="404 not found rocket" width="40px" />
          <div className="earth-moon">
            <img className="object_earth" src="../assets/img/404/earth.svg" width="100px" alt="404 not found earth" />
            <img className="object_moon" src="../assets/img/404/moon.svg" width="80px" alt="404 not found moon" />
          </div>
          <div className="box_astronaut">
            <img className="object_astronaut" src="../assets/img/404/astronaut.svg" alt="404 not found astronaut" width="140px" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default page404;

/* eslint-disable */
import React from 'react';
import {
  Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller
} from 'react-scroll';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import { urlImages } from '../service';

function tutorialConfiguration() {
  return (
    <div className="row mt-2">
      <h3>
        Configuración
        <h5
          className="float-end"
          data-bs-toggle="collapse"
          href="#collapseIndice"
          role="button"
          aria-expanded="false"
          aria-controls="collapseTransactions"
        >
          Indice&nbsp;
          <ArrowDropDownIcon />
        </h5>
      </h3>
      <div className="collapse px-5" id="collapseIndice">
        <div className="container card card-body">
          <ul className="nav navbar-nav">
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-one"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo configurar mi Pos System?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-two"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Crear un Material?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-three"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Editar un Material?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-four"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Eliminar un Material?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-five"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Crear una Categoría?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-six"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Editar una Categoría?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-seven"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Eliminar una Categoría?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-eight"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Editar la Prioridad de las Categorías?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-nine"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Crear un Producto?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-ten"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Eliminar un Producto?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-eleven"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Crear un Rol?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-twelve"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Editar un Rol?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-thirteen"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Eliminar un Rol?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-fourteen"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Crear un Empleado?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-fiveteen"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Editar un Empleado?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-sixteen"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Eliminar un Empleado?
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Element className="element overflow-scroll" id="containerElement">
        <Element name="element-one">
          <h5 className="mt-1">
            ¿Cómo configurar Pos System?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior.
            </li>
            <li>
              Nos mostrará en pantalla las diferentes opciones donde
              podemos personalizar nuestro Pos System y
              administrar nuestras ventas.
            </li>
            <li>
              Aquí encontraremos:
            </li>
            <ul>
              <li>
                Materiales
              </li>
              <li>
                Categorías
              </li>
              <li>
                Productos
              </li>
              <li>
                Roles
              </li>
              <li>
                Empleados
              </li>
            </ul>
            <li>
              En cada una de estas opciones podremos:
            </li>
            <ul>
              <li>
                Crear
              </li>
              <li>
                Editar y Actualizar
              </li>
              <li>
                Listar
              </li>
              <li>
                Eliminar
              </li>
            </ul>
          </ol>
        </Element>
        <Element name="element-two">
          <h5 className="mt-4">
            ¿Cómo Crear un Material?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
              en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Materiales
              </mark>
            </li>
            <li>
              Allí veremos una tabla donde nos&nbsp;
              <mark>
                Listará
              </mark>
              &nbsp;las Categorías que ya tenemos registradas y de lo contrario no visualizaremos
              ningún registro.
            </li>
            <img
              src={urlImages+'/assets/img/tutorials/tutorial-configuracion-05.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 05"
            />
            <li>
              En la parte superior derecha de la visual de Productos veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <AddIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;como único&nbsp;
              <mark>
                Campo Necesario
              </mark>
              &nbsp;para crear nuestro Material.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-06.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 06"
            />
            <li>
              Cuando ya digitemos el&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;de nuestro nuevo Material.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-07.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 07"
            />
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
              &nbsp;para que se Cree.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-08.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 08"
            />
          </ol>
        </Element>
        <Element name="element-three">
          <h5 className="mt-4">
            ¿Cómo Editar un Material?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Materiales
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-03.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 03"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Materiales veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <EditIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;como único&nbsp;
              <mark>
                Campo Necesario
              </mark>
              &nbsp;para Editar nuestro Material.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-09.PNG'}
              className="mt-2 mb-2 w-75 btn-rounded"
              alt="pos system - tutorial configuration 09"
            />
            <img
              src={urlImages+'tutorial-configuracion-10.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 10"
            />
            <li>
              Cuando ya digitemos el nuevo&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;de nuestro Material, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
              &nbsp;para que se Actualice.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-11.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 11"
            />
            <img
              src={urlImages+'tutorial-configuracion-12.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 12"
            />
          </ol>
        </Element>
        <Element name="element-four">
          <h5 className="mt-4">
            ¿Cómo Eliminar un Material?
          </h5>
          <div className="alert alert-info mx-5 py-1 text-center">
            <small>
              <b>
                ¡Importante!
              </b>
              &nbsp;Los Materiales para poderse&nbsp;
              <b>
                Eliminar
              </b>
              &nbsp;no deben estar asociados a&nbsp;
              <b>
                Ningún Producto
              </b>
              .
            </small>
          </div>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Materiales
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-03.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 03"
            />
            <img
              src={urlImages+'tutorial-configuracion-13.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 13"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Materiales veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <DeleteIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              , se abrirá un Popup donde nos preguntará si estamos
              seguros de eliminar el Material.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-14.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 14"
            />
            <li>
              Allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Eliminar
              </button>
              &nbsp;y luego nos aparecerá un mensaje confirmando que el Material
              se ha Eliminado.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-15.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 15"
            />
          </ol>
        </Element>
        <Element name="element-five">
          <h5 className="mt-4">
            ¿Cómo Crear una Categoría?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Categorías
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-02.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 02"
            />
            <li>
              Allí veremos una tabla donde nos&nbsp;
              <mark>
                Listará
              </mark>
              &nbsp;las Categorías que ya tenemos registradas y de lo contrario no visualizaremos
              ningún registro.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-16.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 16"
            />
            <li>
              En la parte superior derecha de la visual de Productos veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <AddIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;como único&nbsp;
              <mark>
                Campo Necesario
              </mark>
              &nbsp;para crear nuestra Categoría.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-17.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 17"
            />
            <li>
              Cuando ya digitemos el&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;de nuestra nueva Categoría, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
              &nbsp;para que se Cree.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-18.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 18"
            />
          </ol>
        </Element>
        <Element name="element-six">
          <h5 className="mt-4">
            ¿Cómo Editar una Categoría?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Categorías
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-03.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 03"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Categorías veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <EditIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;como único&nbsp;
              <mark>
                Campo Necesario
              </mark>
              &nbsp;para Editar nuestra Categoría.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-19.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 19"
            />
            <li>
              Cuando ya digitemos el nuevo&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;de nuestra Categoría, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
              &nbsp;para que se Actualice.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-20.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 20"
            />
            <img
              src={urlImages+'tutorial-configuracion-21.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 21"
            />
            <img
              src={urlImages+'tutorial-configuracion-22.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 22"
            />
          </ol>
        </Element>
        <Element name="element-seven">
          <h5 className="mt-4">
            ¿Cómo Eliminar una Categoría?
          </h5>
          <div className="alert alert-info mx-5 py-1 text-center">
            <small>
              <b>
                ¡Importante!
              </b>
              &nbsp;Las Categorías para poderse&nbsp;
              <b>
                Eliminar
              </b>
              &nbsp;no deben estar asociados a&nbsp;
              <b>
                Ningún Producto
              </b>
              .
            </small>
          </div>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Categorías
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-03.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 03"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Categorías veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <DeleteIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              , se abrirá un Popup donde nos preguntará si estamos
              seguros de eliminar la Categoría.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-22.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 22"
            />
            <li>
              Allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Eliminar
              </button>
              &nbsp;y luego nos aparecerá un mensaje confirmando que la Categoría
              se ha Eliminado.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-23.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 23"
            />
            <img
              src={urlImages+'tutorial-configuracion-24.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 24"
            />
          </ol>
        </Element>
        <Element name="element-eight">
          <h5 className="mt-4">
            ¿Cómo Editar la Prioridad de las Categorías?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Categorías
              </mark>
            </li>
            <li>
              Allí veremos una tabla donde nos&nbsp;
              <mark>
                Listará
              </mark>
              &nbsp;las Categorías que ya tenemos registradas y de lo contrario no visualizaremos
              ningún registro.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-25.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 25"
            />
            <li>
              En la parte superior derecha de la visual de Productos veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <ListIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos el listado de nuestras
              Categorías en&nbsp;
              <mark>
                Orden
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-26.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 26"
            />
            <li>
              Aquí&nbsp;
              <mark>
                <i>
                  Arrastraremos
                </i>
              </mark>
              &nbsp;La Categoría que queramos&nbsp;
              <mark>
                Modificar en el Orden de Prioridad
              </mark>
              , Esto servirá ajustar el orden en el cual se ven en la visual de&nbsp;
              <mark>
                Nueva Orden
              </mark>
              &nbsp;cuando tomemos un pedido.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-27.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 27"
            />
            <li>
              Luego de ajustar nuestras Categorías en el&nbsp;
              <mark>
                Orden de Prioridad
              </mark>
              &nbsp;que queremos, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-28.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 28"
            />
            <img
              src={urlImages+'tutorial-configuracion-29.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 29"
            />
          </ol>
        </Element>
        <Element name="element-nine">
          <h5 className="mt-4">
            ¿Cómo Crear un Producto?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Productos
              </mark>
            </li>
            <li>
              Allí veremos una tabla donde nos&nbsp;
              <mark>
                Listará
              </mark>
              &nbsp;los productos que ya tenemos registrados y de lo contrario no visualizaremos
              ningún registro.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-01.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 01"
            />
            <li>
              En la parte superior derecha de la visual de Productos veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <AddIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos los campos&nbsp;
              <mark>
                Necesarios
              </mark>
              &nbsp;para crear nuestro Producto.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-30.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 30"
            />
            <li>
              Aquí tendremos que completar todos los campos como se puede apreciar en la imagen.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-31.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 31"
            />
            <li>
              Para luego Finalizar con la creación de nuestro producto haciendo&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-32.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 32"
            />
          </ol>
        </Element>
        <Element name="element-ten">
          <h5 className="mt-4">
            ¿Cómo Eliminar un Producto?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Productos
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-01.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 01"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Productos veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <DeleteIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              , se abrirá un Popup donde nos preguntará si estamos
              seguros de eliminar el Producto.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-37.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 37"
            />
            <li>
              Allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Eliminar
              </button>
              &nbsp;y luego nos aparecerá un mensaje confirmando que el Producto
              se ha Eliminado.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-38.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 38"
            />
            <img
              src={urlImages+'tutorial-configuracion-39.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 39"
            />
          </ol>
        </Element>
        <Element name="element-eleven">
          <h5 className="mt-4">
            ¿Cómo Crear un Rol?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Roles
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-05.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 05"
            />
            <li>
              Allí veremos una tabla donde nos&nbsp;
              <mark>
                Listará
              </mark>
              &nbsp;los Roles que ya tenemos registradas y de lo contrario no visualizaremos
              ningún registro.
            </li>
            <li>
              En la parte superior derecha de la visual de Roles veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <AddIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;como único&nbsp;
              <mark>
                Campo Necesario
              </mark>
              &nbsp;para crear nuestro Rol.
            </li>
            <li>
              Cuando ya digitemos el&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;de nuestro nuevo Rol, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
              &nbsp;para que se Cree.
            </li>
          </ol>
        </Element>
        <Element name="element-twelve">
          <h5 className="mt-4">
            ¿Cómo Editar un Rol?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Roles
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-05.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 05"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Roles veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <EditIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;como único&nbsp;
              <mark>
                Campo Necesario
              </mark>
              &nbsp;para Editar nuestro Rol.
            </li>
            <li>
              Cuando ya digitemos el nuevo&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;de nuestro Rol, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
              &nbsp;para que se Actualice.
            </li>
          </ol>
        </Element>
        <Element name="element-thirteen">
          <h5 className="mt-4">
            ¿Cómo Eliminar un Rol?
          </h5>
          <div className="alert alert-info mx-5 py-1 text-center">
            <small>
              <b>
                ¡Importante!
              </b>
              &nbsp;Los Roles para poderse&nbsp;
              <b>
                Eliminar
              </b>
              &nbsp;no deben estar asociados a&nbsp;
              <b>
                Ningún Empleado
              </b>
              .
            </small>
          </div>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
              en el menú superior si no estamos allí, si nos encontramos en 
              ‘Configuración’
               hacemos&nbsp;
              <mark> Clic </mark>
              &nbsp;en el submenú&nbsp;
              <mark>
                Roles
              </mark>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-05.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 05"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Roles veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <DeleteIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              , se abrirá un Popup donde nos preguntará si estamos
              seguros de eliminar el Rol.
            </li>
            <li>
              Allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Eliminar
              </button>
              &nbsp;y luego nos aparecerá un mensaje confirmando que el Rol
              se ha Eliminado.
            </li>
          </ol>
        </Element>
        <Element name="element-fourteen">
          <h5 className="mt-4">
            ¿Cómo Crear un Empleado?
          </h5>
          <ol>  
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior.
            </li>
            <li>
              Allí veremos una tabla donde nos&nbsp;
              <mark>
                Listará
              </mark>
              &nbsp;los Empleados que ya tenemos registrados y de lo contrario no visualizaremos
              ningún registro.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-40.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 40"
            />
            <li>
              En la parte superior derecha de la visual de Empleados veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <AddIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos los&nbsp;
              <mark>
                Campos Necesarios
              </mark>
              &nbsp;para crear nuestro Empleado.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-41.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 41"
            />
            <li>
              Aquí tendremos que completar todos los campos como se puede apreciar en la imagen.
            </li>
            <li>
              Para luego Finalizar con la creación de nuestro producto haciendo&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-42.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 42"
            />
          </ol>
        </Element>
        <Element name="element-fiveteen">
          <h5 className="mt-4">
            ¿Cómo Editar un Empleado?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-43.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 43"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Empleados veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <EditIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos&nbsp;
              <mark>
                <i>
                  Nombre
                </i>
              </mark>
              &nbsp;como único&nbsp;
              <mark>
                Campo Necesario
              </mark>
              &nbsp;para Editar nuestro Empleado.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-44.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 44"
            />
            <li>
              Cuando ya hayamos editado los campos que queremos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-45.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 45"
            />
          </ol>
        </Element>
        <Element name="element-sixteen">
          <h5 className="mt-4">
            ¿Cómo Eliminar un Empleado?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Configuración’
               en el menú superior.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-46.PNG'
}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 46"
            />
            <li>
              En la parte Izquierda de cada registro de la tabla de Empleados veremos un&nbsp;
              <mark>
                botón
              </mark>
              &nbsp;
              <IconButton color="primary" aria-label="upload picture" component="span" size="small">
                <EditIcon className="text-dark" fontSize="inherit" />
              </IconButton>
              &nbsp;si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;se abrirá un Popup donde veremos los&nbsp;
              <mark>
                Campos Necesarios
              </mark>
              &nbsp;para Editar nuestro Empleado.
            </li>
            <img
              src={urlImages+'tutorial-configuracion-47.PNG'
}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 47"
            />
            <li>
              Aquí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;a la casilla 'Activo' y luego hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-configuracion-48.PNG'
}
              className="mt-2 mb-2 w-75 btn-rounded"
              alt="pos system - tutorial configuration 48"
            />
            <img
              src={urlImages+'tutorial-configuracion-49.PNG'}
              className="mt-2 mb-4 w-75 btn-rounded"
              alt="pos system - tutorial configuration 49"
            />
          </ol>
        </Element>
      </Element>
      {/*
        <img
          src={urlImages+'tutorial-configuracion-33.PNG'}
          className="mt-2 mb-4 w-75 btn-rounded"
          alt="pos system - tutorial configuration 33"
        />
        <img
          src={urlImages+'tutorial-configuracion-34.PNG'}
          className="mt-2 mb-4 w-75 btn-rounded"
          alt="pos system - tutorial configuration 34"
        />
        <img
          src={urlImages+'tutorial-configuracion-35.PNG'}
          className="mt-2 mb-4 w-75 btn-rounded"
          alt="pos system - tutorial configuration 35"
        />
        <img
          src={urlImages+'tutorial-configuracion-36.PNG'}
          className="mt-2 mb-4 w-75 btn-rounded"
          alt="pos system - tutorial configuration 36"
        />
      */}      
    </div>
  );
}

export default tutorialConfiguration;

import React, { useEffect } from 'react';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import TextField from '@mui/material/TextField';
import {
  getRequest,
} from '../Cache/cacheStorage';

function SalesPerEmployee() {
  const currentDateForInput = moment().format('YYYY-MM-DD');
  const seriesMensual = [];
  const OptMensual = {
    chart: {
      id: 'month-apexchart',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy',
      },
    },
  };

  const seriesDaily = [];
  const OptDaily = {
    chart: {
      id: 'daily-apexchart',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy',
      },
    },
  };

  const getTransactionsByDay = async (initial, end) => {
    const response = await getRequest('employee-daily-sales-report', { initialdate: initial, enddate: end, timeZone: new Date().getTimezoneOffset() / 60 }, 'employee-daily-sales-report', false);
    if (response.status === 200) {
      if (response.data.dataResponse.length !== 0) {
        seriesDaily.length = 0;

        for (let employee of response.data.dataResponse[0].employee) {
          const elementEmployee = employee;
          seriesDaily.push(elementEmployee);
        }

        OptDaily.xaxis.categories.length = 0;

        for (let category of response.data.dataResponse[0].categories) {
          const elementCategory = category;
          OptDaily.xaxis.categories.push(elementCategory);
        }

        ApexCharts.exec('daily-apexchart', 'updateSeries', seriesDaily);
        ApexCharts.exec('daily-apexchart', 'updateOptions', OptDaily);
      }
    }
  };

  const getTransactionsByMonth = async (initial, end) => {
    const response = await getRequest('employee-daily-sales-report', { initialdate: initial, enddate: end }, 'employee-daily-sales-report', false);
    if (response.status === 200) {
      if (response.data.dataResponse.length !== 0) {
        seriesMensual.length = 0;

        for (let employee of response.data.dataResponse[0].employee) {
          const elementEmployee = employee;
          seriesMensual.push(elementEmployee);
        }

        OptMensual.xaxis.categories.length = 0;

        for (let category of response.data.dataResponse[0].categories) {
          const elementCategory = category;
          OptMensual.xaxis.categories.push(elementCategory);
        }

        ApexCharts.exec('month-apexchart', 'updateSeries', seriesMensual);
        ApexCharts.exec('month-apexchart', 'updateOptions', OptMensual);
      }
    }
  };

  const getSalesDaily = async (eventInit, eventEnd, op) => {
    if (op === 0) {
      getTransactionsByDay(eventInit, eventEnd);
    }

    if (op === 1) {
      getTransactionsByDay(moment(eventInit).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        moment(eventInit).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'));
    }
  };

  const getSalesMonthly = async (eventInit, eventEnd, op) => {
    if (op === 0) {
      getTransactionsByMonth(eventInit, eventEnd);
    }

    if (op === 1) {
      getTransactionsByMonth(moment(eventInit).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        moment(eventInit).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'));
    }
  };

  useEffect(() => {
    getSalesDaily(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'), 0);
    getSalesMonthly(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'), 0);
  }, []);

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-3">
          <h3>Reporte de ventas de Empleados</h3>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h6>Diario</h6>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-end">
              <TextField
                variant="standard"
                id="date-daily"
                type="date"
                defaultValue={currentDateForInput}
                onChange={(e) => getSalesDaily(e.target.value, 0, 1)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
              <ReactApexChart
                options={OptDaily}
                series={seriesDaily}
                type="area"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h6>Mensual</h6>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-end">
              <TextField
                variant="standard"
                id="date-month"
                type="date"
                defaultValue={currentDateForInput}
                onChange={(e) => getTransactionsByMonth(e.target.value, 0, 1)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
              <ReactApexChart
                options={OptMensual}
                series={seriesMensual}
                type="area"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesPerEmployee;

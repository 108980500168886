/* eslint-disable */
import React from 'react';
import {
  Link, DirectLink, Element, Events, animateScroll
  as scroll, scrollSpy, scroller
} from 'react-scroll';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';

function tutorialClockIn() {
  return (
    <div className="row mt-2">
      <h3>
        Asistencia
        <h5
          className="float-end"
          data-bs-toggle="collapse"
          href="#collapseIndice"
          role="button"
          aria-expanded="false"
          aria-controls="collapseTransactions"
        >
          Indice&nbsp;
          <ArrowDropDownIcon />
        </h5>
      </h3>
      <div className="collapse px-5" id="collapseIndice">
        <div className="container card card-body">
          <ul className="nav navbar-nav">
          <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-one"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo crear un registro de asistencia?
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Element className="element overflow-scroll" id="containerElement">
        <Element name="element-one">
          <h5 className="mt-1">
            ¿Cómo crear un registro de asistencia?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Asistencia’
               en el menú en la parte superior derecha.
            </li>
            <li>
              Una vez en la visual, tendremos visible un reloj digital
              y un botón grande para crear el registro de asistencia.
            </li>
            <li>
              Para crear el registro, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp; en el botón&nbsp;
              <mark>
                Registro de asistencia para empleados
              </mark>
            </li>
          </ol>
        </Element>
        <Element name="element-two">
          <h5 className="mt-1">
            ¿Cómo agregar un empleado a la lista de asistencia?           
          </h5>
          <ol>
            <li>
              Una vez creada el registro asistencia,
              visualizaremos una lista con los empleados en la parte derecha de la pantalla.
            </li>
            <li>
              Allí seleccionamos el empleado que vamos a registrar, y luego hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbps;en el botón&nbsp;
              <button className="btn btn-primary btn-sm" type="button">
                Agregar asistencia
              </button>
            </li>
            <li>
              Una vez hayamos agregado al empleado al&nbsp;
              <mark>
                Registro de asistencia
              </mark>
              &nbsp;podremos visualizarlo en el listado inferior.
            </li>
          </ol>
        </Element>
        <Element name="element-three">
          <h5 className="mt-1">
            ¿Cómo agregar hora de salida en la lista de asistencia?
          </h5>
          <ol>
            <li>
              En el listado de Asistencia en la parte inferior de la pantalla, visualizaremos
              el empleado, el cual, hasta el momento habrá registrada la hora de ingreso.
            </li>
            <li>
              En la parte derecha de cada empleado, veremos que si&nbsp;
              <mark>
                no
              </mark>
              &nbsp;tiene hora de salida, nos mostrará un botón&nbsp;
              <button className="btn btn-primary btn-sm">
                Agregar hora de salida
              </button>
            </li>
            <li>
              Si hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el botón&nbsp;
              <button className="btn btn-primary btn-sm">
                Agregar hora de salida
              </button>
              Se registrará la hora de salida al empleado.
            </li>
          </ol>
        </Element>
      </Element>
    </div>
  );
}

export default tutorialClockIn;

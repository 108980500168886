import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AdminViews from './components/AdminViews/adminViews';

import Mesas from './components/Mesas/Mesas';
import Menu from './components/Menu/Menu';
import NuevaOrden from './components/NuevaOrden/NuevaOrden';
import Reportes from './components/Reportes/Reportes';
import Configuracion from './components/Configuracion/Configuracion';
import Ayuda from './components/Ayuda/ayuda';
import clockIn from './components/ClockIn/clockIn';

import Login from './components/Security/Login';
import Signup from './components/Security/Signup';
import page404 from './components/Security/page404';
import app404 from './components/Security/app404';
import ExpiredSession from './components/Security/expiredSession';
import { getTypeUserData, getAuthUser } from './components/Cache/cacheStorage';
import Pdf from './components/Security/pdf';

function App() {
  const loading = useSelector((state) => state.loading.value);

  const sessionToken = sessionStorage.getItem('cogdatauser');
  const userData = getTypeUserData();
  const authUserTest = getAuthUser();

  return (
    <div className="w-100 h-100-i app-overflow-none">
      {sessionToken ? (
        <Router>
          {userData.typeUser === 'pppp-xqqx-xbbb-x2x1' || userData.typeUser === 'qaqa-bbbb-asfh-axvn' ? (
            <div className="app-container">
              <Menu />
              {authUserTest === 'false' ? (
                <Switch>
                  <Route exact path="/" component={ExpiredSession} />
                  <Route path="/expired-session" component={ExpiredSession} />
                  <Route path="*" component={ExpiredSession} />
                </Switch>
              ) : (
                <Switch>
                  <Route exact path="/" component={clockIn} />
                  <Route path="/tables" component={Mesas} />
                  <Route path="/new-order" component={NuevaOrden} />
                  <Route path="/reports" component={Reportes} />
                  <Route path="/settings" component={Configuracion} />
                  <Route path="/ayuda" component={Ayuda} />
                  <Route path="/clock-in" component={clockIn} />
                  <Route path="/pdf" component={Pdf} />
                  <Route path="*" component={app404} />
                </Switch>
              )}

              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          ) : (
            userData.typeUser === 'x2x2-x2x4-x4x5-444f' && (
              <div className="app-container">
                <Menu />
                <Switch>
                  <Route exact path="/" component={AdminViews} />
                  <Route path="/admin-view" component={AdminViews} />
                  <Route path="/pdf" component={Pdf} />
                  <Route path="*" component={app404} />
                </Switch>
              </div>
            )
          )}
        </Router>
      ) : (
        <div className="w-100 h-100-i bg-security">
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/pdf" component={Pdf} />
              <Route path="*" component={page404} />
            </Switch>
          </Router>
        </div>
      )}
    </div>
  );
}

export default App;

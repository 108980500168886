import { configureStore } from '@reduxjs/toolkit';

import Rol from '../reducers/rol';
import PaymentMethod from '../reducers/paymentMethod';
import Materials from '../reducers/materials';
import Employees from '../reducers/employees';
import Products from '../reducers/products';
import Category from '../reducers/category';
import SpecialProducts from '../reducers/specialProducts';
import NewProduct from '../reducers/newProduct';
import Stock from '../reducers/stock';
import Transaction from '../reducers/transaction';
import LoadingSlice from '../reducers/load';

export default configureStore({
  reducer: {
    rol: Rol,
    paymentMethod: PaymentMethod,
    materials: Materials,
    employees: Employees,
    products: Products,
    category: Category,
    specialProducts: SpecialProducts,
    product: NewProduct,
    stock: Stock,
    transaction: Transaction,
    loading: LoadingSlice,
  },
});

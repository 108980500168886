import React, { useState, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Skeleton from '@material-ui/lab/Skeleton';
import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Loading from '../Loading/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { setMaterials } from '../reducers/materials';
import {
  setProducts, insertProducts, updateProducts, deleteProducts,
} from '../reducers/products';
import {
  setProduct, setName, setCategoryProduct, setCategoryName,
  setMaterialsList, setRemoveMaterialsList, setPrice,
} from '../reducers/newProduct';
import { setStock, updateStockProduct } from '../reducers/stock';
import { setCategory } from '../reducers/category';
import {
  getRequest, postRequest, putRequest, deleteRequest, createPhoto,
  getSubMenu, getLangUser, numberToMoney,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Product() {
  const submenu = getSubMenu('settings', 'product');

  const apiUrl = 'product';
  const storedData = 'product';

  const data = useSelector((state) => state.products.value);
  const materials = useSelector((state) => state.materials.value);
  const category = useSelector((state) => state.category.value);
  const item = useSelector((state) => state.product.value);

  const [materialList, setMaterialList] = useState([]);
  const [imgCompressed, setimgCompressed] = useState();
  const [imgCompressedblob, setimgCompressedblob] = useState();
  const [newImg, setNewImg] = useState(false);
  const [modalItem, setmodalItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  // #region Stock Methods
  const stock = useSelector((state) => state.stock.value);

  const handlerSetStock = (infoStock) => {
    dispatch(setStock(infoStock));
  };

  const getStock = async () => {
    const requestStock = await getRequest(apiUrl, {}, storedData, false);
    handlerSetStock(requestStock.data.dataResponse);
  };

  useEffect(() => {
    if (stock.length === 0) {
      getStock();
    }
  }, []);

  const handlerUpdateStockProduct = (infoCategory) => {
    dispatch(updateStockProduct(infoCategory));
  };
  // #endregion

  // #region Category Methods
  const handlerSetCategory = (infoCategory) => {
    dispatch(setCategory(infoCategory));
  };

  const getCategory = async () => {
    if (category.length === 0) {
      const requestCategory = await getRequest('category', {}, 'category', false);
      handlerSetCategory(requestCategory.data.dataResponse);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);
  // #endregion

  // #region Products Methods

  /* A function that is used to set the products. */
  const handlerSetProducts = (infoProducts) => {
    dispatch(setProducts(infoProducts));
  };

  const handlerInsertProducts = (infoProducts) => {
    dispatch(insertProducts(infoProducts));
  };

  const handlerUpdateProducts = (infoProducts) => {
    dispatch(updateProducts(infoProducts));
  };

  const handlerDeleteProducts = (infoProducts) => {
    dispatch(deleteProducts(infoProducts));
  };

  const getProduct = async () => {
    if (data.length === 0) {
      const requestProduct = await getRequest(apiUrl, {}, storedData, false);
      handlerSetProducts(requestProduct.data.dataResponse);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);
  // #endregion

  // #region Material Methods
  const handlerSetMaterials = (infoMaterials) => {
    dispatch(setMaterials(infoMaterials));
  };

  const getMaterials = async () => {
    if (materials.length === 0) {
      const requestMaterials = await getRequest('materials', {}, 'materials', false);
      handlerSetMaterials(requestMaterials.data.dataResponse);
    }
  };

  useEffect(() => {
    getMaterials();
  }, []);
  // #endregion

  // #region Product Methods
  const handlerSetProduct = (infoProduct) => {
    dispatch(setProduct(infoProduct));
  };
  const handlerSetNameProducts = (infoProduct) => {
    dispatch(setName(infoProduct));
  };
  const handlerSetCategoryProducts = (infoProduct) => {
    dispatch(setCategoryProduct(infoProduct));
  };
  const handlerSetCategoryNameProducts = (infoProduct) => {
    dispatch(setCategoryName(infoProduct));
  };
  const handlerSetMaterialsProducts = (infoProduct) => {
    dispatch(setMaterialsList(infoProduct));
  };
  const handlerRemoveMaterialsProducts = (infoProduct) => {
    dispatch(setRemoveMaterialsList(infoProduct));
  };
  const handlerSetPriceProducts = (infoProduct) => {
    dispatch(setPrice(infoProduct));
  };
  // #endregion

  const handlerOpenModalItem = (id) => {
    if (id !== '') {
      const obj = data.filter((x) => x.id === id);
      handlerSetProduct(obj[0]);
      setMaterialList(obj[0].materials);
    }
    setmodalItem(true);
  };
 
  const handlerCloseModalItem = () => {
    handlerSetProduct({
      id: '', name: '', category: '', categoryName: '', materials: [], photo: '', price: 0,
    });
    setMaterialList([]);
    setimgCompressed(null);
    setimgCompressedblob(null);
    setNewImg(false);

    setmodalItem(false);
  };

  const handleChangeName = (e) => {
    const { value } = e.target;
    handlerSetNameProducts(value);
  };

  const handleChangePrice = (e) => {
    const { value } = e.target;
    handlerSetPriceProducts(value);
  };

  const handleChangeCategory = (e) => {
    const { value } = e.target;
    handlerSetCategoryProducts(value);  

    const cat = category.filter((categ) => categ.id === value);
    handlerSetCategoryNameProducts(cat[0].name);
  };

  const handleChangeMaterials = (e) => {
    const { value } = e.target;

    if (materialList.filter((x) => x.id === value.at(-1)).length === 0) {
      const newMat = materials.filter((x) => x.id === value.at(-1))[0];
      const itemMaterial = {
        id: newMat.id,
        name: newMat.name,
      };
      setMaterialList([...materialList, itemMaterial]);
      handlerSetMaterialsProducts(itemMaterial);
    } else {
      setMaterialList(materialList.filter((x) => x.id !== value.at(-1)));
      handlerRemoveMaterialsProducts(value.at(-1));
    }
  };

  const resetInputs = () => {
    handlerSetProduct({
      id: '', name: '', category: '', categoryName: '', materials: [], photo: '', price: 0,
    });
    setmodalItem(false);
    setimgCompressed(null);
    setimgCompressedblob(null);
    setNewImg(false);
    setmodalItem(false);
  };

  const updateItemOnStock = async (dataItem) => {
    const updateDataStock = await putRequest('product-stock', dataItem, 'product-stock', false);
    if (updateDataStock.status === 200) {
      handlerUpdateStockProduct(dataItem);
      handlerUpdateProducts(dataItem);
    }
  };

  const handleApiResponse = (response, successMessage, errorMessage) => {
    if (response.status === 200) {
      setmsmAlert(successMessage);
      setalertSuccess(true);
    } else {
      setmsmAlert(errorMessage);
      setalertError(true);
    }
  };
  
  // #region Image Methods
  const handlerItem = async (dataItem, method) => {
    if (method === 'POST') {
      const createData = await postRequest(apiUrl, dataItem, storedData, false);
      handleApiResponse(createData, 'El Producto se ha creado exitosamente.', 'Error en el registro, por favor intente de nuevo.');

      handlerInsertProducts(dataItem);
    }

    if (method === 'PUT') {
      const updateData = await putRequest(apiUrl, dataItem, storedData, false);
      handleApiResponse(updateData, 'El Producto se ha creado exitosamente.', 'Error en el registro, por favor intente de nuevo.');

      handlerUpdateProducts(dataItem);

      const catItem = category.find((x) => x.id === dataItem.category);
      const stockLenght = stock.filter((x) => x.id === dataItem.id);
      if (catItem.enableStock === true && stockLenght.length !== 0) {
        updateItemOnStock(dataItem);
      }
    }

    resetInputs();
  };

  const handlerProcess = async () => {
    const method = item.id === '' ? 'POST' : 'PUT';

    const requestData = {
      id: item.id === '' ? uuidv4() : item.id,
      name: item.name,
      category: item.category,
      categoryName: item.categoryName,
      materials: item.materials,
      price: item.price,
    };

    if (newImg === true) {
      const responsePhoto = await createPhoto('upload-image', imgCompressedblob);
      if (responsePhoto.status === 200) {
        requestData.photo = responsePhoto.data.imageUrl;
      }
    } else {
      requestData.photo = item.photo;
    }
  
    handlerItem(requestData, method);
  };

  const handlerOpenModalDelete = (id) => {
    setdeleteItem(id);
    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handleApiResponseDelete = (response, successMessage, errorMessage) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setmsmAlert(successMessage);
        setalertSuccess(true);
        handlerDeleteProducts(deleteItem);
      } else {
        setmsmAlert(errorMessage);
        setalertError(true);
      }
    } else {
      setmsmAlert(errorMessage);
      setalertError(true);
    }
  };

  const resetDeleteRequest = () => {
    setdeleteItem('');
    setmodalDelete(false);
  };

  const handlerDelete = async () => {
    if (stock.filter((x) => x.id === deleteItem).length === 0) {
      const deleteData = await deleteRequest(storedData, { id: deleteItem }, storedData, false);
      handleApiResponseDelete(deleteData, 'Se ha eliminado con exito.', 'Error en el registro, por favor intente de nuevo.');
      resetDeleteRequest();
    } else {
      setmodalDelete(false);
      setmsmAlert('Para eliminar este producto, primero elimine este mismo producto en inventario.');
      setalertError(true);
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const compressImage = (event) => {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 700,
      useWebWorker: true,
    };

    imageCompression(imageFile, options)
      .then((compressedFile) => {
        const urlImg = URL.createObjectURL(compressedFile);
        setimgCompressed(urlImg);
        setimgCompressedblob(compressedFile);
        setNewImg(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // #endregion

  const imgElement = imgCompressed ? (
    <img
      width="288"
      height="216"
      className="m-auto img-product"
      src={imgCompressed}
      alt={item.name}
    />
  ) : (
    <Skeleton
      variant="rect"
      width={288}
      height={216}
    />
  );

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      {data.length !== 0 ? (
        <MaterialTable
          title={(
            <h3>
              Productos
              (
              {
                data.length + 1
              }
              )
            </h3>
          )}
          data={data}
          columns={[
            {
              title: 'Nombre',
              field: 'name',
              width: '30%',
            },
            {
              title: 'Categoría',
              field: 'categoryName',
              width: '20%',
            },
            {
              title: 'Materiales',
              field: 'materials',
              width: '35%',
              render: (dataRow) => (dataRow.materials.map((mat) => `${mat.name}`).join(', ')),
            },
            {
              title: 'Precio',
              field: 'price',
              width: '10%',
              render: (dataRow) => (numberToMoney(dataRow.price)),
            },
          ]}
          actions={
            [
              {
                icon: 'edit',
                tooltip: 'Actualizar',
                onClick: (event, rowData) => handlerOpenModalItem(rowData.id),
                disabled: submenu.update !== true,
              },
              {
                icon: 'delete',
                tooltip: 'Eliminar',
                onClick: (event, rowData) => handlerOpenModalDelete(rowData.id),
                disabled: submenu.delete !== true,
              },
              {
                icon: 'add',
                tooltip: 'Crear',
                isFreeAction: true,
                onClick: () => handlerOpenModalItem(''),
                disabled: submenu.create !== true,
              },
            ]
          }
          options={{
            paging: data.length > 100,
            pageSize: 100,
            pageSizeOptions: [100, 150, 200, 300],
            maxColumnSort: 'all_columns',
          }}
          localization={{
            toolbar: {
              searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
              searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
            },
            header: {
              actions: labelLang(getLangUser(), 'table', 'actions'),
            },
            pagination: {
              labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
              labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
              labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
              firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
              firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
              previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
              previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
              nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
              nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
              lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
              lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
            },
          }}
        />
      ) : (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <h3 className="mb-4">Producto</h3>
          <Loading />
        </div>
      )}

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalItem(); } }}
      >
        <DialogTitle>
          {item.id ? (
            <span>Editar</span>
          ) : (
            <span>Crear</span>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-12 d-flex flex-column align-items-center">
                  {item.photo === undefined || item.photo === '' ? (
                    <>
                      {imgElement}
                    </>
                  ) : (
                  <img
                      width="288"
                      height="216"
                      className="m-auto img-product"
                      src={imgCompressed || item.photo}
                      alt={item.name}
                    />
                  )}
                  <input
                    id="icon-button-file-create"
                    accept="image/*"
                    className="d-none"
                    type="file"
                    onChange={(e) => compressImage(e)}
                  />
                  <label htmlFor="icon-button-file-create" className="mt-2">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
                <div className="w-100">
                  <ul className="text-muted">
                    <li>
                      <small>
                        La imagen no debe pesar mas de 1MB
                      </small>
                    </li>
                    <li>
                      <small>
                        Se requiere imagen para registrar el producto en stock
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-12 mb-2">
                  <TextField
                    variant="standard"
                    error={item.name === ''}
                    label="Nombre *"
                    className="w-100"
                    name="name"
                    value={item.name}
                    onChange={handleChangeName}
                  />
                </div>
                <div className="col-12 mb-2">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="label-select-category">Categoría</InputLabel>
                    <Select
                      id="select-category"
                      labelId="select-category"
                      name="category"
                      value={item.category}
                      onChange={handleChangeCategory}
                    >
                      {
                        category.map((cat) => (
                          <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 mb-2">
                  <TextField
                    variant="standard"
                    error={item.price === ''}
                    label="Precio *"
                    className="w-100"
                    name="price"
                    value={item.price}
                    onChange={handleChangePrice}
                  />
                </div>
                <div className="col-12 mb-3">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="demo-mutiple-checkbox-label">Materiales</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={materialList}
                      onChange={handleChangeMaterials}
                      input={<Input />}
                      renderValue={(selected) => selected.map((mat) => (
                        <Chip
                          key={mat.id}
                          label={mat.name}
                          sx={{
                            margin: 2,
                          }}
                        />
                      ))}
                      MenuProps={MenuProps}
                    >
                      {materials.map((mat) => (
                        <MenuItem key={mat.id} value={mat.id}>
                          <Checkbox
                            checked={materialList.filter((x) => x.id === mat.id).length > 0}
                          />
                          <ListItemText primary={mat.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalItem}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerProcess}
                disabled={
                  item.name === '' || item.category === '' || item.price === '' || item.materials.length === 0
                }
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar el Producto?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar> 
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Product;

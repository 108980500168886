import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@material-ui/lab/Skeleton';

import Loading from '../Loading/Loading';

import { useSelector, useDispatch } from 'react-redux';
import { setEmployees } from '../reducers/employees';
import { setProducts } from '../reducers/products';
import { setCategory } from '../reducers/category';
import {
  setStock, insertProductStock, updateAmountProduct, resetStock,
  deleteProductStock,
} from '../reducers/stock';

import {
  getRequest, postRequest, putRequest, deleteRequest, 
  getSubMenu, createPhoto, getLangUser, numberToMoney,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Stock() {
  const submenu = getSubMenu('settings', 'stock');

  const apiUrl = 'stock';
  const storedData = 'stock';
  const [selectCategory, setSelectCategory] = useState('');
  const [productsFilter, setproductsFilter] = useState([]);

  const [modalItem, setmodalItem] = useState(false);
  const [modalItemEdit, setmodalItemEdit] = useState(false);
  const [item, setItem] = useState({
    id: '', name: '', category: '', categoryName: '', price: 0, amountStock: 0, amountSell: 0, amountRemaining: 0, photo: '', active: true,
  });

  const [imgCompressedblob, setimgCompressedblob] = useState();
  const [newImg, setNewImg] = useState(false);

  const [itemUpdate, setItemUpdate] = useState({
    id: '',
    name: '',
    addAmount: 0,
    dateUpdate: '',
    employee: '',
    employeeName: '',
  });

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const dispatch = useDispatch();

  // #region Stock
  const stock = useSelector((state) => state.stock.value);

  const handlerSetStock = (infoStock) => {
    dispatch(setStock(infoStock));
  };

  const handlerInsertStock = (infoStock) => {
    dispatch(insertProductStock(infoStock));
  };

  const handlerUpdateAmountProduct = (infoStock) => {
    dispatch(updateAmountProduct(infoStock));
  };

  const habdlerDeleteProductStock = (infoStock) => {
    dispatch(deleteProductStock(infoStock));
  }

  const handlerResetStock = () => {
    dispatch(resetStock());
  };

  const getStock = async () => {
    const requestStock = await getRequest(apiUrl, {}, storedData, false);
    handlerSetStock(requestStock.data.dataResponse);
  };

  useEffect(() => {
    handlerResetStock();
    getStock();
  }, []);
  // #endregion

  // #region Employees
  const employees = useSelector((state) => state.employees.value);

  const handlerSetEmployees = (infoEmployees) => {
    dispatch(setEmployees(infoEmployees));
  };

  const getEmployees = async () => {
    const requestEmployee = await getRequest('employees', {}, 'employees', false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };

  useEffect(() => {
    if (employees.length === 0) {
      getEmployees();
    }
  }, []);
  // #endregion

  // #region PRODUCTS
  const products = useSelector((state) => state.products.value);

  const handlerSetProducts = (infoProducts) => {
    dispatch(setProducts(infoProducts));
  };

  const getProduct = async () => {
    const requestProduct = await getRequest('product', {}, 'product', false);
    handlerSetProducts(requestProduct.data.dataResponse);
  };

  useEffect(() => {
    if (products.length === 0) {
      getProduct();
    }
  }, []);
  // #endregion

  // #region CATEGORY
  const categories = useSelector((state) => state.category.value);

  const handlerSetCategory = (infoCategory) => {
    dispatch(setCategory(infoCategory));
  };

  const getCategory = async () => {
    const requestCategory = await getRequest('category', {}, 'category', false);
    handlerSetCategory(requestCategory.data.dataResponse);
  };

  useEffect(() => {
    if (categories.length === 0) {
      getCategory();
    }
  }, []);
  // #endregion

  const handlerOpenModalItem = (id) => {
    if (id !== '') {
      const obj = stock.filter((x) => x.id === id);
      setItem((prevState) => ({
        ...prevState,
        ['id']: obj[0].id,
        ['name']: obj[0].name,
        ['category']: obj[0].category,
        ['categoryName']: obj[0].categoryName,
        ['price']: obj[0].price,
        ['amountStock']: obj[0].amountStock,
        ['amountSell']: obj[0].amountSell,
        ['amountRemaining']: obj[0].amountRemaining,
        ['photo']: obj[0].photo,
        ['active']: obj[0].active,
      }));

      setItemUpdate((prevState) => ({ ...prevState, ['id']: obj[0].id }));
      setItemUpdate((prevState) => ({ ...prevState, ['name']: obj[0].name }));

      setmodalItemEdit(true);
    } else {
      setmodalItem(true);
    }
  };

  const handlerFilterProduct = (e) => {
    setSelectCategory(e.target.value);
    setproductsFilter(products.filter((x) => x.category === e.target.value));

    setItem({
      id: '', name: '', category: '', price: 0, amountStock: '0', amountSell: 0, amountRemaining: 0, isEdit: false, comments: [], photo: '',
    });
  };

  const handlerCreateProduct = (e) => {
    const obj = products.filter((x) => x.id === e.target.value);
    setItem((prevState) => ({
      ...prevState,
      ['id']: obj[0].id,
      ['name']: obj[0].name,
      ['category']: obj[0].category,
      ['categoryName']: obj[0].categoryName,
      ['price']: obj[0].price,
      ['photo']: obj[0].photo,
      ['active']: true,
    }));
  };

  const handlerCloseEdit = () => {
    setItem({
      id: '', name: '', category: '', price: 0, amountStock: '0', amountSell: 0, amountRemaining: 0, isEdit: false, comments: [], photo: '',
    });

    setItemUpdate((prevState) => ({ ...prevState, ['id']: '' }));
    setItemUpdate((prevState) => ({ ...prevState, ['name']: '' }));
    setItemUpdate((prevState) => ({ ...prevState, ['addAmount']: 0 }));
    setItemUpdate((prevState) => ({ ...prevState, ['dateUpdate']: '' }));
    setItemUpdate((prevState) => ({ ...prevState, ['employee']: '' }));
    setItemUpdate((prevState) => ({ ...prevState, ['employeeName']: '' }));

    setproductsFilter([]);
    setmodalItemEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: value, ['amountRemaining']: (parseInt(value, 10) - parseInt(item.amountSell, 10)) }));
  };

  const handlerAmountUpdate = (e) => {
    if (e.target.value === '') {
      setItemUpdate((prevState) => ({ ...prevState, ['addAmount']: 0 }));
    } else {
      setItemUpdate((prevState) => ({ ...prevState, ['addAmount']: parseInt(e.target.value, 10) }));
    }
  };

  const handlerSetEmployeeUpdate = (e) => {
    const employeeItem = employees.filter((x) => x.id === e.target.value);

    setItemUpdate((prevState) => ({ ...prevState, ['employee']: employeeItem[0].id }));
    setItemUpdate((prevState) => ({ ...prevState, ['employeeName']: `${employeeItem[0].name} ${employeeItem[0].lastName}` }));
    setItemUpdate((prevState) => ({ ...prevState, ['dateUpdate']: moment().format('YYYY-MM-DDTHH:mm:ss.SSS') }));
  };

  const handleUpdate = async () => {
    const updateData = await putRequest(apiUrl, itemUpdate, storedData, false);

    if (updateData.status === 200) {
      handlerUpdateAmountProduct(itemUpdate);
      handlerCloseEdit();
      setmsmAlert('El Inventario se ha actualizado exitosamente.');
      setalertSuccess(true);
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const addNewProduct = async () => {
    const responseUpdate = await postRequest('stock', item, 'stock', false);
    if (responseUpdate.status === 200) {
      handlerInsertStock(item);
      setmsmAlert('Registro exitoso.');
      setalertSuccess(true);
      setItem({
        id: '', name: '', category: '', categoryName: '', price: 0, amountStock: 0, amountSell: 0, amountRemaining: 0, photo: '', active: true,
      });
      setproductsFilter([]);
      setSelectCategory('');
      setmodalItem(false);
      setimgCompressedblob(null);
      setNewImg(false);
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handlerAdd = async () => {
    if (newImg === true) {
      const responsePhoto = await createPhoto('upload-image', imgCompressedblob);
      if (responsePhoto.status === 200) {
        const itemData = item;
        itemData.photo = responsePhoto.data.imageUrl;
        setItem(itemData);
      }
    }

    addNewProduct();
  };

  const resetForm = () => {
    setItem({
      id: '',
      name: '',
      category: '',
      categoryName: '',
      price: 0,
      amountStock: 0,
      amountSell: 0,
      amountRemaining: 0,
      photo: '',
      active: true
    });
    setimgCompressedblob(null);
    setNewImg(false);
  };
  
  const resetFilters = () => {
    setproductsFilter([]);
  };
  
  const handlerClose = () => {
    resetForm();
    resetFilters();
    setmodalItem(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const [modalDelete, setModalDelete] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const handlerDeleteItem = (id) => {
    setIdDelete(id);
    setModalDelete(true);
  };

  const handlerCloseDeleteItem = () => {
    setIdDelete('');
    setModalDelete(false);
  };

  const handlerDelete = async () => {
    const responseUpdate = await deleteRequest(apiUrl, { id: idDelete }, storedData, false);
    if (responseUpdate.status === 200) {
      habdlerDeleteProductStock(idDelete);
      setmsmAlert('El Producto se ha eliminado exitosamente del inventario.');
      setalertSuccess(true);
      handlerCloseDeleteItem(false);
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      {stock.length !== 0 ? (
        <MaterialTable
          title={<h3>Stock</h3>}
          columns={[
            {
              title: 'Nombre',
              field: 'name',
            },
            {
              title: 'Categoría',
              field: 'categoryName',
            },
            {
              title: 'Cant. restante',
              field: 'amountRemaining',
            },
            {
              title: 'Precio',
              field: 'price',
              render: (dataRow) => (numberToMoney(dataRow.price)),
            },
          ]}
          data={stock}
          actions={
            [
              {
                icon: 'edit',
                tooltip: 'Actualizar',
                onClick: (event, rowData) => handlerOpenModalItem(rowData.id),
                disabled: submenu.update !== true,
              },
              {
                icon: 'add',
                tooltip: 'Crear',
                isFreeAction: true,
                onClick: () => handlerOpenModalItem(''),
                disabled: submenu.create !== true,
              },
              {
                icon: 'delete',
                tooltip: 'Eliminar',
                onClick: (event, rowData) => handlerDeleteItem(rowData.id),
                disabled: submenu.delete !== true,
                hidden: false,
              },
            ]
          }
          options={{
            maxColumnSort: 'all_columns',
            paging: stock.length > 100,
            pageSize: 100,
            pageSizeOptions: [100, 150, 200, 300],
          }}
          localization={{
            toolbar: {
              searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
              searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
            },
            header: {
              actions: labelLang(getLangUser(), 'table', 'actions'),
            },
            pagination: {
              labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
              labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
              labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
              firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
              firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
              previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
              previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
              nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
              nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
              lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
              lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
            },
          }}
        />
      ) : (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <h3 className="mb-4">Stock</h3>
          <Loading />
        </div>
      )}

      <Dialog
        open={modalItemEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseEdit(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                Editar
              </h4>
            </div>
            <div className="col-6 mb-2">
              <TextField
                variant="standard"
                label="Nombre"
                className="w-100"
                name="amountStock"
                value={item.name}
                disabled
              />
            </div>
            <div className="col-6 mb-2">
              <TextField
                variant="standard"
                label="Cantidad de Stock *"
                className="w-100"
                name="amountStock"
                value={item.amountStock}
                disabled
              />
            </div>
            <div className="col-12 mb-2">
              <TextField
                variant="standard"
                error={
                  parseInt(itemUpdate.addAmount, 10) === 0
                  && itemUpdate.addAmount === ''
                  && Number.isNaN(itemUpdate.addAmount)
                  && parseInt(itemUpdate.addAmount, 10) < 0
                }
                helperText={
                  itemUpdate.addAmount === '' ? (
                    'Ingrese un valor Valido'
                  )
                    : parseInt(itemUpdate.addAmount, 10) === 0 ? (
                      'El valor no puede ser igual a 0'
                    ) : parseInt(itemUpdate.addAmount, 10) < 0 ? (
                      'El valor no puede ser negativo'
                    ) : ''
                }
                label="Cantidad para agregar *"
                className="w-100"
                name="amountStock"
                defaultValue={itemUpdate.addAmount}
                onChange={handlerAmountUpdate}
              />
            </div>
            <div className="col-12 mb-3 text-center">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-employee">Empleado quien registra</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  defaultValue=""
                  name="employee"
                  error={itemUpdate.employee === '' || itemUpdate.employeeName === ''}
                  onChange={handlerSetEmployeeUpdate}
                  MenuProps={MenuProps}
                >
                  {
                    employees.map((employeeItem) => (
                      <MenuItem key={employeeItem.id} value={employeeItem.id}>
                        {`${employeeItem.name} ${employeeItem.lastName}`}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseEdit}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handleUpdate}
                disabled={
                  itemUpdate.addAmount !== ''
                  && !Number.isNaN(itemUpdate.addAmount)
                  && parseInt(itemUpdate.addAmount, 10) === 0
                  && parseInt(itemUpdate.addAmount, 10) <= 0
                  && itemUpdate.employee === ''
                  && itemUpdate.employeeName === ''
                }
              >
                Agregar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerClose(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                Agregar a Inventario
              </h4>
            </div>
            <div className="col-6 mb-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-category">Categoría</InputLabel>
                <Select
                  id="select-category"
                  labelId="select-category"
                  name="category"
                  value={selectCategory !== '' ? selectCategory : '0'}
                  onChange={handlerFilterProduct}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="0">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    categories.filter((x) => x.enableStock === true).map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mb-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-product">Productos</InputLabel>
                <Select
                  id="select-product"
                  labelId="select-product"
                  name="product"
                  value={item.id !== '' ? item.id : '0'}
                  onChange={handlerCreateProduct}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="0">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    productsFilter.filter((p) => !stock.find((pStock) => p.id === pStock.id))
                      .map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mb-1">
              <TextField
                variant="standard"
                error={parseInt(item.amountStock, 10) === 0 || item.amountStock === '' || Number.isNaN(item.amountStock)}
                label="Cantidad de Stock *"
                className="w-100"
                name="amountStock"
                value={item.amountStock}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-1 mb-1">
              <InputLabel>
                <small>
                  Imagen
                </small>
              </InputLabel>
            </div>
            <div className="col-12 mt-1 mb-3 text-center d-flex flex-column align-items-center">
              {item.photo !== '' && (
                <img
                  width="288"
                  height="216"
                  className="m-auto img-product"
                  src={item.photo}
                  alt={item.name}
                />
                )}
                {item.photo === '' && (
                  <Skeleton
                    variant="rect"
                    width={288}
                    height={216}
                  />
                )}
            </div>

            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerClose}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerAdd}
                disabled={
                  item.id === ''
                  || item.name === ''
                  || item.category === ''
                  || parseInt(item.amountStock, 10) === 0
                  || item.amountStock === ''
                  || Number.isNaN(item.amountStock)
                }
              >
                Agregar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseDeleteItem(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar el producto?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseDeleteItem}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Stock;

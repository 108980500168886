/* eslint-disable */
import React from 'react';
import {
  Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller
} from 'react-scroll';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { urlImages } from '../service';

function tutorialTable() {
  return (
    <div className="row mt-2">
      <h3>
        Mesas
        <h5
          className="float-end"
          data-bs-toggle="collapse"
          href="#collapseIndice"
          role="button"
          aria-expanded="false"
          aria-controls="collapseTransactions"
        >
          Indice&nbsp;
          <ArrowDropDownIcon />
        </h5>
      </h3>
      <div className="collapse px-5" id="collapseIndice">
        <div className="container card card-body">
          <ul className="nav navbar-nav">
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-one"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Crear una Mesa?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-two"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Editar una Mesa?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-three"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo Eliminar una Mesa?
              </Link>
            </li>
            <li style={{cursor: 'pointer'}}>
              <Link
                activeClass="active"
                to="element-four"
                spy={true}
                smooth={true}
                duration={250}
                containerId="containerElement"
                data-bs-toggle="collapse"
                href="#collapseIndice"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTransactions"
              >
                ¿Cómo verificar el estado de mis Mesas?
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Element className="element overflow-scroll" id="containerElement">
        <Element name="element-one">
          <h5 className="mt-1">
            ¿Cómo Crear una Mesa?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a&nbsp;
              ‘Mesas’
              &nbsp;en el menú superior.
            </li>
            <img
              src={urlImages+'tutorial-table-01.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 01"
            />
            <li>
              En la parte izquierda de esta pantalla, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Nueva Mesa
              </button>
              &nbsp;y allí se abrirá un Popup de Creación de la nueva mesa.
            </li>
            <img
              src={urlImages+'tutorial-table-02.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 02"
            />
            <li>
              A continuación le asignaremos un&nbsp;
              <mark>
                <i>
                  Nombre a la Mesa
                </i>
              </mark>
              &nbsp;y luego hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el Botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-table-03.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 03"
            />
            <li>
              Allí nos aparecerá una Alerta de&nbsp;
              <mark>
                <i>
                  Las Mesas se ha registrado exitosamente
                </i>
              </mark>
              &nbsp;y luego podremos visualizar la nueva mesa en parte derecha de la visual.
            </li>
            <img
              src={urlImages+'tutorial-table-04.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 04"
            />
          </ol>
        </Element>
        <Element name="element-two">
          <h5 className="mt-4">
            ¿Cómo Editar una Mesa?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Mesas’
              en el menú superior.
            </li>
            <img
              src={urlImages+'tutorial-table-05.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 05"
            />
            <li>
              En la parte izquierda de esta pantalla, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Editar Mesas
              </button>
              &nbsp;y allí nuestras mesas en la parte derecha de la pantalla muestran&nbsp;
              <mark>
                <i>
                  campos de texto
                </i>
              </mark>
              &nbsp;en los cuales podremos cambiar el nombre
              de cualquiera de las mesas que tenemos registradas.
            </li>
            <img
              src={urlImages+'tutorial-table-06.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 06"
            />
            <li>
              Asignamos un nuevo nombre a nuestra mesa a&nbsp;
              <mark>
                Editar
              </mark>
              &nbsp;y hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en el Botón&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-table-07.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 07"
            />
            <li>
              Allí nos aparecerá una Alerta de&nbsp;
              <mark>
                <i>
                  Las Mesas se ha actualizado exitosamente
                </i>
              </mark>
              &nbsp;y podremos ver que los&nbsp;
              <mark>
                <i>
                  campos de texto
                </i>
              </mark>
              &nbsp;se ocultarán y se harán visible nuevamente los nombres
              de las Mesas actualizados.
            </li>
            <img
              src={urlImages+'tutorial-table-08.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 08"
            />
          </ol>
        </Element>
        <Element name="element-three">
          <h5 className="mt-4">
            ¿Cómo Eliminar una Mesa?
          </h5>
          <div className="alert alert-info mx-5 py-1 text-center">
            <small>
              <b>
                ¡Importante!
              </b>
              &nbsp;Las Mesas para poderse&nbsp;
              <b>
                Eliminar
              </b>
              &nbsp;no deben estar con&nbsp;
              <b>
                Ningún Pedido Asociado
              </b>
              .
            </small>
          </div>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Mesas’
               en el menú superior.
            </li>
            <img
              src={urlImages+'tutorial-table-13.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 13"
            />
            <li>
              En la parte izquierda de esta pantalla, hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Editar Mesas
              </button>
              &nbsp;y allí nos mostrarán los&nbsp;
              <mark>
                <i>
                  campos de texto
                </i>
              </mark>
              &nbsp;y un Icono de Eliminar&nbsp;
              <span className="material-icons text-danger ms-4">delete</span>
            </li>
            <img
              src={urlImages+'tutorial-table-07.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 07"
            />
            <li>
              Allí hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;al Icono de Eliminar, nos mostrará un Popup
              donde nos preguntará que si estamos seguros de eliminar la Mesa.
            </li>
            <img
              src={urlImages+'tutorial-table-14.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 14"
            />
            <li>
              Hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Eliminar
              </button>
            </li>
            <img
              src={urlImages+'tutorial-table-09.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 09"
            />
            <li>
              Y por último hacemos&nbsp;
              <mark>
                Clic
              </mark>
              &nbsp;en&nbsp;
              <button className="btn btn-sm btn-primary" type="button">
                Guardar
              </button>
              &nbsp;para guardar los cambios.
            </li>
            <img
              src={urlImages+'tutorial-table-10.PNG'}
              className="my-2 w-75 btn-rounded"
              alt="pos system - tutorial table 10"
            />
          </ol>
        </Element>
        <Element name="element-four">
          <h5 className="mt-4">
            ¿Cómo verificar el estado de mis Mesas?
          </h5>
          <ol>
            <li>
              Nos dirigiremos a 
              ‘Mesas’
               en el menú superior.
            </li>
            <li>
              En la pantalla en la parte superior derecha veremos los siguientes indicadores
              donde nos describen brevemente lo que significan los colores que
              tienen nuestras Mesas.
            </li>
          </ol>
          <div className="row mt-2">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h3 className="w-100">
                <small>
                  <h6>
                    <span className="bg-mesa-pay-order me-2">&nbsp;</span>
                    Disponible: Significa que nuestra mesa no tiene ningún pedido
                    por lo cual, podremos usar esta mesa.
                  </h6>
                </small>
                <small>
                  <h6>
                    <span className="bg-mesa-taking-order me-2">&nbsp;</span>
                    No disponible (Mesa con pedido no pago):
                    Significa que nuestra mesa se encuentra&nbsp;
                    <mark>
                      Ocupada
                    </mark>
                    &nbsp;y con una orden sin pagar.
                  </h6>
                </small>
                <small>
                  <h6>
                    <span className="bg-mesa-preparing-order me-2">&nbsp;</span>
                    No disponible (Mesa con pedido Pago):
                    Significa que nuestra mesa se encuentra&nbsp;
                    <mark>
                      Ocupada
                    </mark>
                    , pero la orden se encuentra paga.
                  </h6>
                </small>
              </h3>
            </div>
          </div>
          <img
            src={urlImages+'tutorial-table-12.PNG'}
            className="my-2 w-75 btn-rounded"
            alt="pos system - tutorial table 12"
          />
        </Element>
      </Element>
    </div>
  );
}

export default tutorialTable;

import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector, useDispatch } from 'react-redux';
import {
  setProduct, insertProduct, updateProduct, deleteProduct,
} from '../reducers/specialProducts';
import {
  getRequest, postRequest, putRequest, deleteRequest,
  createPhoto, getLangUser,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SpecialProducts() {
  const apiUrl = 'special-products';
  const storedData = 'special-products';

  const [item, setItem] = useState({
    id: '',
    name: '',
    photo: '',
    price: '',
  });
  const [modalItem, setmodalItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const [imgCompressed, setimgCompressed] = useState();
  const [imgCompressedblob, setimgCompressedblob] = useState();
  const [newImg, setNewImg] = useState(false);
  const [imgLoad, setImgLoad] = useState(false);

  const data = useSelector((state) => state.specialProducts.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  /* A function that is used to set the rol. */
  const handlerSetProduct = (infoProduct) => {
    dispatch(setProduct(infoProduct));
  };

  const handlerInsertProduct = (infoProduct) => {
    dispatch(insertProduct(infoProduct));
  };

  const handlerUpdateProduct = (infoProduct) => {
    dispatch(updateProduct(infoProduct));
  };

  const handlerDeleteProduct = (infoProduct) => {
    dispatch(deleteProduct(infoProduct));
  };

  const getSpecialProducts = async () => {
    const requestProduct = await getRequest(apiUrl, {}, storedData, false);
    handlerSetProduct(requestProduct.data.dataResponse);
  };

  useEffect(() => {
    if (data.length === 0) {
      getSpecialProducts();
    }
  }, []);

  const handlerClose = () => {
    setItem({
      id: '',
      name: '',
      photo: '',
      price: '',
    });
    setimgCompressed(null);
    setimgCompressedblob(null);
    setNewImg(false);
    setImgLoad(false);
    setmodalItem(false);
  };

  const handleChange = (event) => {
    setItem({
      ...item,
      [event.target.name]: event.target.value,
    });
  };

  const resetRequest = () => {
    setItem({
      id: '',
      name: '',
      photo: '',
      price: '',
    });
  };

  const handleApiResponse = (response, successMessage, errorMessage) => {
    if (response.status === 200) {
      setmsmAlert(successMessage);
      setalertSuccess(true);
    } else {
      setmsmAlert(errorMessage);
      setalertError(true);
    }
  };

  const handlerCreateProduct = async () => {
    const product = item;

    if (product.id === '') {
      product.id = uuidv4();

      const responseCreateProduct = await postRequest(apiUrl, product, storedData, false);
      if (responseCreateProduct.status === 200) {
        handlerInsertProduct(product);
        resetRequest();
      }
      handleApiResponse(responseCreateProduct, 'Producto especial creado correctamente.', 'Error en la creación.');
    }

    if (product.id !== '') {
      const responsePutProduct = await putRequest(apiUrl, product, storedData, false);
      if (responsePutProduct.status === 200) {
        handlerUpdateProduct(product);
        resetRequest();
      }
      handleApiResponse(responsePutProduct, 'Actualizado.', 'Error en la actualización.');
    }

    setmodalItem(false);
  };

  const handlerCreate = async () => {
    if (newImg === true) {
      const responsePhoto = await createPhoto('upload-image', imgCompressedblob);
      if (responsePhoto.status === 200) {
        const itemData = item;
        itemData.photo = responsePhoto.data.imageUrl;
        setItem(itemData);

        handlerCreateProduct();
      }
    } else {
      handlerCreateProduct();
    }
  };

  const handlerViewProduct = (id) => {
    if (id !== '') {
      const productItem = data.filter((x) => x.id === id);
      setItem(productItem[0]);
    }

    setmodalItem(true);
  };

  const compressImage = (event) => {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1080,
      useWebWorker: true,
    };

    imageCompression(imageFile, options)
      .then((compressedFile) => {
        const urlImg = URL.createObjectURL(compressedFile);
        setimgCompressed(urlImg);
        setimgCompressedblob(compressedFile);
        setNewImg(true);
        setImgLoad(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlerOpenModalDelete = (id) => {
    setdeleteItem(id);
    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handlerDelete = async () => {
    const deleteData = await deleteRequest(apiUrl, { createdate: deleteItem }, storedData, false);
    if (deleteData.status === 200) {
      if (deleteData.data.status === false) {
        setdeleteItem('');
        handlerCloseModalDelete();
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }

      if (deleteData.data.status === true) {
        handlerDeleteProduct(deleteItem);
        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
        setdeleteItem('');
        setmodalDelete(false);
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const imgComponent = imgCompressed ? (
    <img
      width="288"
      height="216"
      className="m-auto img-product"
      src={imgCompressed}
      alt={item.name}
    />
  ) : (
    <Skeleton
      variant="rect"
      width={288}
      height={216}
    />
  );

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <MaterialTable
        title={<h3>Productos especiales</h3>}
        columns={[
          {
            title: 'Nombre',
            field: 'name',
          },
          {
            title: 'Precio',
            field: 'price',
          },
        ]}
        data={data}
        actions={
          [
            {
              icon: 'edit',
              tooltip: 'Actualizar',
              onClick: (event, rowData) => handlerViewProduct(rowData.id),
            },
            {
              icon: 'delete',
              tooltip: 'Eliminar',
              onClick: (event, rowData) => handlerOpenModalDelete(rowData.createdate),
            },
            {
              icon: 'add',
              tooltip: 'Crear',
              isFreeAction: true,
              onClick: () => handlerViewProduct(''),
            },
          ]
        }
        options={{
          paging: data.length > 100,
          pageSize: 100,
          pageSizeOptions: [100, 150, 200, 300],
          maxColumnSort: 'all_columns',
        }}
        localization={{
          toolbar: {
            searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
            searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
          },
          header: {
            actions: labelLang(getLangUser(), 'table', 'actions'),
          },
          pagination: {
            labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
            labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
            labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
            firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
            firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
            previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
            previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
            nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
            nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
            lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
            lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
          },
        }}
      />

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerClose(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                {item.id === '' ? 'Crear producto' : 'Editar producto'}
              </h4>
            </div>
            <div className="col-12 mt-1 mb-1">
              <small>
                <InputLabel>
                  Imagen
                </InputLabel>
              </small>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12 mt-1 mb-3 text-center d-flex flex-column align-items-center">
                  {item.photo === undefined || item.photo === '' ? (
                    <>
                      {imgComponent}
                    </>
                  ) : (
                    <img
                      width="288"
                      height="216"
                      className="m-auto img-product"
                      src={imgCompressed || item.photo}
                      alt={item.photo}
                    />
                  )}
                  <input
                    id="icon-button-file-create"
                    accept="image/*"
                    className="d-none"
                    type="file"
                    onChange={(e) => compressImage(e)}
                  />
                  <label htmlFor="icon-button-file-create" className="mt-2">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
                <div className="w-100">
                  <ul className="text-muted">
                    <li>
                      <small>
                        La imagen no debe pesar mas de 1MB
                      </small>
                    </li>
                    <li>
                      <small>
                        Se requiere imagen para registrar el producto en stock
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12 mb-2">
                  <TextField
                variant="standard"
                    error={item.name === ''}
                    label="Nombre *"
                    className="w-100"
                    name="name"
                    value={item.name === '' ? '' : item.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="col-12 mb-2">
                  <TextField
                variant="standard"
                    error={item.price === ''}
                    label="Precio *"
                    className="w-100"
                    name="price"
                    value={item.price === '' ? '' : item.price}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerClose}>Cancelar</button>
            </div>
            {item.id !== '' ? (
              <div className="col-6 mb-2">
                <button
                  className="btn btn-primary w-100"
                  type="button"
                  onClick={handlerCreate}
                  disabled={item.id === '' || item.name === '' || item.photo === '' || item.price === '' || item.price === 0}
                >
                  Actualizar
                </button>
              </div>
            ) : (
              <div className="col-6 mb-2">
                <button
                  className="btn btn-primary w-100"
                  type="button"
                  onClick={handlerCreate}
                  disabled={item.name === '' || imgLoad === false || item.price === '' || item.price === 0}
                >
                  Crear
                </button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar el producto especial?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SpecialProducts;

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Pool from './userPool';

function ExpiredSession() {
  const history = useHistory();

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      sessionStorage.clear();
      user.signOut();
      history.push('/');
      window.location.reload();
    }
  };

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <div className="h-100 w-100 bg-white btn-rounded detail-content px-3 py-3 d-flex justify-content-center align-items-center">
        <div className="w-75 pb-5">
          <h2 className="mb-4">
            Sesión Expirada
          </h2>
          <p>
            Recuerde que por seguridad, la sesión expirará en
            cuanto se presenten lo siguientes casos:
          </p>
          <ul>
            <li>
              Cuando iniciemos sesión en un dispositivo
              diferente al que se inició la sesión anteriormente.
            </li>
            <li>
              Cuando se usemos un inicio de sesión del día anterior.
            </li>
          </ul>
          <h5 className="mt-5">
            ¿Y Ahora qué hago?
          </h5>
          <p>
            El paso a seguir es iniciar sesión nuevamente,
            para eso tienes que cerrar la sesión actual y volver a iniciar sesión.
          </p>
          <p>
            Con esto ya habríamos solventado el problema.
          </p>
          <p>
            Recuerde que es por su seguridad que la sesión expire en un tiempo determinado o
            si inicia sesión en otro dispositivo, por lo tanto, si no desea que su sesión expire,
            debe cerrarla manualmente y volver a iniciar sesión.
          </p>
          <br />
          <Link
            onClick={() => logout()}
            className="nav-log-out text-decoration-none rounded-right rounded-left"
            to="/"
            style={{ height: '41px' }}
          >
            Cerrar Sesión &nbsp;
            <ExitToAppIcon />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ExpiredSession;

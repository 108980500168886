/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Container, Draggable } from 'react-smooth-dnd';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Loading from '../Loading/Loading';

import { useSelector, useDispatch } from 'react-redux';
import {
  setCategory, insertCategory, updateCategory, deleteCategory,
} from '../reducers/category';

import {
  getRequest, postRequest, putRequest, deleteRequest, getSubMenu, getAdmWebSite, getLangUser,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Category() {
  // #region
  const submenu = getSubMenu('settings', 'employee');
  const admWebSite = getAdmWebSite();
  const apiUrl = 'category';
  const storedData = 'category';

  const [dataOrder, setdataOrder] = useState([]);
  const [item, setItem] = useState({
    id: '', name: '', order: 0, enableStock: false, admWebSite: false, typeListWebSite: 'card',
  });
  const [modalItem, setmodalItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [modalOrderPriority, setmodalOrderPriority] = useState(false);
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const data = useSelector((state) => state.category.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  /* A function that is used to set the rol. */
  const handlerSetCategory = (infoCategory) => {
    dispatch(setCategory(infoCategory));
  };

  const handlerInsertCategory = (infoCategory) => {
    dispatch(insertCategory(infoCategory));
  };

  const handlerUpdateCategory = (infoCategory) => {
    dispatch(updateCategory(infoCategory));
  };

  const handlerDeleteCategory = (infoCategory) => {
    dispatch(deleteCategory(infoCategory));
  };

  const getCategory = async () => {
    const requestCategory = await getRequest(apiUrl, {}, storedData, false);
    handlerSetCategory(requestCategory.data.dataResponse);
  };

  useEffect(() => {
    if (data.length === 0) {
      getCategory();
    }
  }, []);

  const handlerOpenModalItem = (id) => {
    if (id !== '') {
      const obj = data.filter((x) => x.id === id);
      setItem(obj[0]);
    }

    setmodalItem(true);
  };

  const handlerCloseModalItem = () => {
    setItem({
      id: '', name: '', order: 0, enableStock: false, admWebSite: false, typeListWebSite: 'card',
    });

    setmodalItem(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeStock = (e) => {
    const { name, checked } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handlerItem = async (dataItem, method) => {
    if (method === 'POST') {
      const createData = await postRequest(apiUrl, dataItem, storedData, false);

      if (createData.status === 200) {
        handlerInsertCategory(dataItem);
        setItem({
          id: '', name: '', order: 0, enableStock: false, admWebSite: false, typeListWebSite: 'card',
        });
        setmodalItem(false);
        setmsmAlert('La Categoría se ha creado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }

    if (method === 'PUT') {
      const updateData = await putRequest(apiUrl, dataItem, storedData, false);

      if (updateData.status === 200) {
        handlerUpdateCategory(dataItem);
        setItem({
          id: '', name: '', order: 0, enableStock: false, admWebSite: false, typeListWebSite: 'card',
        });
        setmodalItem(false);
        setmsmAlert('La Categoría se ha actualizado exitosamente');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }
  };

  const handlerProcess = () => {
    const dataItem = item;
    let method = '';
    if (dataItem.id === '') {
      method = 'POST';
      dataItem.id = uuidv4();
      dataItem.order = data.length + 1;
    } else {
      method = 'PUT';
    }

    handlerItem(dataItem, method);
  };

  const handlerOpenModalDelete = (id) => {
    setdeleteItem(id);
    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handlerDelete = async () => {
    const deleteData = await deleteRequest(apiUrl, { id: deleteItem }, storedData, false);
    if (deleteData.status === 200) {
      if (deleteData.data.status === false) {
        setdeleteItem('');
        handlerCloseModalDelete((modal) => !modal);
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }

      if (deleteData.data.status === true) {
        handlerDeleteCategory(deleteItem);
        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
        setdeleteItem('');
        const updateData = data.filter((x) => x.id !== deleteItem);
        const categoryOrder = updateData.sort((a, b) => a.order - b.order);
        for (let i = 0; i < categoryOrder.length; i += 1) {
          categoryOrder[i].order = (i + 1);
        }
        setdataOrder([...categoryOrder]);

        handlerCloseModalDelete((modal) => !modal);
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const onDrop = ({ removedIndex, addedIndex }) => {
    const newDataOrder = [...dataOrder];
    const removeItem = newDataOrder[removedIndex];
    newDataOrder.splice(removedIndex, 1);
    newDataOrder.splice(addedIndex, 0, removeItem);
    setdataOrder(newDataOrder);
  };

  const handlerUpdateOrder = async () => {
    try {
      const categoryOrdered = [];

      for (let i = 0; i < dataOrder.length; i += 1) {
        const element = dataOrder[i];
        const newElement = { ...element, order: i + 1 }; // create a new object with the modified order property
        categoryOrdered.push(newElement);
      }

      for (let index of categoryOrdered) {
        const requestUpdateOrder = await putRequest('categoryOrder', categoryOrdered[index], 'category', false);

        if (requestUpdateOrder.status !== 200) {
          setmsmAlert('Error, por favor intente de nuevo.');
          setalertError(true);
          break;
        }
      }

      getCategory();

      setmsmAlert('La Prioridad se ha actualizado exitosamente.');
      setalertSuccess(true);
      setmodalOrderPriority(false);
    } catch (error) {
      console.log(error);
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handlerOpenModalOrderPriority = () => {
    setdataOrder(data);

    setmodalOrderPriority(true);
  };

  const handlerCloseModalOrderPriority = () => {
    setmodalOrderPriority(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };
  // #endregion

  const handlerTypeList = (type) => {
    setItem((prevState) => ({ ...prevState, ['typeListWebSite']: type }));
  };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">

      {data.length !== 0 ? (
        <MaterialTable
          title={<h3>Categoría</h3>}
          columns={[
            {
              title: 'Nombre',
              field: 'name',
            },
            {
              title: 'Stock activo',
              field: 'enableStock',
              render: (dataRow) => (dataRow.enableStock === true ? 'Si' : 'No'),
            },
            {
              title: 'Prioridad',
              field: 'order',
            },
          ]}
          data={data}
          actions={
            [
              {
                icon: 'edit',
                tooltip: 'Actualizar',
                onClick: (event, rowData) => handlerOpenModalItem(rowData.id),
                disabled: submenu.update !== true,
              },
              {
                icon: 'delete',
                tooltip: 'Eliminar',
                onClick: (event, rowData) => handlerOpenModalDelete(rowData.id),
                disabled: submenu.delete !== true,
              },
              {
                icon: 'add',
                tooltip: 'Crear',
                isFreeAction: true,
                onClick: () => handlerOpenModalItem(''),
                disabled: submenu.create !== true,
              },
              {
                icon: 'list',
                tooltip: 'Prioridad',
                isFreeAction: true,
                onClick: () => handlerOpenModalOrderPriority(),
                disabled: submenu.update !== true,
              },
            ]
          }
          options={{
            maxColumnSort: 'all_columns',
            paging: data.length > 50,
            pageSize: 50,
            pageSizeOptions: [100, 150, 200, 300],
          }}
          localization={{
            toolbar: {
              searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
              searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
            },
            header: {
              actions: labelLang(getLangUser(), 'table', 'actions'),
            },
            pagination: {
              labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
              labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
              labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
              firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
              firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
              previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
              previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
              nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
              nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
              lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
              lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
            },
          }}
        />
      ) : (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <h3 className="mb-4">Categoría</h3>
          <Loading />
        </div>
      )}

      <Dialog
        open={modalOrderPriority}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalOrderPriority(); } }}
      >
        <DialogTitle>
          Prioridad
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2 modal-priority">
              <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                {dataOrder.map((p) => (
                  <Draggable key={p.id}>
                    <div className="drag-handle draggable-item">
                      {p.name}
                    </div>
                  </Draggable>
                ))}
              </Container>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalOrderPriority}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-primary w-100" type="button" onClick={handlerUpdateOrder}>Guardar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalItem(); } }}
      >
        <DialogTitle>
          {item.id ? (
            <span>Editar</span>
          ) : (
            <span>Crear</span>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-4">
              <TextField
                variant="standard"
                error={item.name === ''}
                label="Nombre *"
                className="w-100"
                name="name"
                value={item.name}
                onChange={handleChange}
              />
            </div>
            <div
              className={admWebSite.admWebSite === true ? 'col-6 mb-2' : 'col-12 mb-2'}
            >
              <InputLabel id="label-update-materials">Activar stock</InputLabel>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="enableStock"
                    checked={item.enableStock}
                    onChange={handleChangeStock}
                    disabled={item.admWebSite}
                  />
                )}
                label="Activar"
              />
            </div>
            {admWebSite.admWebSite === true && (
              <div className="col-6 mb-2">
                <InputLabel id="label-update-materials">Visualizar en la web</InputLabel>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="admWebSite"
                      checked={item.admWebSite}
                      onChange={handleChangeStock}
                      disabled={item.enableStock}
                    />
                  )}
                  label="Activar"
                />
              </div>
            )}
            {(item.admWebSite === true) && (
              <div className="col-12 mb-4">
                <FormControl variant="standard" className="w-100">
                  <InputLabel id="label-select-typelist">Tipo de lista</InputLabel>
                  <Select
                    labelId="select-update-typelist"
                    id="select-update-typelist"
                    value={item.typeListWebSite}
                    onChange={(e) => handlerTypeList(e.target.value)}
                  >
                    <MenuItem value="card">Tarjetas</MenuItem>
                    <MenuItem value="compact-list">Lista Compacta</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalItem}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerProcess}
                disabled={item.name === ''}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar la Categoría?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Category;

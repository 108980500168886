import React from 'react';
import {
  Switch, Route, Link, useRouteMatch, useHistory, Redirect,
} from 'react-router-dom';
import Sales from './sales';
import SalesPerEmployee from './salesPerEmployee';
import reportExpense from './reportExpense';
import transactionToday from './transactionToday';
import reportClockIn from './reportClockIn';
import topSeller from './topSeller';
import ReportStock from './reportStock';
import { getListSubMenu } from '../Cache/cacheStorage';

function Reportes() {
  const submenuList = getListSubMenu('reports');
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const ValidationPath = history.location.pathname;

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <div className="d-flex w-100 h-100">
        <div className="options pe-2 h-100">
          <h3 className="text-white">Reportes</h3>
          <div className="w-100">
            <div className="w-100 menu-content">
              {submenuList.submenu.map((menus) => (
                <Link
                  id="sales"
                  key={menus.name}
                  className={`nav-link p-2 text-white ${ValidationPath.includes(menus.name) === true ? ' btn-filter-product-active btn-rounded' : ''}`}
                  to={`${url}/${menus.name}`}
                >
                  {menus.tagName}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="option-content-view ps-2 h-100 detail-content">
          <Switch>
            <Redirect exact from={`${path}/`} to={`${path}/transactions-today`} />
            <Route path={`${path}/transactions-today`} component={transactionToday} />
            <Route path={`${path}/sales-report`} component={Sales} />
            <Route path={`${path}/sales-employee`} component={SalesPerEmployee} />
            <Route path={`${path}/report-expense`} component={reportExpense} />
            <Route path={`${path}/report-clockin`} component={reportClockIn} />
            <Route path={`${path}/report-stock`} component={ReportStock} />
            <Route path={`${path}/top-seller`} component={topSeller} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Reportes;

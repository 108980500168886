/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  getRequest, postRequest, putRequest, deleteRequest, getListSubMenu,
} from '../Cache/cacheStorage';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Mesas() {
  const submenuList = getListSubMenu('tables');
  const [tables, settables] = useState([]);
  const [newTables, setnewTables] = useState({
    branchOffice: '',
    id: '',
    currentTransaction: '',
    name: '',
    position_x: 0,
    position_y: 0,
    state: 0,
  });
  const [valitadorName, setvalitadorName] = useState(false);
  const [deleteItem, setdeleteItem] = useState({});
  const [modalDelete, setmodalDelete] = useState(false);
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const getTables = async () => {
    const responseLogin = await getRequest('tables', {}, 'tables', false);
    if (responseLogin.status === 200) {
      settables(responseLogin.data.dataResponse);
    }
  };

  useEffect(() => {
    getTables();
  }, []);

  const [isEditTables, setIsEditTables] = useState(false);

  const editMesas = () => {
    setIsEditTables(true);
  }

  const cancelSaveMesas = () => {
    setIsEditTables(false);
  }

  const saveMesas = async () => {
    try {
      for (const itemTable of tables) {
        const response = await putRequest('tables', itemTable, 'tables', false);

        if (response.status !== 200) {
          setmsmAlert('Error en el registro, por favor intente de nuevo.');
          setalertError(true);
        }
      }

      setmsmAlert('Las Mesas se han actualizado exitosamente.');
      setalertSuccess(true);

      cancelSaveMesas();
    } catch (err) {
      console.log(err);
    }
  };

  const handlerChangeName = (e) => {
    const data = [...tables];
    const index = data.findIndex((x) => x.id === e.target.id);
  
    data[index].name = e.target.value;
  
    settables(data);
  };

  const handlerCreateName = (e) => {
    const item = newTables;
    item.name = e.target.value;
    setnewTables(item);

    if (e.target.value === '') {
      setvalitadorName(false);
    } else {
      setvalitadorName(true);
    }
  };

  const handlerCreateTable = async () => {
    const item = newTables;
    item.id = uuidv4();
    setnewTables(item);

    const response = postRequest('tables', newTables, 'tables', false);
    if (response.status !== 200) {
      setmsmAlert('Las Mesas se han actualizado exitosamente.');
      setalertSuccess(true);

      settables([...tables, item]);
      handlerCloseModalItem();
      setnewTables({
        id: '',
        currentTransaction: '',
        name: '',
        position_x: 0,
        position_y: 0,
        state: 0,
      });
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const handlerModalDelete = (e) => {
    const item = tables.filter((x) => x.id === e);
    setdeleteItem(item[0]);
    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handlerDelete = async () => {
    const responseDelete = await deleteRequest('tables', deleteItem, 'tables', false);
    if (responseDelete.status === 200) {
      if (responseDelete.data.dataResponse.status === true) {
        const filteredItems = tables.filter((x) => x.id !== deleteItem.id);
        settables(filteredItems);
        setdeleteItem({});
        setmodalDelete(false);
        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
      }

      if (responseDelete.data.dataResponse.status === false) {
        setdeleteItem({});
        setmodalDelete(false);
        setmsmAlert('Error, por favor intente de nuevo.');
        setalertError(true);
      }
    }
  };

  const handlerGetClassForMesaState = (state) => {
    let className = '';
    if (state === 0) {
      className = 'bg-mesa-pay-order';
    } else if (state === 1) {
      className = 'bg-mesa-taking-order';
    } else if (state === 2) {
      className = 'bg-mesa-preparing-order';
    } else {
      className = 'bg-mesa-without-order';
    }
    return className;
  };

  const handlerGetTransactionId = (transaction) => {
    let urlTransaction = '';

    if (transaction !== '' && transaction !== undefined && transaction !== null) {
      urlTransaction = `&transaction=${transaction}`;
    }

    return urlTransaction;
  };

  const handlerGetLink = (table) => {
    const linkTo = `/new-order?idMesa=${table.id}${table.state === 1 || table.state === 2 ? handlerGetTransactionId(table.currentTransaction) : ''}`;
    return linkTo;
  };

  const handlerGetClass = (type, table) => {
    const validatorEdit = type === 1 ? true : false;
    const classBtn = `w-100 h-100 vertical-center p-2 ${isEditTables === validatorEdit ? 'd-none' : ''} ${handlerGetClassForMesaState(table.state)}`;
    return classBtn;
  };

  const [modalItem, setmodalItem] = useState(false);

  const handlerOpenModalItem = () => {
    setmodalItem(true);
  };

  const handlerCloseModalItem = () => {
    setmodalItem(false);
  };

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalItem(); } }}
      >
        <DialogTitle>
          <span>Crear Mesa</span>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
              <TextField
                variant="standard"
                error={newTables.name === ''}
                id="name"
                label="Nombre *"
                className="w-100"
                onChange={(e) => handlerCreateName(e)}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-danger w-100"
                type="button"
                onClick={handlerCloseModalItem}
              >
                  Cancelar
              </button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                disabled={newTables.name === ''}
                onClick={handlerCreateTable}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
              <h6>¿Seguro desea eliminar esta mesa?</h6>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {submenuList.menuUrl !== '' && (
        <div className="d-flex w-100 h-100">
          <div className="options pe-2 h-100">
            <h3 className="text-white">Opciones</h3>
            <div id="no-editing-mesas" className={`w-100 py-3 ${isEditTables === true ? 'd-none' : ''}`}>
              <button id="nueva-mesa" className="btn btn-primary mb-2 w-100" type="button" onClick={handlerOpenModalItem}>
                <span className="text-menu">
                  Nueva Mesa
                </span>
                <AddIcon className="icon-menu" />
              </button>
              <button id="edit-mesas" className="btn btn-primary mb-2 w-100" type="button" onClick={() => editMesas()}>
                <span className="text-menu">
                  Editar Mesas
                </span>
                <EditIcon className="icon-menu" />
              </button>
            </div>
            <div id="editing-mesas" className={`w-100 py-3 ${isEditTables === false ? 'd-none' : ''}`}>
              <button id="save-mesas" className="btn btn-danger mb-2 w-100" type="button" onClick={() => cancelSaveMesas()}>Cancelar</button>
              <button id="save-mesas" className="btn btn-primary mb-2 w-100" type="button" onClick={saveMesas}>Guardar</button>
            </div>
          </div>
          <div className="option-content-view ps-2 h-100">
            <div className="w-100">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <h3 className="text-white w-100">
                    Mesas
                    <small className="float-end mx-2">
                      <h6 className="text-white">
                        <span className="bg-mesa-pay-order me-2">&nbsp;</span>
                        Disponible
                      </h6>
                    </small>
                    <small className="text-secondary float-end mx-2">
                      <h6 className="text-white">
                        <span className="bg-mesa-taking-order me-2">&nbsp;</span>
                        No disponible
                        <span className="text-menu">
                          (Mesa con pedido no pago)
                        </span>
                      </h6>
                    </small>
                    <small className="text-secondary float-end mx-2">
                      <h6 className="text-white">
                        <span className="bg-mesa-preparing-order me-2">&nbsp;</span>
                        Mesa con pedido Pago
                        <span className="text-menu">
                          (No disponible)
                        </span>
                      </h6>
                    </small>
                  </h3>
                </div>
              </div>
            </div>
            <div className="wrapper-table-list">
              {tables.map((mesa) => (
                <div key={mesa.id} id={mesa.id} type="button" className="box align-middle btn-rounded">
                  <Link className="text-decoration-none" to={handlerGetLink(mesa)}>
                    <div className={handlerGetClass(1, mesa)}>
                      <span className="text-white mx-auto">{mesa.name}</span>
                    </div>
                  </Link>
                  <div className={handlerGetClass(2, mesa)}>
                    <button
                      type="button"
                      className="bg-white col-sm-2 col-md-2 col-lg-2 vertical-center rounded-left h-100 border-0"
                      style={{ height: '100%' }}
                      onClick={() => handlerModalDelete(mesa.id)}
                    >
                      <span className="material-icons text-danger mx-auto">delete</span>
                    </button>
                    <div className="bg-white col-sm-10 col-md-10 col-lg-10 rounded-right vertical-center h-100 pe-2">
                      <TextField
                        variant="standard"
                        id={mesa.id}
                        className="w-100"
                        defaultValue={mesa.name}
                        error={valitadorName}
                        onChange={(e) => handlerChangeName(e)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Mesas;

/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    value: {
      branchOffice: "",
      createdate: "",
      comments: [],
      delivery: "Venta en tienda",
      employee: "",
      employeeName: "",
      fromHostedSite: false,
      fromPOSSystem: false,
      fulldate: "",
      fullhour: "",
      id: "",
      isEdit: false,
      isPaid: false,
      paymentMethod: "Efectivo",
      products: [],
      table: "pedido",
      tableName: "pedido",
      totalAmount: 0
    },
  },
  reducers: {
    setProductInTransactions: (state, action) => {
      state.value.products.push(action.payload)
    },
    setOrderInTransactions: (state, action) => {
      state.value.products = action.payload
    },
    setAmountToProductInTransaction: (state, action) => {
      state.value.products.map((item, index) => {
				if (item.id === action.payload.id) {
					state.value.products[index].amount += 1;
				}
			})
    },
    setRemoveProductInTransaction: (state, action) => {
      state.value.products = state.value.products.filter((p) => p.id !== action.payload.id)
    },
    resetTransaction: (state, action) => {
      state.value.branchOffice = "";
      state.value.createdate = "";
      state.value.comments = [];
      state.value.delivery = "Venta en tienda";
      state.value.employee = "";
      state.value.employeeName = "";
      state.value.fromHostedSite = false;
      state.value.fromPOSSystem = false;
      state.value.fulldate = "";
      state.value.fullhour = "";
      state.value.id = "";
      state.value.isEdit = false;
      state.value.isPaid = false;
      state.value.paymentMethod = "Efectivo";
      state.value.products = [];
      state.value.table = "pedido";
      state.value.tableName = "pedido";
      state.value.totalAmount = 0;
    },
  }
});

export const {
  setProductInTransactions, setOrderInTransactions, setAmountToProductInTransaction, resetTransaction,
  setRemoveProductInTransaction
} = transactionSlice.actions;

export default transactionSlice.reducer;

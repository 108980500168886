import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@mui/material/TextField';
import MaterialTable from '@material-table/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import QRCode from "react-qr-code";
import { useSelector, useDispatch } from 'react-redux';
import { setEmployees } from '../reducers/employees';
import {
  getRequest, putRequest, getSubMenu, getCompany, getAdmWebSite,
  handlerSendEmail, getLangUser, numberToMoney, encodeData
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';
import { urlpdf } from '../service';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransactionToday() {
  const submenu = getSubMenu('reports', 'transactions-today');
  const admWebSite = getAdmWebSite(); 

  // #region
  const currentDateForInput = moment().format('YYYY-MM-DD');
  const [currentDate, setCurrentDate] = useState(currentDateForInput);
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);
  const [dataTable, setdataTable] = useState([]);
  const [data, setdata] = useState([]);
  const [modalUpdate, setmodalUpdate] = useState(false);
  const [editItem, seteditItem] = useState({
    id: '',
    createdate: '',
    employee: '',
    employeeName: '',
    fulldate: '',
    fullhour: '',
    paymentMethod: '',
    products: [],
    table: '',
    tableName: '',
    totalAmount: 0,
    isEdit: false,
    comments: [],
    delivery: '',
    fromPOSSystem: false,
    fromHostedSite: false,
    isPaid: false,
  });
  const [newComment, setNewComment] = useState({
    emailRegister: '',
    createdate: '',
    comment: '',
  });
  const [validatorComments, setValidatorComments] = useState(true);
  const [modalTotalSell, setmodalTotalSell] = useState(false);
  const [paymentMethodList, setpaymentMethodList] = useState([]);
  const [company, setCompany] = useState({
    company: '',
    companyName: '',
    branchOffice: '',
    branchOfficeName: '',
    address: '',
    phoneNumber: '',
    nit: '',
  });

  const [transaction, setTransaction] = useState({
    id: '',
    createdate: '',
    employee: '',
    employeeName: '',
    paymentMethod: '',
    products: [],
    totalAmount: 0,
    table: 'pedido',
    tableName: 'pedido',
    comments: [],
    delivery: '',
    isEdit: false,
    stateTransaction: 0,
    fromPOSSystem: false,
    fromHostedSite: false,
    isPaid: false,                                               
  });
  const [modalDetail, setmodalDetail] = useState(false);
  const [filterOrigin, setFilterOrigin] = useState('0');
  
  const getDataCompany = () => {
    const dataCompany = getCompany();
    setCompany((prevState) => ({
      ...prevState,
      ['company']: dataCompany.companyName,
      ['companyName']: dataCompany.companyName,
      ['branchOffice']: dataCompany.branchOffice,
      ['branchOfficeName']: dataCompany.branchOfficeName,
      ['address']: dataCompany.address,
      ['phoneNumber']: dataCompany.phoneNumber,
      ['nit']: dataCompany.nit,
    }));
  }

  useEffect(() => {
    getDataCompany();
  }, []);
  
  const getPaymentMethod = async () => {
    const requestPaymentMethod = await getRequest('payment-method', {}, 'payment-method', false);
    setpaymentMethodList(requestPaymentMethod.data.dataResponse);
  };

  useEffect(() => {
    getPaymentMethod();
  }, []);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const employee = useSelector((state) => state.employees.value);

  /* A function that is used to set the rol. */
  const handlerSetEmployees = (infoEmployees) => {
    dispatch(setEmployees(infoEmployees));
  };

  const getEmployees = async () => {
    const requestEmployee = await getRequest('employees', {}, 'employees', false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };

  useEffect(() => {
    if (employee.length === 0) {
      getEmployees();
    }
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      width: 220,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'Cant.',
      type: 'number',
      width: 80,
      editable: false,
    },
    {
      field: 'price',
      headerName: 'Precio',
      type: 'number',
      width: 100,
      editable: false,
    },
    {
      field: 'fromStock',
      headerName: 'Stock',
      type: 'number',
      width: 100,
      editable: false,
      valueFormatter: (params) => {
        return `${params.value === true ? 'Si' : 'No'}`;
      },
    },
  ];

  const handlerOpenModalUpdate = (e) => {
    const item = data.filter((x) => x.id === e);
    seteditItem(item[0]);
    setmodalUpdate((modal) => !modal);
  };

  const handlerCloseModalUpdate = () => {
    seteditItem({});
    setmodalUpdate((modal) => !modal);
  };

  const handlerUpdate = async () => {
    const itemComment = newComment;
    const itemUpdate = editItem;

    if (itemComment.comment !== '') {
      itemComment.createdate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
      itemUpdate.comments.push(itemComment);
      itemUpdate.isEdit = true;

      const responseUpdate = await putRequest('add-comment-transaction', itemUpdate, 'add-comment-transaction', false);
      if (responseUpdate.status === 200) {
        setmodalUpdate((modal) => !modal);
        setmsmAlert('El comentario se ha añadido a la Venta Exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    } else {
      setmsmAlert('Error, el campo se encuentra vacio.');
      setalertError(true);
    }
  };

  const getTransactionTOday = async (i, e) => {
    const responseTr = await getRequest('transaction-today', { initialdate: i, enddate: e }, 'transaction-today', false);
    if (responseTr.status === 200) {
      setdataTable(responseTr.data.dataResponse);
      setdata(responseTr.data.dataResponse);
    }
  };

  const getExpense = (event) => {
    setCurrentDate(moment(event).format('YYYY-MM-DD'));
    getTransactionTOday(moment(event).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      moment(event).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'));
  };

  useEffect(() => {
    getTransactionTOday(moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'));
  }, []);

  const handleraddComments = (e) => {
    const item = newComment;
    item.comment = e.target.value;
    setNewComment(item);

    if (e.target.value === '') {
      setValidatorComments((state) => !state);
    }
  };

  const changeEmployee = (id) => {
    const nameEmployee = employee.find((x) => x.id === id);
    const commentItem = newComment;
    commentItem.emailRegister = `${nameEmployee.name} ${nameEmployee.lastName}`;

    setNewComment(commentItem);
  };

  const handlerTotalAmount = () => {
    setmodalTotalSell(true);
  };

  const handlerCloseModalTotalAmount = () => {
    setmodalTotalSell(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const callPrint = () => {
    window.print();
  };

  const modalDetailOpen = (id) => {
    const item = data.filter((x) => x.id === id);
    setTransaction(item[0]);
    setmodalDetail(true);
  };

  const modalDetailClose = () => {
    setTransaction({
      id: '',
      createdate: '',
      employee: '',
      employeeName: '',
      paymentMethod: '',
      products: [],
      totalAmount: 0,
      table: 'pedido',
      tableName: 'pedido',
      comments: [],
      delivery: '',
      isEdit: false,
      stateTransaction: 0,
      fromPOSSystem: false,
      fromHostedSite: false,
      isPaid: false,                                                  
    });
    setmodalDetail(false);
  };

  const handlerColumnsFormat = (e) => {
    const transactions = data;

    if (e === "1") {
      setdataTable(transactions.filter((transaction) => transaction.fromPOSSystem === true));
    } else if (e === "2") {
      setdataTable(transactions.filter((transaction) => transaction.fromHostedSite === true));
    } else {
      setdataTable(transactions);
    }

    setFilterOrigin(e);
  };
  // #endregion

  const [order, setOrder] = useState({
    id: '',
    createdate: '',
    employee: '',
    employeeName: '',
    paymentMethod: '',
    products: [],
    totalAmount: 0,
    table: 'pedido',
    tableName: 'pedido',
    comments: [],
    delivery: '',
    isEdit: false,
    stateTransaction: 0,
    fromPOSSystem: false,
    fromHostedSite: false,
    isPaid: false,
    clientName: '',
    clientEmail: '',
    address: '',
    phoneNumber: '',
  });
  const [modalPaidOrder, setModalPaidOrder] = useState(false);

  const handlerModalPaid = async (id) => {
    const item = data.filter((x) => x.id === id);
    const itemUpdate = item[0];

    setOrder(itemUpdate);
    setModalPaidOrder(true);
  };

  const handlerModalClose = () => {
    setOrder({
      id: '',
      createdate: '',
      employee: '',
      employeeName: '',
      paymentMethod: '',
      products: [],
      totalAmount: 0,
      table: 'pedido',
      tableName: 'pedido',
      comments: [],
      isEdit: false,
      stateTransaction: 0,
      fromPOSSystem: false,
      fromHostedSite: false,
      isPaid: false,
      clientName: '',
      clientEmail: '',
      address: '',
      phoneNumber: '',
    });

    setModalPaidOrder(false);
  };

  const handlerEmployeePaidOrder = (id) => {
    const itemUpdate = order;
    const nameEmployee = employee.find((x) => x.id === id);

    itemUpdate.employee = id;
    itemUpdate.employeeName = `${nameEmployee.name} ${nameEmployee.lastName}`;
    itemUpdate.isPaid = true;

    setOrder(itemUpdate);
  };

  const handlerpaid = async () => {
    const responseUpdate = await putRequest('paid-order', order, 'updateTransaction', false);
    if (responseUpdate.status === 200) {
      setmsmAlert('La Venta se ha marcado como Pagada Exitosamente.');
      setalertSuccess(true);
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }

    setModalPaidOrder(false);
  };

  const handlerDispatchOrder = async (id) => {
    const item = data.filter((x) => x.id === id);
    const itemUpdate = item[0];
    itemUpdate.isPaid = true;

    const encodedText = encodeURIComponent(`Hola ${itemUpdate.clientName}`);
    const url = `https://wa.me/573135482549?text=${encodedText}`;
    window.open(url, '_blank');
  };

  const [reportView, setReportView] = useState('table');

  const handlerChangeReport = (e) => {
    setReportView(e);
  };

  const handlerSendEmailResume = async () => {
    const emailResquest = await handlerSendEmail(moment(currentDate).startOf('day').format('YYYY-MM-DD'));
    if (emailResquest.status === 200) {
      setmsmAlert('Email de Resumen de ventas se ha enviado.');
      setalertSuccess(true);
    }
  };

  const getValidate = (admWebSite, fromHostedSite, fromPOSSystem, isPaid) => {
    return admWebSite === false || fromHostedSite === false || fromPOSSystem === true || isPaid === true;
  };

  const commentView = (data) => {
    const rowComment = [
      {
        id: 1,
        name: 'Quien Registra',
        value: data.emailRegister,
      },
      {
        id: 2,
        name: 'Fecha de Registro',
        value: moment(data.createdate).format('DD/MM/YYYY hh:mm:ss A'),
      },
      {
        id: 3,
        name: 'Comentario',
        value: data.comment,
      },
    ];

    return <div className="row py-2 mb-2" style={{ border: '1px Solid #e0e0e0', borderRadius: '0.25rem' }}>
      {rowComment.map((item) => (
        <div className="col-12" key={item.id}>
          <span>
            {item.name}:&nbsp;
            <span className="text-muted">
            {item.value}
            </span>
          </span>
        </div>
      ))}
    </div>;
   };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <div className="col-12 my-2">
          <h3>
            Ventas del día&nbsp;
            {reportView === 'table' && (
              <small className="text-primary">
                ({dataTable.length})
              </small>
            )}
            <IconButton
              className="float-end"
              color="primary"
              aria-label="settings"
              component="span"
              data-bs-toggle="collapse"
              href="#collapse-settings"
              role="button"
              aria-expanded="false"
              aria-controls="collapse-settings"
            >
              <SettingsIcon />
            </IconButton>
            {reportView === 'table' && (
              <button
              className="btn float-end border-1 me-1 mt-1 border-success"
                type="button"
                onClick={handlerTotalAmount}
              >
                <span className="h5">
                  Valor de ventas&nbsp;
                  <span className="text-success">
                    {
                      numberToMoney(
                        dataTable
                        .filter((transaction) => transaction.isPaid === true)
                        .reduce((a, v) => a + v.totalAmount, 0)
                      )
                    }
                  </span>
                </span>
              </button>
            )}
          </h3>
        </div>
        <div className="col-12 mb-2">
          <div className="collapse" id="collapse-settings">
            <div className="card card-body border-0 px-0 py-1">
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <TextField
                    variant="standard"
                    className="float-start"
                    label="Fecha *"
                    id="date-end"
                    type="date"
                    defaultValue={currentDateForInput}
                    onChange={(e) => getExpense(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '25%',
                    }}
                  />
                  <FormControl
                    variant="standard"
                    className="float-end filter-table-report"
                    sx={{
                      width: '25%',
                    }}
                  >
                    <InputLabel id="label-select-table">Tipo</InputLabel>
                    <Select
                      id="select-table"
                      labelId="select-table"
                      name="Tabla"
                      value={reportView}
                      onChange={(e) => {handlerChangeReport(e.target.value)}}
                    >
                      <MenuItem value="table">Tabla de ventas</MenuItem>
                      <MenuItem value="stock-table">Tabla de productos de Stock</MenuItem>
                    </Select>
                  </FormControl>
                  {admWebSite && (
                    <FormControl
                      sx={{
                        width: '25%',
                      }}
                      variant="standard"
                      className="float-end filter-origin"
                    >
                      <InputLabel id="label-select-category">Categoría</InputLabel>
                      <Select
                        id="select-category"
                        labelId="select-category"
                        name="category"
                        value={filterOrigin}
                        onChange={(e) => {handlerColumnsFormat(e.target.value)}}
                      >
                        <MenuItem value="0">Todos</MenuItem>
                        <MenuItem value="1">POS</MenuItem>
                        <MenuItem value="2">Sitio Web</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {reportView === 'table' && (
                    <button
                      type="button"
                      className="btn btn-primary me-2 mt-1 float-end"
                      onClick={() => handlerSendEmailResume()}
                    >
                      Enviar resumen
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {reportView === 'table' && (
          <div className="col-12">
            <MaterialTable
              title=""
              columns={[
                {
                  title: 'Hora', field: 'fulldate', width: '15%',
                  render: (dataRow) => moment(dataRow.createdate).format('hh:mm:ss A'),
                  customSort: (a, b) => -a.createdate.localeCompare(b.createdate),
                },
                {
                  title: 'Mesa/Desde', field: 'tableName', width: '10%',
                },
                {
                  title: 'Empleado', field: 'employeeName', width: '20%',
                },
                {
                  title: 'M. de Pago', field: 'paymentMethod', width: '35%',
                },
                {
                  title: '¿Pagó?', field: 'isPaid', width: '10%',
                  render: (dataRow) => dataRow.isPaid === true ? 'Si' : 'No',
                },
                {
                  title: 'T. de Venta', field: 'totalAmount', width: '15%',
                  render: (dataRow) => (numberToMoney(dataRow.totalAmount)),
                },
              ]}
              data={dataTable}
              actions={
                [
                  {
                    icon: 'chevron_right',
                    onClick: (event, rowData) => { modalDetailOpen(rowData.id); },
                  },
                  {
                    icon: 'edit',
                    tooltip: 'Actualizar',
                    onClick: (event, rowData) => handlerOpenModalUpdate(rowData.id),
                    disabled: submenu.update !== true,
                  },
                  (rowdata) => ({
                    icon: 'attach_money',
                    tooltip: 'Validar pago',
                    hidden: getValidate(admWebSite, rowdata.fromHostedSite, rowdata.fromPOSSystem, rowdata.isPaid),
                    disabled: rowdata.isPaid === true,
                    onClick: (event, rowData) => {
                      if (rowdata.isPaid === false) {
                        handlerModalPaid(rowData.id)
                      }
                    },
                  }),
                  (rowdata) => ({
                    icon: 'directions_bike',
                    tooltip: 'Despachar pedido',
                    hidden: getValidate(admWebSite, rowdata.fromHostedSite, rowdata.fromPOSSystem, rowdata.isPaid),
                    disabled: rowdata.isPaid === false,
                    onClick: (event, rowData) => {
                      if (rowdata.isPaid === true) {
                        handlerDispatchOrder(rowData.id)
                      }
                    },
                  }),
                ]
              }
              options={{
                paging: dataTable.length > 100,
                pageSize: 100,
                pageSizeOptions: [100, 150, 200, 300],
                toolbar: false,
                maxColumnSort: 'all_columns',
                rowStyle: (rowData) => {
                  if (rowData.isEdit === true) {
                    return { backgroundColor: '#fa343e', color: '#fff' };
                  }
                },
              }}
              localization={{
                toolbar: {
                  searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
                  searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
                },
                header: {
                  actions: labelLang(getLangUser(), 'table', 'actions'),
                },
                pagination: {
                  labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
                  labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
                  labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
                  firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
                  firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
                  previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
                  previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
                  nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
                  nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
                  lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
                  lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
                },
              }}
            />
          </div>
        )}
        {reportView === 'stock-table' && (
          <div className="col-12">
          <MaterialTable
              title=""
              columns={[
                {
                  title: 'Nombre', field: 'name', width: '40%',
                },
                {
                  title: 'Cantidad vendida', field: 'amount', width: '30%',
                },
                {
                  title: 'Precio', field: 'price', width: '20%',
                  render: (dataRow) => (numberToMoney(dataRow.price)),
                },
              ]}
              data={dataTable.map((item) => 
                item.products.filter((x) => x.fromStock === true).map((product) => {
                  return {
                    id: product.id,
                    name: product.name,
                    amount: product.amount,
                    fromStock: product.fromStock,
                    price: product.price,
                  }
                })
              ).reduce((acc, curr) => acc.concat(curr), []).reduce((acc, cur) => {
                const product = acc.find((o) => o.id === cur.id);
                if (product) {
                  product.amount += cur.amount;
                } else {
                  acc.push(cur);
                }
                return acc; 
              }, [])}
              options={{
                paging: false,
                toolbar: false,
                maxColumnSort: 'all_columns',
              }}
              localization={{
                toolbar: {
                  searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
                  searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
                },
                header: {
                  actions: labelLang(getLangUser(), 'table', 'actions'),
                },
                pagination: {
                  labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
                  labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
                  labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
                  firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
                  firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
                  previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
                  previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
                  nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
                  nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
                  lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
                  lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
                },
              }}
            />
          </div>
        )}
      </div>

      <Dialog
        open={modalDetail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { modalDetailClose(); } }}
      >
        <DialogContent>
        <PrintProvider>
          <Print printOnly style={{paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px', marginRight: '0px'}}>
            <div style={{fontFamily: 'monospace', backgroundColor: 'white', paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px', marginRight: '0px', width: '100%', overflow: 'hidden'}}>
              <div style={{textAlign: 'center', paddingBottom: '0px'}}>
                <h2 style={{marginTop:'0px', marginBottom:'0px',}}>{company.companyName}</h2>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>{company.branchOfficeName}</p>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>DIR: {company.address}</p>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>TEL: {company.phoneNumber}</p>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>NIT: {company.nit}</p>
              </div>
              <div style={{height:'2px', backgroundColor: '#000', marginTop:'10px', marginBottom: '10px'}}></div>
              <div style={{paddingLeft:'0px', paddingRight:'0px',}}>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>Empleado: {transaction.employeeName}</p>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>Forma de pago: {transaction.paymentMethod}</p>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>Fecha: {moment(transaction.createdate).format('DD/MM/YYYY')}</p>
                <p style={{marginTop:'0px', marginBottom:'0px',}}>Hora: {moment(transaction.createdate).format('hh:mm:ss')}</p>
              </div>
              <div style={{height:'2px', backgroundColor: '#000', marginTop:'10px', marginBottom: '5px'}}></div>
              <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
                <p style={{marginTop:'5px', marginBottom: '5px',}}></p>
                <table style={{width: '100%'}}>
                  <thead> 
                    <tr>
                      <th style={{textAlign: 'left', width: '60%',}}>Prod.</th>
                      <th style={{textAlign: 'left', width: '20%',}}>Cant.</th>
                      <th style={{textAlign: 'right', width: '20%',}}>Val.</th>
                    </tr>
                  </thead>
                </table>
                <table style={{width: '100%'}}>
                  <tbody>
                    {transaction.products.map((item) => (
                      <tr key={item.id}>
                        <td style={{textAlign: 'left'}}>
                          {item.name}
                        </td>
                        <td style={{textAlign: 'left'}}>
                          {item.amount}
                        </td>
                        <td style={{textAlign: 'right'}}>
                          {numberToMoney(item.price)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table style={{width: '100%'}}>
                  <tfoot>
                    <tr>
                      <th style={{textAlign: 'left', width: '80%'}}>Total</th>
                      <th style={{textAlign: 'right', width: '20%'}}>
                        {numberToMoney(transaction.totalAmount)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div style={{height:'2px', backgroundColor: '#000', marginTop:'15px', marginBottom: '15px'}}></div>
              <div style={{textAlign: 'center', marginBottom:'10px'}}>
                <QRCode size={192} value={`${urlpdf}pdf?f=1&d=${encodeData({
                  id: transaction.id,
                  createdate: transaction.createdate,
                  employeeName: transaction.employeeName,
                  paymentMethod: transaction.paymentMethod,
                  products: transaction.products.map(item => ({name: item.name, price: item.price, amount: item.amount})),
                  totalAmount: transaction.totalAmount,
                })}&c=${encodeData({
                  companyName: company.companyName,
                  branchOfficeName: company.branchOfficeName,
                  address: company.address,
                  phoneNumber: company.phoneNumber,
                  nit: company.nit,
                })}`}
                />
                <p style={{marginTop:'10px', marginBottom:'0px'}}>CUFE</p>
                <p style={{marginTop:'0px', marginBottom:'0px'}}>{transaction.id}</p>
                <p style={{marginTop:'0px', marginBottom:'0px'}}>{moment(transaction.createdate).format('DD/MM/YYYY hh:mm:ss')}</p>
              </div>
            </div>
          </Print>
          <NoPrint force>
            <div className="row bg-white btn-rounded">
              <div className="col-6">
                <div className="col-12 mb-4">
                  <h4>Detalle de Venta</h4>
                </div>
                <div className="row">
                  <div className="col-6 mb-2">
                    <TextField
                      variant="standard"
                      label="Fecha de Registro *"
                      id="date"
                      className="w-100"
                      type="text"
                      disabled
                      defaultValue={moment(transaction.createdate).format('DD/MM/YYYY hh:mm:ss A')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <TextField
                      variant="standard"
                      label={(admWebSite === true || transaction.fromHostedSite === true) ? 'Desde *' : 'Mesa *'}
                      id="table"
                      className="w-100"
                      type="text"
                      disabled
                      defaultValue={transaction.tableName}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <TextField
                      variant="standard"
                      label="M. de Pago *"
                      id="payment-method"
                      className="w-100"
                      type="text"
                      disabled
                      defaultValue={transaction.paymentMethod}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <TextField
                      variant="standard"
                      label="Empleado *"
                      id="employeeName"
                      className="w-100"
                      type="text"
                      disabled
                      defaultValue={transaction.employeeName}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  {(admWebSite === true || transaction.clientName !== undefined) && (
                    <div className="col-6 mb-2">
                      <TextField
                        variant="standard"
                        label="Nombre del Cliente *"
                        id="clientName"
                        className="w-100"
                        type="text"
                        disabled
                        defaultValue={transaction.clientName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                  {(admWebSite === true || transaction.clientEmail !== undefined) && (
                    <div className="col-6 mb-2">
                      <TextField
                        variant="standard"
                        label="Email del Cliente *"
                        id="clientEmail"
                        className="w-100"
                        type="text"
                        disabled
                        defaultValue={transaction.clientEmail}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                  {(admWebSite === true || transaction.address !== undefined) && (
                    <div className="col-6 mb-2">
                      <TextField
                        variant="standard"
                        label="Dirección del Cliente *"
                        id="address"
                        className="w-100"
                        type="text"
                        disabled
                        defaultValue={transaction.address}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                  {(admWebSite === true || transaction.phoneNumber !== undefined) && (
                    <div className="col-6 mb-2">
                      <TextField
                        variant="standard"
                        label="No. Tel. del Cliente *"
                        id="phoneNumber"
                        className="w-100"
                        type="text"
                        disabled
                        defaultValue={transaction.phoneNumber}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                  {(admWebSite === true || transaction.delivery !== undefined) && (
                    <div className="col-6 mb-2">
                      <TextField
                        variant="standard"
                        label="Tipo de entrega *"
                        id="delivery"
                        className="w-100"
                        type="text"
                        disabled
                        defaultValue={transaction.delivery}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                  <div className="col-12 mb-4 mt-2" style={{ height: 150 }}>
                    <DataGrid
                      headerHeight={40}
                      hideFooter
                      disableColumnMenu
                      rowHeight={30}
                      rows={transaction.products}
                      columns={columns}
                      isCellEditable={(params) => params.row.age % 2 === 0}
                    />
                  </div>
                  <div className="col-12">
                    <h4>
                      Total
                      <span className="float-end">
                        {numberToMoney(transaction.totalAmount)}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12 mb-2">
                    <h4>Comentarios de Edición</h4>
                  </div>
                  <div className="col-12 mb-2 overflow-auto" style={{ height: '330px' }}>
                    <h6 className="text-muted">Comentarios</h6>
                    {transaction.isEdit === true && (
                      transaction.comments.map((item, i) => (
                        <div className="w-100" key={i}>
                          {console.log(item)}
                          <a
                            className="text-decoration-none"
                            data-bs-toggle="collapse"
                            href={`#commentNumber${i}`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`commentNumber${i}`}
                          >
                            {moment(item.createdate).format('DD/MM/YYYY hh:mm:ss A')}
                            <ArrowDropDownIcon />
                          </a>
                          <div
                            className="collapse mt-1 px-2"
                            id={`commentNumber${i}`}
                          >
                            <>
                              {commentView(item)}
                            </>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="container-actions my-2 px-0">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => { modalDetailClose(); }}
                >
                  Cerrar
                </button>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => { callPrint(); }}
                  disabled={transaction.paymentMethod === 'Venta asignada a la mesa, no pagada'}
                >
                  Imprimir factura
                </button>
              </div>
            </div>
          </NoPrint>
        </PrintProvider>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalUpdate(); } }}
      >
        <DialogTitle>
          Añadir comentario
        </DialogTitle>
        <DialogContent>
          <div className="row pb-2">
            <div className="col-12 mb-2">
              <TextField
                variant="standard"
                label="Comentarios *"
                id="reason-edit-transaction"
                type="text"
                className="w-100"
                multiline
                minRows={2}
                onChange={(e) => handleraddComments(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 mb-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-employee">Empleado quien registra</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  defaultValue=""
                  onChange={(e) => changeEmployee(e.target.value)}
                >
                  {
                    employee.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {`${item.name} ${item.lastName}`}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalUpdate}>
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerUpdate}
                disabled={!validatorComments || newComment.emailRegister !== ''}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalPaidOrder}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerModalClose(); } }}
      >
        <DialogTitle>
          Verificar pago del pedido.
        </DialogTitle>
        <DialogContent>
          <div className="row pb-2">
            <div className="col-12 mb-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-employee">Empleado quien registra</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  defaultValue=""
                  onChange={(e) => handlerEmployeePaidOrder(e.target.value)}
                >
                  {
                    employee.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {`${item.name} ${item.lastName}`}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerModalClose}>
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerpaid}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalTotalSell}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalTotalAmount(); } }}
      >
        <DialogTitle>
          Total de ventas por Medio de pago
        </DialogTitle>
        <DialogContent>
          <div className="row pb-2">
            <div className="col-12 mb-2">
              <span>
                Efectivo  
              </span>
              <span className="float-end text-success">
                {
                  numberToMoney(
                    data
                    .filter((item) => item.paymentMethod === 'Efectivo')
                    .reduce((a, v) => a + v.totalAmount, 0)
                  )
                }
              </span>
            </div>
            {paymentMethodList.map((i) => (
              <div className="col-12 mb-2" key={i.name}>
                <span>
                  {i.name}
                </span>
                <span className="float-end text-success">
                  {
                    numberToMoney(
                      data
                      .filter((item) => item.paymentMethod === i.name)
                      .reduce((a, v) => a + v.totalAmount, 0)
                    )
                  }
                </span>
              </div>
            ))}
            <div className="col-12 mt-4">
              <button className="btn border-1 border-primary w-100 text-primary" type="button" onClick={handlerCloseModalTotalAmount}>
                Entendido
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default TransactionToday;

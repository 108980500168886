import Lang from './lang.json';

export const labelLang = (langUser, object, id) => {
  const getDictionaryLang = Lang
    .find((langDictionary) => langDictionary.lang === langUser);

  const getDictionaryObject = getDictionaryLang.component
    .find((component) => component.object === object);

  const getLabelObject = getDictionaryObject.dictionary
    .find((dictionaryObject) => dictionaryObject.id === id);

  return getLabelObject.word;
};
